/* eslint-disable react/jsx-props-no-spreading */
import { setOnboardingEntryPoint } from '@actions/onboarding';
import { MenuLink } from '@components/molecules/AccountMenuItem/AccountMenuItem';
import LINKS from '@constants/links';
import NOTIFICATIONS from '@constants/notifications';
import { ENTRY_POINT_MAIN_MENU } from '@features/onboarding/constants';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useCustomer from '@hooks/useCustomer';
import IconBag from '@public/icons/regularIcons/icon-bag.svg';
import IconBonusCheck from '@public/icons/regularIcons/icon-bonus-check.svg';
import IconList from '@public/icons/regularIcons/icon-list.svg';
import IconLogout from '@public/icons/regularIcons/icon-logout.svg';
import IconMembership from '@public/icons/regularIcons/icon-membership.svg';
import IconUser from '@public/icons/regularIcons/icon-user.svg';
import { NotificationStoreState } from '@reducers/notifications';
import { loginTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { openLoginModal } from '@actions/login';

const useAccountMenuItems = () => {
  const { t } = useTranslation('header');
  const dispatch = useAppDispatch();
  const { isAnyB2BCustomer, isAnonymous } = useCustomer();
  const { push } = useAppRouter();

  const b2cProfilePageLink: MenuLink = {
    url: LINKS.MY_PROFILE,
    title: t('myaccountmenu:myaccountmenu->myProfile'),
    icon: {
      svg: IconUser,
      color: 'black',
    },
    onClick: () => {
      push(LINKS.MY_PROFILE);
    },
  };

  const b2bProfilePageLink: MenuLink = {
    url: LINKS.B2B_PROFILE,
    title: t('myaccountmenu:myaccountmenu->myCompanyInformation'),
    icon: {
      svg: IconUser,
      color: 'black',
    },
    onClick: () => {
      push(LINKS.B2B_PROFILE);
    },
  };

  const myMemberShipLink: MenuLink = {
    url: LINKS.MY_MEMBERSHIP,
    title: t('myaccountmenu:myaccountmenu->mymembership'),
    icon: {
      svg: IconMembership,
      color: 'black',
    },
    onClick: () => {
      push(LINKS.MY_MEMBERSHIP);
    },
  };

  const notification: NotificationType = useAppSelector(
    (state: { notifications: NotificationStoreState }) => state.notifications[NOTIFICATIONS.VOUCHER_NOTIFICATION]
  );
  const loginLinks: MenuLink[] = [
    {
      title: t('header->account->accountDropdownMenu->logIn'),
      onClick: () => {
        dispatch(setOnboardingEntryPoint(ENTRY_POINT_MAIN_MENU));
        dispatch(openLoginModal({ b2b: false, isLoginConfirm: false }));
      },
    },
    {
      title: t('header->account->accountDropdownMenu->logInCompany'),
      onClick: () => {
        dispatch(openLoginModal({ b2b: true, isLoginConfirm: false }));
      },
    },
    {
      url: LINKS.REGISTER_B2C_DEPRECATED,
      title: t('header->account->accountDropdownMenu->signUp'),
      onClick: () => {
        dispatch(setOnboardingEntryPoint(ENTRY_POINT_MAIN_MENU));
        push(LINKS.REGISTER_B2C_DEPRECATED);
      },
    },
  ];

  const loggedInLinks: MenuLink[] = [
    {
      url: LINKS.MY_LISTS,
      title: t('myaccountmenu:myaccountmenu->myLists'),
      icon: {
        svg: IconList,
        color: 'black',
      },
      onClick: () => {
        push(LINKS.MY_LISTS);
      },
    },
    {
      url: LINKS.MY_ORDERS,
      title: t('myaccountmenu:myaccountmenu->myPurchases'),
      icon: {
        svg: IconBag,
        color: 'black',
      },
      onClick: () => {
        push(LINKS.MY_ORDERS);
      },
    },
    isAnyB2BCustomer ? b2bProfilePageLink : b2cProfilePageLink,
    {
      url: LINKS.LOGOUT,
      title: t('myaccountmenu:myaccountmenu->logOut'),
      icon: {
        svg: IconLogout,
        color: 'black',
      },
      onClick: () => {
        loginTracker.userLogout();
        push(LINKS.LOGOUT);
      },
    },
  ];

  if (!isAnyB2BCustomer) {
    loggedInLinks.unshift(
      {
        url: LINKS.MY_BENEFITS,
        title: t('myaccountmenu:myaccountmenu->myBenefits'),
        icon: {
          svg: IconBonusCheck,
          color: 'black',
        },
        onClick: () => {
          push(LINKS.MY_BENEFITS);
        },
        badgeValue: notification?.number,
      },
      myMemberShipLink
    );
  }

  return isAnonymous ? loginLinks : loggedInLinks;
};

export default useAccountMenuItems;
