import { useRouter } from 'next/compat/router';

const useAppRouter = () => {
  const nextRouter = useRouter() ;

  return {
    ...nextRouter,
    // this is query object with hidden params in the page router, not same as useSearchParams
    query: nextRouter?.query ?? {},
    events: nextRouter?.events,
    push: nextRouter ? nextRouter.push : () => {},
    reload: nextRouter ? nextRouter.reload : () => {},
    replace: nextRouter ? nextRouter.replace : () => {},
  };
};

export default useAppRouter;
