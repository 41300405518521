// running inside react native part of axfood app (iOS or Android)
// @ts-ignore
const isReactNative = () => window?.ReactNativeWebView;
// running inside iOS WKWebView inside axfood app
// @ts-ignore
const isIosApp = () => typeof webkit !== 'undefined';
// running inside Android webview inside axfood app
// @ts-ignore
const isAndroidApp = () => typeof window?.axfoodAppHandler !== 'undefined';

// method to send arbitrary messages to the app (don't use this directly)
export const sendMessageToApp = (messageDictionary: MessageDictionary) => {
  if (isReactNative()) {
    // running inside React Native webview
    const jsonMessage = JSON.stringify(messageDictionary);
    (window as any)?.ReactNativeWebView?.postMessage(jsonMessage);
  } else if (isIosApp()) {
    // running inside iOS WKWebView inside axfood app
    // @ts-ignore
    webkit?.messageHandlers?.axfoodAppHandler?.postMessage(messageDictionary);
  } else if (isAndroidApp()) {
    // running inside Android webview inside axfood app
    const json = JSON.stringify(messageDictionary);

    (window as any)?.axfoodAppHandler?.postMessage(json);
  } else {
    // No app handler available. Ignore attempt to send message.
  }
};