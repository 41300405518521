import UnoptimizedImage from '@components/atoms/UnoptimizedImage/UnoptimizedImage';
import { Colors, PromoComponentProps, promoBannerClicked } from '../PromoComponent';
import { ContentContainer, PromoContentComponent, PromoPanel } from './CenteredPromoComponent.styles';

const CenteredPromoComponent = ({ data }: PromoComponentProps) => {
  const { background, tracking } = data;

  const backgroundColor = background.color && Colors[background.color];
  const mobileImage = background.image.mobile || background.image.desktop;
  const buttonVariant = data.button.color === 'transparent' ? 'strokeBlack' : 'secondaryPlain';
  const centerContent = true;

  return (
    <PromoPanel $centerContent={centerContent} $backgroundColor={backgroundColor}>
      {/* This image really need unoptimized, also ensures it looks right in storybook */}
      {mobileImage && (
        <UnoptimizedImage
          src={mobileImage.url}
          alt={mobileImage.altText || 'Bild'}
          style={{ objectFit: 'cover' }}
          fill
          unoptimized
        />
      )}
      <ContentContainer>
        <PromoContentComponent
          data={{
            title: data.header.text,
            buttonText: data.button.text,
            buttonUrl: data.button.link,
          }}
          centerContent={centerContent}
          headingSize="large"
          buttonVariant={buttonVariant}
          color={data.textColor}
          icon={data.icon}
          onClick={() => promoBannerClicked(tracking)}
        />
      </ContentContainer>
    </PromoPanel>
  );
};

export default CenteredPromoComponent;
