import UnoptimizedImage from '@components/atoms/UnoptimizedImage/UnoptimizedImage';
import ColumnComponentContext from '@context/ColumnComponentContext';
import { useContext } from 'react';
import { Container, ImageWrapper } from './CMSImageComponent.styles';

interface CMSImageComponentProps {
  component: CMSImageComponent;
}

const CMSImageComponent = ({ component }: CMSImageComponentProps) => {
  const { isMultiRowComponent = false } = useContext(ColumnComponentContext);
  return (
    <Container $withPadding={!isMultiRowComponent}>
      <ImageWrapper>
        <UnoptimizedImage
          src={component.media.url}
          alt={component.media.altText}
          fill
          style={{ objectFit: 'contain' }}
        />
      </ImageWrapper>
    </Container>
  );
};

export default CMSImageComponent;
