import { FacetDataOfSearchStateData } from '@api/generated/storefront';
import FACET_CODES from '@constants/productListFilters';

export const clearFiltersFromQuery = (routerQuery: URLSearchParams) => {
  // save q
  const q = routerQuery.get('q')

  // copy everything
  const other = new URLSearchParams(routerQuery?.toString());
  // remove q
  other.delete('q')

  // remove all filters from q
  const searchKey = q?.split(':')[0].trim();

  if (searchKey && searchKey.length) {
    return new URLSearchParams(`q=${searchKey}&${other.toString()}`)
  } else {
    return new URLSearchParams(other.toString())
  }
};

export const getCurrentSearchKey = (query: string) => query.split(':')[0].trim();

export const countActiveFilters = (facets: FacetDataOfSearchStateData[] | null) => {
  return facets ? facets.reduce((acc, cur) => {
    const count = cur.values?.filter(i => i.selected).length || 0;
    return acc + count;
  }, 0) : 0;
};

export const getCurrentAppliedFilters = (routerQuery: string): string[] => {
  const filterArray = routerQuery?.split(':').slice(1);
  return filterArray.filter((item) => ![FACET_CODES.LABELS, FACET_CODES.BRANDS, FACET_CODES.PROMOTION].includes(item));
};
