import Link from '@components/atoms/Link';
import FooterHeading from '@components/molecules/FooterHeading';
import { rem } from 'polished';
import styled from 'styled-components';

export const ColumnHeader = styled(FooterHeading)``;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin: ${rem(8)} 0;
`;

export const ListItemLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.thin};
  font-size: ${({ theme }) => theme.fonts.fontSize.normal};

  &:hover {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    color: ${({ theme }) => theme.colors.colorTextPrimary};
  }
`;
