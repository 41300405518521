import { usePathname } from 'next/navigation';

const useEventListName = (LIST_NAME: string) => {
  const pathname = usePathname() ?? '';
  const extendListName = ` | ${LIST_NAME}`;
  const EVENT_LIST_NAME = `${pathname}${extendListName}`;
  return {
    EVENT_LIST_NAME,
  };
};

export default useEventListName;
