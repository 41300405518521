import { MAX_QUANTITY_VALUE } from '@components/molecules/Quantity';
import { roundDecimals } from './numberHelpers';

export const roundToNearestIncrement = (quantity: number, incrementValue: number) => {
  const nearestIncrement =
    quantity % incrementValue >= incrementValue / 2
      ? Math.ceil(quantity / incrementValue) * incrementValue
      : Math.floor(quantity / incrementValue) * incrementValue;
  return roundDecimals(nearestIncrement);
};

/**
 * Ensure quantity is within specified range defined by minValue and maxValue.
 */
export const clampQuantity = (quantity: number, incrementValue: number, minValue = 0, maxValue = MAX_QUANTITY_VALUE) => {
  return roundToNearestIncrement(Math.min(Math.max(quantity, minValue), maxValue), incrementValue);
};
