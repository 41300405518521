import { getCustomer } from '@api/CustomerApi';
import useAppRouter from '@hooks/useAppRouter';
import useSWR from 'swr';

export const key = 'api/customer';
export const fetcher = () => getCustomer();

const useCustomer = () => {
  const { push } = useAppRouter();
  const {
    data: customer,
    mutate: mutateCustomer,
    isLoading: isLoadingCustomer,
    isValidating: isValidatingCustomer,
    error: customerError,
  } = useSWR(key, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    onErrorRetry: (error, _, __, revalidate, { retryCount }) => {
      // Never retry on 404.
      if (error.status === 404) {
        push?.('/404');
        return;
      }

      if (retryCount >= 3) {
        push?.('/500');
        return;
      }

      setTimeout(() => revalidate({ retryCount }), 1000);
    },
  });

  const hasFetchedCustomer = !isLoadingCustomer;

  const customerFetchIsRequired = typeof window === 'undefined' || Boolean(customer);

  return {
    ...customer,
    mutateCustomer,
    isLoadingCustomer,
    isValidatingCustomer,
    customerError,
    hasFetchedCustomer,
    customerFetchIsRequired,
  };
};

export default useCustomer;
