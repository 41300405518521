import PICK_UNIT from '@constants/pickUnit';
import React, { useRef } from 'react';
import { BorderBox, Container } from './Quantity.styles';
import DecreaseButton, { ChangeOrderIncreaseNotAllowedTooltip, IncreaseButton } from './QuantityButtons';
import QuantityInputField from './QuantityInputField';
import { MAX_QUANTITY_VALUE, QuantityUnit } from './types';

export interface QuantityProps {
  value: number;
  decrement: () => void;
  increment: () => void;
  unit: QuantityUnit;
  incrementValue?: number;
  isActive: boolean;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isIncrementDisabledDueToLowStock?: boolean;
}

const Quantity = ({
  value = 0,
  incrementValue = 1,
  decrement,
  increment,
  unit = PICK_UNIT.PIECES,
  isActive,
  disabled,
  minValue = 0,
  maxValue = MAX_QUANTITY_VALUE,
  onChange,
  onBlur,
  onFocus: onFieldFocus = () => undefined,
  isIncrementDisabledDueToLowStock = false,
}: QuantityProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const onKeyUp = (key: React.KeyboardEvent<HTMLInputElement>) => {
    if (key.key === 'Enter' || key.key === 'Escape') {
      const input = ref?.current;
      input?.blur();
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = ref?.current;
    input?.select();
    onFieldFocus(event);
  };

  const minusDisabled = value <= minValue || disabled;
  const quantityValue: string = value?.toString() || minValue.toString();

  return (
    <BorderBox $isActive={isActive} data-testid="quantity-input-wrapper">
      <Container data-testid="quantity-container">
        <DecreaseButton onClick={decrement} disabled={minusDisabled} />
        <QuantityInputField
          ref={ref}
          quantityValue={quantityValue}
          onLabelClick={() => ref?.current?.focus()}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          unit={unit}
          minValue={minValue}
          maxValue={maxValue}
          step={incrementValue}
          disabled={disabled}
        />
        {isIncrementDisabledDueToLowStock ? (
          <ChangeOrderIncreaseNotAllowedTooltip />
        ) : (
          <IncreaseButton onClick={increment} disabled={value >= maxValue || disabled} />
        )}
      </Container>
    </BorderBox>
  );
};

// eslint-disable-next-line fp/no-mutation
Quantity.displayName = 'Quantity';

export default Quantity;
