import LinkArea from '@components/atoms/LinkArea';
import Paragraph from '@components/atoms/Paragraph';
import UnoptimizedImage from '@components/atoms/UnoptimizedImage/UnoptimizedImage';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import ProductMessageModal from '@components/molecules/ProductMessageModal';
import ProductPrice, { mapProductPriceProps } from '@components/molecules/ProductPrice';
import ProductQuantity from '@components/molecules/ProductQuantity';
import CAMPAIGN_TYPE from '@constants/campaignType';
import { URLPREFIXES } from '@constants/links';
import { IntersectionObserverComponentProps } from '@helpers/componentTracking';
import { getProductUrl } from '@helpers/productHelper';
import useAppRouter from '@hooks/useAppRouter';
import useProductQuantityWithCart from '@hooks/useProductQuantityWithCart';
import useResponsive from '@hooks/useResponsive';
import useTranslation from 'next-translate/useTranslation';
import TagManager from 'react-gtm-module';
import ProductLabels from '../ProductLabels/ProductLabels';
import PromotionSplash from '../PromotionSplash/PromotionSplash';
import {
  LabelsWrapper,
  ProductImage,
  ProductInformation,
  ProductInformationWrapper,
  ProductManufacturerDisplayVolume,
  ProductPriceWrapper,
  ProductQuantityWrapper,
  ProductTitle,
  ProductTitleInformation,
  ProductWrapper,
  SplashWrapper,
} from './HorizontalProduct.styles';

interface Props extends IntersectionObserverComponentProps {
  product: Product;
  analyticsTracker?: string;
}
const HorizontalProduct = ({ product, intersectionRef, analyticsTracker = '' }: Props) => {
  const {
    name,
    hasPromotion,
    hasPromotionComparePrice,
    code,
    displayVolume,
    manufacturer,
    outOfStock,
    incrementValue,
    thumbnail,
    isDrugProduct,
    nicotineMedicalProduct,
    labels,
    promotion,
    price: { comparePrice, depositPrice, formattedPrice },
    analytics: { impressionObject },
    isBargainProduct: shouldShowBargain,
  } = product;
  const { promotionComparePrice } = promotion || {};

  const { t } = useTranslation('product');

  const { isTabletLandscapeOrGreater } = useResponsive();
  const shouldUseTextLabelGenerated =
    promotion?.campaignType === CAMPAIGN_TYPE.LOYALTY && promotion?.qualifyingCount && promotion?.qualifyingCount > 1;
  const promotionLabel = shouldUseTextLabelGenerated ? promotion?.textLabelGenerated : promotion?.textLabel;
  const eventListName = 'search modal';

  const shouldShowLabels = labels.includes('age_restricted_18');

  const {
    changeQuantityBy,
    onValueChange,
    onFocus,
    onBlur,
    quantity,
    quantityFieldIsActive,
    modalState,
    setModalState,
  } = useProductQuantityWithCart({
    eventListName: analyticsTracker,
    product,
  });

  const router = useAppRouter();
  const as = `${URLPREFIXES.PRODUCT_DETAIL}${encodeURI(getProductUrl(product))}`;
  const query = { ...router.query, name: encodeURIComponent(getProductUrl(product)), productCode: code };
  const hasLowestHistoricalPrice = !!promotion?.lowestHistoricalPrice;

  const handleClick = () => {
    if (eventListName) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'productClick',
          ecommerce: {
            currencyCode: 'SEK',
            click: {
              actionField: { list: eventListName },
              products: [impressionObject],
            },
          },
        },
      });
    }
  };

  return (
    <>
      <ProductWrapper data-testid="horizontal-product" ref={intersectionRef}>
        <LinkArea
          href={{ pathname: router.pathname, query }}
          nextLinkAs={{ pathname: as }}
          onClick={handleClick}
          shallow
        >
          <ProductInformationWrapper>
            {!!thumbnail && (
              <ProductImage>
                <UnoptimizedImage src={thumbnail} width="48" height="48" alt={name} />
              </ProductImage>
            )}
            <ProductInformation>
              <ProductTitleInformation>
                {shouldShowBargain && (
                  <Paragraph size="xsmall" color="primary" weight="bold">
                    {t('localBargainText')}
                  </Paragraph>
                )}
                {hasPromotion && !shouldShowBargain && (
                  <Paragraph
                    truncateText
                    size="xsmall"
                    weight="bold"
                    color={promotion?.isAlwaysGoodPrice ? 'orange' : 'primary'}
                  >
                    {promotionLabel}
                  </Paragraph>
                )}
                <ProductTitle size="md" bold="bold" truncateText>
                  {name}
                </ProductTitle>
                <ProductManufacturerDisplayVolume>
                  {manufacturer && (
                    <Paragraph size="small" weight="bold">{`${manufacturer}${displayVolume ? ', ' : ''}`}</Paragraph>
                  )}
                  {displayVolume && (
                    <Paragraph size="small" weight="medium">
                      {displayVolume}
                    </Paragraph>
                  )}
                </ProductManufacturerDisplayVolume>
              </ProductTitleInformation>
              {hasPromotion && !shouldShowLabels && (
                <SplashWrapper>
                  <PromotionSplash product={product} />
                </SplashWrapper>
              )}
              {shouldShowLabels && (
                <LabelsWrapper>
                  <ProductLabels
                    horizontal
                    labels={labels}
                    isDrugProduct={isDrugProduct}
                    nicotineMedicalProduct={nicotineMedicalProduct}
                    isAlwaysGoodPrice={promotion?.isAlwaysGoodPrice || false}
                  />
                </LabelsWrapper>
              )}
              <ProductPriceWrapper>
                {hasLowestHistoricalPrice && (
                  <Paragraph size="xsmall" data-testid="lowest-price">
                    {t('price->lowestHistorical')} {promotion?.lowestHistoricalPrice?.valueWithUnit}
                  </Paragraph>
                )}
                {hasPromotion && !hasLowestHistoricalPrice && (
                  <Paragraph size="xsmall" data-testid="ordinary-price">
                    {`${t('price->ordinary')} ${formattedPrice.valueWithUnit}`}
                  </Paragraph>
                )}
                {comparePrice.value && (
                  <Paragraph size="xsmall" color={hasPromotion ? 'primary' : 'black'} data-testid="compare-price">
                    {`${t('price->compare')}  ${
                      hasPromotionComparePrice ? promotionComparePrice?.valueWithUnit : comparePrice.valueWithUnit
                    }`}
                    {isTabletLandscapeOrGreater && <br />}
                    {depositPrice && t('price->deposit')}
                  </Paragraph>
                )}
                {isTabletLandscapeOrGreater && (
                  <ProductPrice product={mapProductPriceProps(product)} variant="horizontal" />
                )}
              </ProductPriceWrapper>
            </ProductInformation>
          </ProductInformationWrapper>
        </LinkArea>

        <ProductQuantityWrapper>
          {!isTabletLandscapeOrGreater && <ProductPrice product={mapProductPriceProps(product)} variant="horizontal" />}
          <ProductQuantity
            unit={product?.price?.pickUnitName || ''}
            isActive={quantityFieldIsActive}
            value={quantity}
            decrement={() => changeQuantityBy(-incrementValue)}
            increment={() => changeQuantityBy(incrementValue)}
            onChange={onValueChange}
            onBlur={onBlur}
            onFocus={onFocus}
            incrementValue={incrementValue}
            outOfStock={outOfStock}
          />
          {hasPromotion && <Paragraph size="xsmall">{promotion?.redeemLimitLabel}</Paragraph>}
        </ProductQuantityWrapper>
      </ProductWrapper>
      <> {modalState !== 'NONE' && <ProductMessageModal status={modalState} setStatus={setModalState} />}</>
    </>
  );
};

export default HorizontalProduct;
