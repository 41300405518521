import Button from '@components/atoms/Button';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ChangeStoreButtonWrapper = styled.div`
  padding: ${rem(16)} ${rem(24)} 0;

  ${media.tabletPortrait} {
    padding: 0;
  }
`;

export const ChangeStoreButton = styled(Button)``;

export const StoreSearchPopover = styled.div`
  position: absolute;
  top: ${rem(40)};
  right: 0;
  z-index: 1000;
  padding: 0 ${rem(24)};
  width: 100%;

  ${media.tabletPortrait} {
    width: auto;
    padding: 0;
  }
`;
