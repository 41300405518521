import { AxfoodCartViewModel } from '@occ/api-client';

const mapCart = (cart: AxfoodCartViewModel) => {
  return {
    ...cart,
    isChangingOrder: !!cart.orderReference,
  };
};

export type CartType = ReturnType<typeof mapCart>;
export default mapCart;
