import { setOnboardingEntryPoint } from '@actions/onboarding';
import Button from '@components/atoms/Button/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Notification from '@components/organisms/Header/Notification/Notification';
import LINKS from '@constants/links';
import NOTIFICATIONS from '@constants/notifications';
import { ENTRY_POINT_KLUBBVARA_POPUP } from '@features/onboarding/constants';
import { useAppDispatch } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import {
  ButtonWrapper,
  LoyaltyLoginPop,
  NotificationContent,
  NotificationHeader,
} from './LoyaltyLoginNotification.styles';
import { openLoginModal } from '@actions/login';

interface Props {
  popperReference?: HTMLElement | null;
}
const LoyaltyLoginNotification = ({ popperReference }: Props) => {
  const { t } = useTranslation('toolbar');
  const { push } = useAppRouter();
  const dispatch = useAppDispatch();
  const [popperElement, setPopperElement] = useState(null);

  const handleLoginClick = () => {
    dispatch(setOnboardingEntryPoint(ENTRY_POINT_KLUBBVARA_POPUP));
    dispatch(openLoginModal({ b2b: false, isLoginConfirm: false }));
  };

  const handleSignupClick = () => {
    dispatch(setOnboardingEntryPoint(ENTRY_POINT_KLUBBVARA_POPUP));
    push(LINKS.B2C_CREATE_ACCOUNT);
  };

  const { styles, attributes } = usePopper(popperReference, popperElement, {
    placement: 'bottom-end',
  });

  return (
    <div ref={setPopperElement as any} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
      <Notification notificationName={NOTIFICATIONS.LOYALTY_LOGIN_NOTIFICATION} timer={5000} closeable>
        <LoyaltyLoginPop>
          <NotificationHeader>{t('toolbar->loyaltyLogin->header')}</NotificationHeader>
          <NotificationContent>
            <Paragraph>{t('toolbar->loyaltyLogin->content')}</Paragraph>
          </NotificationContent>
          <ButtonWrapper>
            <Button variant={'secondary'} onClick={handleSignupClick}>
              {t('common:navigation->createAccount')}
            </Button>
            <Button onClick={handleLoginClick}>{t('minicart:minicart->links->login')}</Button>
          </ButtonWrapper>
        </LoyaltyLoginPop>
      </Notification>
    </div>
  );
};

export default LoyaltyLoginNotification;
