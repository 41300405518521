import PICK_UNIT from '@constants/pickUnit';
import useCustomer from '@hooks/useCustomer';
import { useDebounceFn } from '@hooks/useDebounce';
import { AddToCartForm, AxfoodCartProductViewModel } from '@occ/api-client';
import useSWR from 'swr';
import { addToCart, getCart } from './api';

async function updateCart(addToCartForm: Array<AddToCartForm>, storeId?: string) {
  const response = await addToCart(addToCartForm, storeId);
  return response;
}

const DEBOUNCE_TIME = 1000;
export const UPDATE_CART_CANCELLED = 'UPDATE_CART_CANCELLED';

export const validateCartRequest = async (
  requestProduct: AddToCartForm,
  storeId?: string,
  products?: AxfoodCartProductViewModel[]
) => {
  const productInCart = products?.find((cartProduct) => cartProduct.code === requestProduct.productCodePost);

  // If the product is not in the cart and the updated quantity after the debounce is 0, don't update the cart
  if (!productInCart && requestProduct.qty === 0) {
    return undefined;
  }

  const isPickUnitKG = requestProduct.pickUnit === PICK_UNIT.KILOGRAM;
  const quantity = isPickUnitKG ? productInCart?.pickQuantity : productInCart?.quantity;
  // If the product is in the cart and the updated quantity after the debounce is the same as the current quantity, don't update the cart
  if (quantity === requestProduct.qty) {
    return undefined;
  }

  const newData = await updateCart([requestProduct], storeId);

  return newData;
};

const useCart = () => {
  const { customerId, customerFetchIsRequired } = useCustomer();

  const key = {
    endpoint: '/api/cart',
    customerId,
  };
  const shouldFetch = customerFetchIsRequired;
  const { data, error, mutate, isLoading, isValidating } = useSWR(shouldFetch ? key : null, getCart);

  const debouncedUpdateCart = useDebounceFn(
    async (requestProduct: AddToCartForm, storeId?: string) =>
      validateCartRequest(requestProduct, storeId, data?.products),
    DEBOUNCE_TIME
  );

  return {
    cart: data,
    hasProducts: Boolean(data?.products?.length),
    hasFetchedCart: Boolean(data && !isLoading),
    error,
    isLoading,
    isValidating,
    refreshCart: mutate,
    // add a single or multiple products to the cart without debounce
    addProductsToCart: async (addToCartForm: Array<AddToCartForm>, storeId?: string) => {
      return mutate(await updateCart(addToCartForm, storeId));
    },
    // handle multiple request to update quantity of a single product with debounce and possible cancellation
    addProductWithDebounce: async (product: AddToCartForm, storeId?: string) => {
      const newCart = await debouncedUpdateCart(product, storeId);

      if (!newCart) {
        mutate();
        // eslint-disable-next-line fp/no-throw
        throw new Error(UPDATE_CART_CANCELLED);
      }
      return mutate(newCart, {
        // TODO: Add calculations to figure out the optimistic product count. Weight based products etc
        // optimisticData: {...data, totalUnitCount: addToCartForm.length },
        // rollbackOnError: true,
        // populateCache: true,
        // revalidate: false,
      });
    },
  };
};

export default useCart;
