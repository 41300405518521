import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { Stack } from '@components/atoms/Stack';
import { ProductDetailsData } from '@components/organisms/ProductDetails/useGetProductDetails/mapping';
import { capitalizeFirst } from '@helpers/string';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import DailyRecommendedIntake from './DailyRecommendedIntake/DailyRecommendedIntake';
import { InfoBlock } from './ProductInformationListing.styles';

interface ProductInformationListingProps {
  product: ProductDetailsData;
}

const ProductInformationListing = ({ product }: ProductInformationListingProps) => {
  const { t } = useTranslation('productDetails');
  const { animalData } = product;

  return (
    <Stack $space="small">
      <DailyRecommendedIntake product={product} />
      {animalData && (
        <>
          {animalData?.typeCode && animalData?.targetedConsumptionBy && (
            <Heading type="h4" color="red">
              {`${capitalizeFirst(animalData.typeCode)} för ${animalData.targetedConsumptionBy}`}
            </Heading>
          )}
          {animalData?.compositionStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->compositionStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="composition-statement">
                {animalData.compositionStatement}
              </Paragraph>
            </InfoBlock>
          )}
          {animalData?.constituentStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->constituentStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="constituent-statement">
                {animalData.constituentStatement}
              </Paragraph>
            </InfoBlock>
          )}
          {animalData?.additiveStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->additiveStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="additive-statement">
                {animalData.additiveStatement}
              </Paragraph>
            </InfoBlock>
          )}
        </>
      )}
    </Stack>
  );
};

export default ProductInformationListing;
