import { mapHeadingSize } from '@components/atoms/Heading/Heading.styles';
import { createGlobalStyle } from 'styled-components';
import normalize from './normalize';
import pureConnect from './pureConnect.styles';
import { caveatBold, objektiv, objektivItalic, sentinel } from './typography';

const GlobalStyles = createGlobalStyle`
  :root {
    --font-objektiv: ${objektiv.style.fontFamily};
    --font-objektiv-italic: ${objektivItalic.style.fontFamily};
    --font-sentinel: ${sentinel.style.fontFamily};
    --font-caveat: ${caveatBold.style.fontFamily};
  }
  ${normalize}
  body {
    ${pureConnect}
    background-color: ${({ theme }) => theme.colors.colorDesertStorm};
    color: ${({ theme }) => theme.colors.colorTextPrimary};
    font-family: ${({ theme }) => theme.typography.fontSans};
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }
  h1, h2, h3, h4 {
    font-family: ${({ theme }) => theme.typography.fontSerif};
  }
  h1 {
    ${mapHeadingSize['large']}
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  }
  h2 {
    ${mapHeadingSize['medium']}
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  }
  h3 {
    ${mapHeadingSize['small']}
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  }

  h4 {
    ${mapHeadingSize['xSmall']}
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  }


  a, button, input{
    color: ${({ theme }) => theme.colors.colorTextPrimary};
  }
  svg {
    pointer-events: none;
  }
  menu {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;
