import localFont from 'next/font/local';

export const caveatBold = localFont({
  src: './Caveat-Bold.woff2',
  weight: '700',
  style: 'normal',
  preload: true,
  display: 'swap',
  variable: '--font-caveat',
});

export const sentinel = localFont({
  src: [
    {
      path: './Sentinel-Book.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './Sentinel-Bold_Web.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './Sentinel-Black_Web.woff2',
      weight: '900',
      style: 'normal',
    },
  ],
  preload: true,
  display: 'swap',
  fallback: ['serif'],
  variable: '--font-sentinel',
});

export const objektivItalic = localFont({
  src: './ObjektivMk2_W_XBdIt.woff2',
  style: 'italic',
  preload: true,
  display: 'swap',
  fallback: ['sans-serif'],
  variable: '--font-objektiv-italic',
});

export const objektiv = localFont({
  src: [
    { path: './ObjektivMk2_W_Rg.woff2', weight: '400', style: 'normal' },
    { path: './ObjektivMk2_W_Md.woff2', weight: '500', style: 'normal' },
    { path: './ObjektivMk2_W_Bd.woff2', weight: '700', style: 'normal' },
  ],
  preload: true,
  display: 'swap',
  fallback: ['sans-serif'],
  variable: '--font-objektiv',
});

const typography = {
  fontSans: 'var(--font-objektiv)',
  fontSerif: 'var(--font-sentinel)',
  fontSansItalicBold: 'var(--font-objektiv-italic)',
  fontCaveat: 'var(--font-caveat)',
};

export type ThemeTypography = typeof typography;
export default typography;
