export const resolveUrlString = (href: UrlType) => {
  let urlString = '';
  if (href instanceof Object && typeof href.pathname === 'string') {
    urlString = href.pathname;
  } else if (typeof href === 'string') {
    urlString = href;
  }

  return urlString;
};
