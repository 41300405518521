import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import IconTime from '@public/icons/regularIcons/icon-time.svg';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(5)};
`;

interface Props {
  cookingTime: number;
  color: 'white' | 'black';
}

const CookingTime = ({ cookingTime, color }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <Icon icon={IconTime} color={color} data-testid="icon-time" />
      <Paragraph size="medium" weight="medium" color={color}>
        {cookingTime} {t('units->minutes')}
      </Paragraph>
    </Container>
  );
};

export default memo(CookingTime);
