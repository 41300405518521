import LinkArea from '@components/atoms/LinkArea';
import { getExternalLink, getTrackerLinkName } from '@components/cmsComponents/AxfoodFooterComponent/externalLinks';
import { navigationClickTracker } from '@trackers';
import { ReactElement } from 'react';

const FooterLink = ({
  linkId,
  children,
  'aria-label': ariaLabel,
}: {
  linkId: string;
  children: ReactElement;
  'aria-label'?: string;
}) => (
  <LinkArea
    href={getExternalLink(linkId)}
    target="_blank"
    onClick={() => navigationClickTracker.linkClickTracker('footer', getTrackerLinkName(linkId))}
    aria-label={ariaLabel}
  >
    {children}
  </LinkArea>
);

export default FooterLink;
