import { MergeModalActionType } from '@components/organisms/MergeCartModal';
import { KEEP_PERSISTENT, KEEP_SESSION, MERGE } from '@constants/cart';
import TagManager from 'react-gtm-module';

export const trackMergeAction = (actionOption: MergeModalActionType) => {
  const ActionStrings = {
    [MERGE]: 'merge_carts',
    [KEEP_PERSISTENT]: 'loggedin_cart',
    [KEEP_SESSION]: 'anonymous_cart',
  };
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'cart_merge_modal',
      action: `continue_with_${ActionStrings[actionOption]}`,
      label: '',
      value: 0,
    },
  });
};

export const trackMergeModalShown = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'cart_merge_modal',
      action: `merge_modal_shown`,
      label: '',
      value: 0,
    },
  });
};

export const trackMergeModalClosed = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'cart_merge_modal',
      action: 'merge_modal_closed',
      label: '',
      value: 0,
    },
  });
};
