import { setPaymentAuthStatus } from '@actions/checkout';
import { closeLoginModal } from '@actions/login';
import { PAYMENT_AUTH_STATUS_IS_SUCCESS } from '@features/checkout/constants';
import { useAppDispatch } from '@hooks/redux';
import { useCallback } from 'react';

const useHandleLoginClose = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    ({ loginConfirmSuccess }: { loginConfirmSuccess: boolean }) => {
      dispatch(closeLoginModal());
      if (loginConfirmSuccess) {
        dispatch(setPaymentAuthStatus(PAYMENT_AUTH_STATUS_IS_SUCCESS));
      }
    },
    [dispatch]
  );
};

export default useHandleLoginClose;
