import { FacetValueDataOfSearchStateData } from '@api/generated/storefront';
import Button from '@components/atoms/Button';
import Paragraph from '@components/atoms/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import { memo, useState } from 'react';
import { ListWrapper } from '../../organisms/ProductListFilters/ProductListFilters.styles';
import Tablist, { Tab } from '../Tablist/Tablist';

interface Props {
  facetList: FacetValueDataOfSearchStateData[];
  onFilterClick: (newQuery: string, name: string, selected: boolean) => void;
  title?: string;
  showCount?: boolean;
}

export const sliceIndex = 10;
const FilterList = ({ facetList, onFilterClick, title, showCount = true }: Props) => {
  const { t } = useTranslation('common');
  const [isShowingAll, setShowAll] = useState(false);
  const hasSelectedItems = !!facetList.filter((f) => f?.selected).length;

  const items = isShowingAll || hasSelectedItems ? facetList : facetList.slice(0, sliceIndex);
  const renderShowMoreButton = facetList.length > sliceIndex && !isShowingAll && !hasSelectedItems;

  const list: Tab[] = items.map((facet: FacetValueDataOfSearchStateData) => {
    const { count, selected = false, name = '', query } = facet;
    const newQuery = query?.query?.value || '';
    return {
      content: (
        <>
          {name}&nbsp;
          {showCount && !!count ? `(${count})` : null}
        </>
      ),
      selected,
      onClick: () => onFilterClick(newQuery, name, selected),
      key: name,
    };
  });

  return (
    <ListWrapper>
      {!!items.length && (
        <>
          {title && <Paragraph weight="medium">{title}</Paragraph>}
          <Tablist list={list}>
            <>
              {renderShowMoreButton ? (
                <Button variant="primaryOutlined" onClick={() => setShowAll(true)}>
                  {t('defaultActions->showMore')} ({facetList.length - sliceIndex})
                </Button>
              ) : null}
            </>
          </Tablist>
        </>
      )}
    </ListWrapper>
  );
};

export default memo(FilterList);
