import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Table from '@components/atoms/Table';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledParagraph = styled(Paragraph)`
  padding: ${rem(8)};
`;

export const AlignRight = styled.div`
  text-align: right;
`;

export const StyledTable = styled(Table)`
  th:last-of-type,
  td:last-of-type {
    text-align: right;
  }
`;
