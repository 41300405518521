import Button from '@components/atoms/Button';
import Heading from '@components/atoms/Heading';
import Icon from '@components/atoms/Icon';
import IconClose from '@public/icons/regularIcons/icon-close.svg';
import { Close, FooterWrapper, Header } from './Panel.styles';

const PanelHeader = ({ title, closePanel, closeText }: { title: string; closePanel: () => void; closeText: string }) => {
  return (
    <Header>
      <Heading type="h2" size="small">
        {title}
      </Heading>
      <Close onClick={closePanel} aria-label={closeText}>
        <Icon icon={IconClose} /> {closeText}
      </Close>
    </Header>
  );
};

interface ButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

const PanelFooter = ({ primary, secondary }: { primary: ButtonProps; secondary: ButtonProps }) => {
  return (
    <FooterWrapper>
      <Button variant="secondary" disabled={secondary.disabled} onClick={secondary.onClick} aria-label={secondary.text}>
        {secondary.text}
      </Button>
      <Button onClick={primary.onClick} aria-label={primary.text} disabled={primary.disabled}>
        {primary.text}
      </Button>
    </FooterWrapper>
  );
};

export { PanelFooter, PanelHeader };
