import { getCampaigns } from '@api';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { DESKTOP_XXL_PRODUCTS_AMOUNT } from '@constants/promotionProductsGrid';
import logError from '@helpers/logHelpers';
import { usePromotionStore } from '@hooks/localStorage';
import useAssortmentKey from '@hooks/useAssortmentKey';
import useCustomer from '@hooks/useCustomer';
import axios from 'axios';
import { useEffect, useState } from 'react';

const usePromotedProductsRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const { homeStoreId } = useCustomer();
  const [promotionStore] = usePromotionStore();
  const { promotionStoreId = '' } = promotionStore;
  const assortmentKey = useAssortmentKey();

  useEffect(() => {
    if (!assortmentKey) {
      return;
    }
    const source = axios.CancelToken.source();
    let isCancelled = false;

    const fetchData = async () => {
      !isCancelled && setIsLoading(true);
      try {
        const campaigns = await getCampaigns(0, DESKTOP_XXL_PRODUCTS_AMOUNT * 2, source.token);
        if (campaigns.results && !isCancelled) {
          setProducts(campaigns.results);
        }
      } catch (e) {
        logError('Failed to get PromotedProductsComponent products', e);
      } finally {
        !isCancelled && setIsLoading(false);
      }
    };

    if (!isCancelled) {
      fetchData();
    }

    return () => {
      isCancelled = true;
      source.cancel('Cancel PromotedProducts get campaign request');
    };
  }, [assortmentKey, assortmentKey?.assortmentStoreId, assortmentKey?.slotId, promotionStoreId, homeStoreId]);

  return {
    products,
    isLoading,
  };
};

export default usePromotedProductsRequest;
