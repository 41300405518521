import { BankIdAuthStatusType, BankIdHintCodeType } from '@constants/bankid';
import { BANK_ID_DEVICES, LoginAlertType } from '@features/login/constants';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import * as types from './types';

type OpenLoginModal = {
  type: typeof types.OPEN_LOGIN_MODAL;
  b2b: boolean;
  isLoginConfirm: boolean;
};

type OpenLoginModalWithGuest = {
  type: typeof types.OPEN_LOGIN_MODAL_WITH_GUEST;
  b2b: boolean;
};

type CloseLoginModal = {
  type: typeof types.CLOSE_LOGIN_MODAL;
};

type ToggleCustomerType = {
  type: typeof types.TOGGLE_CUSTOMER_TYPE;
};

type SetCurrentHintCode = {
  type: typeof types.SET_CURRENT_HINT_CODE;
  payload: BankIdHintCodeType;
};

type SetCurrentStatus = {
  type: typeof types.SET_CURRENT_STATUS;
  payload: BankIdAuthStatusType;
};

type SetQrData = {
  type: typeof types.SET_QR_DATA;
  payload: string;
};

type SetBankIdDeviceType = {
  type: typeof types.SET_BANK_ID_DEVICE_TYPE;
  payload: BANK_ID_DEVICES;
};

type SetLoginAlert = {
  type: typeof types.SET_LOGIN_ALERT;
  payload: LoginAlertType;
};

type SetShowOnboardingInfo = {
  type: typeof types.SET_SHOW_ONBOARDING_INFO;
};

interface SetBankIdStatusAndHintCode {
  ({
    hintCode,
    status,
  }: {
    status: BankIdAuthStatusType;
    hintCode: BankIdHintCodeType;
  }): ThunkAction<void, RootState, unknown, AnyAction>;
}
export const openLoginModal = ({ b2b = false, isLoginConfirm = false } = {}): OpenLoginModal => ({
  type: types.OPEN_LOGIN_MODAL,
  b2b,
  isLoginConfirm,
});

export const openLoginModalWithGuest = ({ b2b = false }): OpenLoginModalWithGuest => ({
  type: types.OPEN_LOGIN_MODAL_WITH_GUEST,
  b2b,
});

export const closeLoginModal = (): CloseLoginModal => ({
  type: types.CLOSE_LOGIN_MODAL,
});

export const toggleCustomerType = (): ToggleCustomerType => ({
  type: types.TOGGLE_CUSTOMER_TYPE,
});

export const setCurrentHintCode = (hintCode: BankIdHintCodeType): SetCurrentHintCode => ({
  type: types.SET_CURRENT_HINT_CODE,
  payload: hintCode,
});

export const setCurrentStatus = (status: BankIdAuthStatusType): SetCurrentStatus => ({
  type: types.SET_CURRENT_STATUS,
  payload: status,
});

export const setQrData = (qrData: string): SetQrData => ({
  type: types.SET_QR_DATA,
  payload: qrData,
});

export const setBankIdDevice = (bankIdDevice: BANK_ID_DEVICES): SetBankIdDeviceType => ({
  type: types.SET_BANK_ID_DEVICE_TYPE,
  payload: bankIdDevice,
});

export const setLoginAlert = (alert: LoginAlertType): SetLoginAlert => ({
  type: types.SET_LOGIN_ALERT,
  payload: alert,
});

export const setBankIdStatusAndHintCode: SetBankIdStatusAndHintCode = ({ hintCode, status }) => {
  return (dispatch) => {
    dispatch(setCurrentStatus(status));
    dispatch(setCurrentHintCode(hintCode));
  };
};
export const setShowOnboardingInfo = (): SetShowOnboardingInfo => ({
  type: types.SET_SHOW_ONBOARDING_INFO,
});

export type LoginModalActionTypes =
  | OpenLoginModal
  | OpenLoginModalWithGuest
  | CloseLoginModal
  | ToggleCustomerType
  | SetCurrentHintCode
  | SetCurrentStatus
  | SetQrData
  | SetBankIdDeviceType
  | SetLoginAlert
  | SetShowOnboardingInfo;
