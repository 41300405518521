import { TableColumnsType, TableRowType } from '@components/atoms/Table/types';
import {
  AxfoodProductDetailsViewModel,
  NutrientFactData,
  ProductInfoNutrientDetailData,
  ProductInfoNutrientHeaderData,
} from '@occ/api-client';

export interface DriRow {
  nutritionDescription: string;
  nutrientHeaderRow: TableColumnsType[];
  rowData: TableRowType[];
}

export interface ProductDetailsData extends AxfoodProductDetailsViewModel {
  dailyRecommendedIntakeData: DriRow[];
  formattedNutritionsFactList: TableRowType[];
}

// mapped type with required typeCode field
type RequiredNutrientFactData = NutrientFactData & Required<Pick<NutrientFactData, 'typeCode'>>;

// type guard
const isRequiredNutrientFactData = (item: NutrientFactData): item is RequiredNutrientFactData => !!item.typeCode;

export const mapProductDetails = (productDetailsData: AxfoodProductDetailsViewModel): ProductDetailsData => {
  const { nutrientHeaders, nutritionsFactList } = productDetailsData;
  const dailyRecommendedIntakeData = nutrientHeaders?.length
    ? nutrientHeaders.map((nutrient) => ({
        nutritionDescription: getNutritionDescription(nutrient),
        nutrientHeaderRow: getNutrientHeaderRow(nutrient),
        rowData: nutrient.nutrientDetails?.length ? mapNutrientRowData(nutrient.nutrientDetails) : [],
      }))
    : [];

  const formattedNutritionsFactList = nutritionsFactList.length
    ? nutritionsFactList
        .filter(isRequiredNutrientFactData)
        .map(({ typeCode, measurementPrecisionCode = '', value = '', unitCode = '' }) => ({
          data: [
            { key: 'typeCode', value: typeCode },
            { key: 'value', value: `${measurementPrecisionCode} ${value} ${unitCode}` },
          ],
        }))
    : [];

  return { ...productDetailsData, dailyRecommendedIntakeData, formattedNutritionsFactList };
};

export const getNutritionDescription = (nutrient: ProductInfoNutrientHeaderData): string => {
  return `${nutrient.nutrientBasisQuantity} ${nutrient.nutrientBasisQuantityMeasurementUnitCode}`;
};

const getNutrientHeaderRow = ({
  nutrientBasisQuantity,
  nutrientBasisQuantityMeasurementUnitCode,
}: ProductInfoNutrientHeaderData) => [
  { id: 'typeCode', label: 'Innehåll' },
  {
    id: 'value',
    label: `${nutrientBasisQuantity} ${nutrientBasisQuantityMeasurementUnitCode}`,
  },
  { id: 'dri', label: 'DRI*' },
];

export const mapNutrientRowData = (details: ProductInfoNutrientDetailData[]) =>
  details.map(
    ({ nutrientTypeCode = '', quantityContained = '', measurementUnitCode = '', dailyValueIntakePercent }) => ({
      data: [
        { key: 'typeCode', value: nutrientTypeCode },
        { key: 'value', value: `${quantityContained} ${measurementUnitCode}` },
        { key: 'dri', value: dailyValueIntakePercent ? `${dailyValueIntakePercent}%` : '' },
      ],
    })
  );
