import DELIVERY_METHODS from '@constants/deliveryMethods';
import LINKS from '@constants/links';
import useCart from '@features/cart/useCart';
import useAppRouter from '@hooks/useAppRouter';
import { useCallback } from 'react';

const useOpenDeliveryModal = () => {
  const { cart: { deliveryModeCode = '' } = {} } = useCart();
  const { push, pathname, query } = useAppRouter();

  const openDeliveryModal = useCallback(() => {
    const link =
      deliveryModeCode !== DELIVERY_METHODS.HOME_CODE ? LINKS.DELIVERY_METHOD_PICKUP : LINKS.DELIVERY_METHOD_HOME;
    push({ pathname, query }, link, { shallow: true, scroll: false });
  }, [deliveryModeCode, pathname, query, push]);

  const openDeliveryModalWithAddress = useCallback(
    (address: string) => {
      const newQuery = !!address ? { ...query, address: encodeURIComponent(address) } : query;
      push({ pathname, query: newQuery }, LINKS.DELIVERY_METHOD_HOME, { shallow: true, scroll: false });
    },
    [deliveryModeCode, pathname, query, push]
  );

  return { openDeliveryModal, openDeliveryModalWithAddress };
};

export default useOpenDeliveryModal;
