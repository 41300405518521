import { openLoginModal } from '@actions/login';
import { confirmGuestCheckout } from '@actions/validators';
import MergeCartModal from '@components/organisms/MergeCartModal';
import { LOGIN_CONFIRM } from '@features/login/constants';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { selectLoginAlert, selectLoginModalIsVisible, selectLoginModalIsWithGuest } from '@selectors/login';
import dynamic from 'next/dynamic';
import { memo, useEffect, useState } from 'react';
import useCloseLoginModalOnRouteChange from './useCloseLoginModalOnRouteChange';
import useHandleLoginClose from './useHandleLoginClose';

const DynamicLogin = dynamic(() => import('@features/login'));

const LoginModal = () => {
  const dispatch = useAppDispatch();
  const loginModalIsVisible = useAppSelector(selectLoginModalIsVisible);
  const withGuestLogin = useAppSelector(selectLoginModalIsWithGuest);

  const handleLoginClose = useHandleLoginClose();
  const [persistedCart, setPersistedCart] = useState<persistedCartType | null>(null);

  useCloseLoginModalOnRouteChange();

  const handleGuestConfirm = () => {
    dispatch(confirmGuestCheckout());
    handleLoginClose({ loginConfirmSuccess: false });
  };
  const loginAlert = useAppSelector(selectLoginAlert);
  const alertIsLoginConfirm = loginAlert === LOGIN_CONFIRM;

  useEffect(() => {
    // opens modal if interceptors has recieved 402 | 403
    if (loginAlert && !loginModalIsVisible) {
      dispatch(openLoginModal({ b2b: alertIsLoginConfirm, isLoginConfirm: alertIsLoginConfirm }));
    }
  }, [loginAlert, dispatch, loginModalIsVisible, alertIsLoginConfirm]);

  return (
    <>
      {loginModalIsVisible && (
        <DynamicLogin
          onClose={handleLoginClose}
          withGuestLogin={withGuestLogin}
          onGuestConfirm={handleGuestConfirm}
          setPersistedCart={setPersistedCart}
        />
      )}
      {!loginModalIsVisible && persistedCart && persistedCart.totalPersistedCartProducts > 0 && (
        <MergeCartModal persistedCart={persistedCart} setPersistedCart={setPersistedCart} />
      )}
    </>
  );
};

export default memo(LoginModal);
