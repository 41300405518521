import Text from '@components/atoms/Text';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import CAMPAIGN_TYPE from '@constants/campaignType';
import { URLPREFIXES } from '@constants/links';
import PRODUCT_LABELS from '@constants/productLabels';
import PROMOTION_THEME, { PROMOTION_TYPE } from '@constants/promotion';
import { getLowestHistoricalPrice, promotionComparePriceIsSame, shouldShowOrdinaryPrice } from '@helpers/productHelper';
import useTranslation from 'next-translate/useTranslation';
import ProductEnergyDeclarationLabel from './ProductEnergyDeclarationLabel/ProductEnergyDeclarationLabel';
import {
  Bottom,
  Discount,
  ProductComparePrices,
  ProductManufacturer,
  ProductManufacturerVolume,
  ProductTitle,
  PromotionLabel,
  Wrapper,
} from './ProductInformation.styles';

interface ProductInformationProps {
  product: ProductVariantType;
  url: UrlType;
  priceUnit: string;
  disableLinks?: boolean;
  handleProductLinkClick?: () => void;
}

const urlPrefix = URLPREFIXES.PRODUCT_DETAIL;

const ProductInformation = ({
  product,
  url,
  priceUnit,
  disableLinks,
  handleProductLinkClick,
}: ProductInformationProps) => {
  const { t } = useTranslation('product');
  const {
    name,
    comparePrice: productComparePrice,
    potentialPromotions,
    isDrugProduct,
    nicotineMedicalProduct,
    energyDeclaration,
    comparePriceUnit,
    displayVolume,
    manufacturer,
    online,
    depositPrice,
    price,
    labels,
  } = product;
  const promotion = potentialPromotions[0];
  const hasPromotion = !!promotion;
  const {
    comparePrice: promotionComparePrice,
    endDate,
    promotionType,
    qualifyingCount,
    campaignType,
    textLabelGenerated,
    textLabel,
  } = hasPromotion && promotion;
  const promotionTheme = promotion?.promotionTheme?.code;
  const isAlwaysGoodPriceTheme = promotionTheme === PROMOTION_THEME.ALWAYS_GOOD_PRICE;
  const hasUnitInComparePriceAttribute =
    hasPromotion &&
    promotionComparePrice &&
    (promotionComparePrice.includes('/kg') ||
      promotionComparePrice.includes('/l') ||
      promotionComparePrice.includes('/st'));

  const lowestHistoricalPrice = getLowestHistoricalPrice(product);
  const hasLowestHistoricalPrice = !!lowestHistoricalPrice;
  const isSingelProductAlwaysGoodPricePromotionProduct =
    isAlwaysGoodPriceTheme && promotionType !== PROMOTION_TYPE.MIX_MATCH_PRICE_PROMOTION;
  const showOrdinaryPrice = shouldShowOrdinaryPrice(product) && !isSingelProductAlwaysGoodPricePromotionProduct;
  const showPromotionComparePrice =
    hasPromotion &&
    !promotionComparePriceIsSame(product) &&
    !isSingelProductAlwaysGoodPricePromotionProduct &&
    promotionComparePrice;
  const showProductComparePrice = !!productComparePrice && (!hasPromotion || isAlwaysGoodPriceTheme);
  const promotionComparePriceColor = !online ? 'black' : 'primary';
  const showIsDrugProduct = isDrugProduct || nicotineMedicalProduct;

  const comparePriceUnitWithSlash = comparePriceUnit ? `/${comparePriceUnit}` : '';

  const shouldUseTextLabelGenerated = campaignType === CAMPAIGN_TYPE.LOYALTY && qualifyingCount > 1;
  const promotionLabel = shouldUseTextLabelGenerated ? textLabelGenerated : textLabel;

  return (
    <Wrapper>
      <PromotionLabel>
        {hasPromotion && (
          <Paragraph truncateText size="xs" bold="bold" textColor={isAlwaysGoodPriceTheme ? 'orange' : 'primary'}>
            {promotionLabel}
          </Paragraph>
        )}
        {showIsDrugProduct && (
          <Paragraph textColor="black" bold="bold" data-testid="is-drug-product">
            {t('product:isDrugProduct')}
          </Paragraph>
        )}
      </PromotionLabel>
      <ProductTitle data-testid="product-title">
        <Text type="p" size="md" bold="bold" color="black" truncateText>
          {online ? (
            <Link
              href={url}
              underlined={false}
              disabled={disableLinks}
              title={name}
              shallow
              onClick={handleProductLinkClick}
              forceInternal
              dynamicUrlPrefix={urlPrefix}
              margin={false}
            >
              {name}
            </Link>
          ) : (
            name
          )}
        </Text>
      </ProductTitle>
      <ProductManufacturerVolume>
        <Paragraph size="xs">
          {manufacturer && (
            <ProductManufacturer data-testid="display-manufacturer">
              {`${manufacturer}${displayVolume ? ', ' : ''}`}
            </ProductManufacturer>
          )}
          {!!displayVolume && <span data-testid="display-volume">{`${displayVolume}`}</span>}
        </Paragraph>
      </ProductManufacturerVolume>
      <Bottom>
        <ProductComparePrices>
          {hasLowestHistoricalPrice ? (
            <Paragraph size="xs">{`${t('product:price->lowestHistorical')} ${lowestHistoricalPrice}`}</Paragraph>
          ) : (
            showOrdinaryPrice && (
              <Paragraph size="xs" data-testid="ordinary-price">
                {`${t('product:price->ordinary')} ${price}${priceUnit === 'kg' ? '' : `/${priceUnit}`}`}
              </Paragraph>
            )
          )}
          {showProductComparePrice && (
            <Paragraph size="xs" data-testid="compare-price">
              {`${t('product:price->compare')} ${productComparePrice}${comparePriceUnitWithSlash}`}
              {!!depositPrice && <span>{t('product:price->deposit')}</span>}
            </Paragraph>
          )}
          {showPromotionComparePrice && (
            <Discount>
              <Paragraph size="xs" data-testid="promotion-compare-price" textColor={promotionComparePriceColor}>
                {`${t('product:price->compare')} ${
                  hasUnitInComparePriceAttribute
                    ? promotionComparePrice
                    : `${promotionComparePrice}${comparePriceUnitWithSlash}`
                }`}
                {!isAlwaysGoodPriceTheme && !!depositPrice && <span>{t('product:price->deposit')}</span>}
              </Paragraph>
            </Discount>
          )}
          {hasPromotion && endDate && (
            <Paragraph size="xs" data-testid="end-date">
              {`${t('product:availableTill')} ${endDate}`}
            </Paragraph>
          )}
          {labels?.includes(PRODUCT_LABELS['age_restricted_18']) && (
            <Paragraph size="xs">{t('product:AgeRestriction18')}</Paragraph>
          )}
        </ProductComparePrices>
        {!!energyDeclaration && (
          <ProductEnergyDeclarationLabel
            url={url}
            energyDeclaration={energyDeclaration}
            isOnlineProduct={online}
          />
        )}
      </Bottom>
    </Wrapper>
  );
};

export default ProductInformation;
