import {
  AxfoodCartProductViewModel,
  AxfoodOrderProductViewModel,
  AxfoodPromotionViewModel,
  ProductBasketTypeEnum,
  UnitData,
} from '@api/generated/storefront';
import { GRID_GAP, MIN_PRODUCT_CARD_WITH_GAP } from '@components/atoms/ProductGrid/ProductGrid.styles';
import { Impression, Product } from '@components/molecules/Product/ProductDataMapper/types';
import PICK_UNIT from '@constants/pickUnit';
import { PROMOTION_TYPE } from '@constants/promotion';
import stringHelpers from '@helpers/string';
import type { ParsedUrlQuery } from 'querystring';
import { RefObject } from 'react';
import { getCurrentAppliedFilters, getCurrentSearchKey } from './productFilterHelpers';
import { getPriceFromPromotion, getPromotionPriceFromRewardLabel, shouldShowPromotionPrice } from './promotions';

const getProductUrl = (product: ProductVariantType) => {
  return `${stringHelpers.slugify(product.name)}-${product.code}`;
};

const getPreviewUrl = (product: ProductVariantType, promotion: AxfoodPromotionViewModel) => {
  return `${stringHelpers.slugify(product.name)}-${promotion.mainProductCode}`;
};

const getImpressionsObjectPrice = (product: ProductVariantType) => {
  const piecesItem = !product.productBasketType || product.productBasketType.code === 'ST';
  const price = product.priceValue || 0;
  const promotion = product?.potentialPromotions && product?.potentialPromotions[0];
  const hasPromotion = !!promotion;
  const promotionPrice =
    hasPromotion && shouldShowPromotionPrice(promotion) && promotion.price?.formattedValue
      ? parseFloat(promotion.price.formattedValue)
      : price;
  if (
    piecesItem &&
    product?.price?.length &&
    product?.price?.substring(product.price.length - 2) === 'kg' &&
    product?.averageWeight
  ) {
    return Math.round(product.averageWeight * promotionPrice * 100) / 100;
  }

  return getActualCustomerPrice(product);
};

// ToDo: remove below code when GA360 no longer in use
/* eslint-disable no-nested-ternary */

const productToImpressionObject = (product: ProductVariantType): Impression => {
  return {
    id: product.code,
    name: product.name,
    brand: product.manufacturer ? product.manufacturer.toLowerCase() : '',
    price: getImpressionsObjectPrice(product),
    category: product.googleAnalyticsCategory || '',
  };
};

const getActualCustomerPrice = (product: ProductVariantType): number => {
  const GIFT_LABEL = 'gåva!';
  const promotion = !!product.potentialPromotions?.length && product.potentialPromotions[0];
  const hasPromotion = !!promotion;
  const { promotionPrice, promotionDecimal } = getPromotionPriceFromRewardLabel(promotion?.rewardLabel);
  const promotionPriceWithDecimal = parseFloat(`${promotionPrice}.${promotionDecimal}`);

  if (hasPromotion && shouldShowPromotionPrice(promotion)) {
    if (hasPromotion && promotion.promotionType === PROMOTION_TYPE.MIX_MATCH_PERCENTAGE_PROMOTION) {
      return getPriceFromPromotion(promotion);
    }
    if (hasPromotion && promotion?.rewardLabel?.toLowerCase() !== GIFT_LABEL) {
      return promotionPriceWithDecimal;
    }
    if (promotion?.rewardLabel.toLowerCase() === GIFT_LABEL) {
      return 0;
    }
  }
  return product.priceValue || 0;
};
// GA4 tracking
/* eslint-disable no-nested-ternary */
const productToImpressionObjectGa4 = (product: ProductVariantType, listName?: string) => {
  return {
    item_id: product.code,
    item_name: product.name,
    item_brand: product.manufacturer ? product.manufacturer.toLowerCase() : '',
    item_category: product.googleAnalyticsCategory || '',
    item_list_name: listName,
    price: getImpressionsObjectPrice(product),
  };
};

export interface QuantityProduct {
  pickQuantity?: number;
}

/* Formats the query params (filters and searchkey) to the correct eventListName for the gtm-trackers, when searching for a product. */
const getFormattedProductSearchEventListName = (query: string, relatedProducts?: boolean) => {
  const currentFilters = getCurrentAppliedFilters(query);
  const searchKeyString: EventListNameExtentionType = ` | ${getCurrentSearchKey(query)}`;
  const filterSeparatorLabel: EventListNameExtentionType = ' | Filters';
  const relatedSeparator: EventListNameExtentionType = ' | Relaterade produkter';
  const filterString = currentFilters.length > 0 ? `${filterSeparatorLabel}: ${currentFilters.join(',')}` : '';
  return relatedProducts
    ? `/sok${searchKeyString}${relatedSeparator}${filterString}`
    : `/sok${searchKeyString}${filterString}`;
};

const getFormattedProductsCategoryAppliedFilters = (routerQuery: ParsedUrlQuery): string => {
  if (Object.keys(routerQuery).length === 0) return '';
  const q = routerQuery.q;
  const sort = routerQuery.sort;
  if (q) {
    const replaceTerm = sort ? `:${sort}` : ':topRated';
    const filterQuery = q.toString().replace(replaceTerm, '');
    const currentFilters = getCurrentAppliedFilters(filterQuery);
    const filterSeparatorLabel: EventListNameExtentionType = ' | Filters';
    return currentFilters.length > 0 ? `${filterSeparatorLabel}: ${currentFilters.join(',')}` : '';
  }
  return '';
};

const getQuantity = (product: AxfoodCartProductViewModel | AxfoodOrderProductViewModel): number => {
  return product.pickQuantity > 0 ? product.pickQuantity : product.quantity > 0 ? product.quantity : 0;
};

const toShoppingListProduct = (
  product: AxfoodCartProductViewModel | AxfoodOrderProductViewModel
): ShoppingListProduct => {
  return {
    entryType: product.online ? 'PRODUCT' : 'PROMOTION',
    pickUnit: product.pickUnit?.code,
    productCode: product.code,
    quantity: getQuantity(product),
  };
};

/**
 * Converts a price string to a numerical version.
 *
 * @example '1 495,90 kr' => 1495.9
 * @example '5,00 kr' => 5
 * @param price
 */
const priceToNumerical = (price: string | undefined | null): number => {
  if (typeof price === 'string') {
    const modifiedPrice = price.replace(new RegExp(/,/gm), '.');
    return parseFloat(modifiedPrice.replace(new RegExp(/[a-z]|\s/gm), '')) || 0;
  }
  return 0;
};

const toShoppingListProducts = (
  products: Array<AxfoodCartProductViewModel | AxfoodOrderProductViewModel>
): ShoppingListProduct[] => {
  return products.map((product) => toShoppingListProduct(product));
};

/**
 * Returns the promotion with the highest numerical priority, or null if no promotions exist for the product.
 *
 * @param product
 */

type AxfoodPromotionViewModelWithPriority = AxfoodPromotionViewModel &
  Required<Pick<AxfoodPromotionViewModel, 'priority'>>;

const isAxfoodPromotionViewModelWithPriority = (
  promotion: AxfoodPromotionViewModel
): promotion is AxfoodPromotionViewModelWithPriority => !!promotion.priority;

const getPrimaryPromotion = (product: AxfoodCartProductViewModel): AxfoodPromotionViewModelWithPriority => {
  return [...product.potentialPromotions]
    .filter(isAxfoodPromotionViewModelWithPriority)
    .sort((a, b) => b.priority - a.priority)[0];
};

const formatRecipeProducts = (products: Array<AxfoodCartProductViewModel>) => {
  return products.map((product) => ({
    id: product.code,
    name: product.name,
    price: getImpressionsObjectPrice(product),
    quantity: product.pickQuantity,
    category: product.googleAnalyticsCategory || '',
    brand: product.manufacturer ? product.manufacturer.toLowerCase() : '',
  }));
};

/**
 * helper for displaying product unit as 'st' or 'kg'
 */

interface BasketProduct {
  productBasketType?: ProductBasketTypeEnum;
  pickUnit?: UnitData;
}

const pickUnitName = <Type extends BasketProduct>(product: Type) =>
  'pickUnit' in product && product.pickUnit?.code
    ? product.pickUnit.code
    : product.productBasketType?.code === 'ST'
      ? PICK_UNIT.PIECES
      : PICK_UNIT.KILOGRAM;

/**
 * Takes array of Product's and converts to CarouselProductType by adding positionInList property
 * Used to being able to report the position value properly in GA events fired from within a carousel slide
 */

export interface CarouselProductType extends Product {
  positionInList: number;
}
const toCarouselProducts = (items: Product[]): CarouselProductType[] =>
  items.length
    ? items.map((p, i) => ({
        ...p,
        positionInList: i + 1,
      }))
    : [];

/**
 * @summary helper for knowing when to pop the RemoveProductModal based on unit type - PICK_UNIT
 * @param unit
 * @param quantity
 * @param incrementValue
 * @param quantityIncludedInRecipe
 */
const shouldShowRemoveProductModal = (
  unit: string,
  quantity: number,
  incrementValue: number,
  quantityIncludedInRecipe?: number | '' | null
): boolean => {
  const lessThanQuantity = quantityIncludedInRecipe || 1;
  return unit === PICK_UNIT.KILOGRAM ? quantity === incrementValue : quantity - 1 < lessThanQuantity;
};

interface PromotionProduct {
  potentialPromotions: Array<AxfoodPromotionViewModel>;
  price?: string;
}

export const promotionComparePriceIsSame = <Type extends PromotionProduct>({
  price,
  potentialPromotions: [promotion],
}: Type) => promotion?.price?.formattedValue === price;

export const shouldShowOrdinaryPrice = <Type extends PromotionProduct>(product: Type) => {
  const {
    potentialPromotions: [promotion],
    price,
  } = product;
  const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
  return !!promotion && !!price && !hasSavePriceLabel && !promotionComparePriceIsSame(product);
};

export const getLowestHistoricalPrice = <Type extends PromotionProduct>(product: Type): string | undefined => {
  const {
    potentialPromotions: [promotion],
  } = product;

  const lowestHistoricalPrice = promotion?.lowestHistoricalPrice?.formattedValue?.trim();
  return lowestHistoricalPrice === '' ? undefined : lowestHistoricalPrice;
};

const isXforYProduct = (product: AxfoodCartProductViewModel): boolean => {
  const { appliedPromotions = [], potentialPromotions = [] } = product;
  const promotion = potentialPromotions[0];

  return (
    !!promotion &&
    !!appliedPromotions &&
    appliedPromotions.length > 0 &&
    !!promotion.qualifyingCount &&
    promotion.qualifyingCount > 1
  );
};

const getNumberOfCardsFitsInRef = (containerRef?: RefObject<HTMLDivElement>) => {
  const width = containerRef?.current?.offsetWidth;
  return width ? Math.floor((width + GRID_GAP) / MIN_PRODUCT_CARD_WITH_GAP) : 1;
};

export {
  formatRecipeProducts,
  getActualCustomerPrice,
  getFormattedProductsCategoryAppliedFilters,
  getFormattedProductSearchEventListName,
  getImpressionsObjectPrice,
  getNumberOfCardsFitsInRef,
  getPreviewUrl,
  getPrimaryPromotion,
  getProductUrl,
  getQuantity,
  isXforYProduct,
  pickUnitName,
  priceToNumerical,
  productToImpressionObject,
  productToImpressionObjectGa4,
  shouldShowRemoveProductModal,
  toCarouselProducts,
  toShoppingListProduct,
  toShoppingListProducts,
};
