import { getQueryWithoutFilters } from '@helpers/string';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';

const useSearchTerm = () => {
  const searchParams = useSearchParams();
  const q = searchParams?.get('q')
  const initialSearchTerm = getQueryWithoutFilters(q);
  return useState<string>(initialSearchTerm);
};

export default useSearchTerm;
