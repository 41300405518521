import { useEffect, useRef } from 'react';

export const useDialog = (onClose?: (arg: boolean) => void) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const closeDialog = () => {
    dialogRef.current?.close();
    onClose && onClose(true);
  };

  const openDialog = () => {
    dialogRef.current?.showModal();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeDialog();
    }
  };

  const scrollPosition = (position: number) => {
    setTimeout(() => {
      dialogRef &&
        dialogRef.current?.scrollTo({
          top: position,
          behavior: 'smooth',
        });
    }, 300);
  };

  useEffect(() => {
    const dialog = dialogRef.current;

    const handleClickOutside = (e: any) => {
      if (dialog && e.target === dialog) {
        closeDialog();
      }
    };

    if (dialog) {
      dialog.addEventListener('click', handleClickOutside);

      //Android fix manipulating the DOM Dialog
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.attributeName === 'open' && !dialog?.open) {
            closeDialog();
          }
        });
      });
      observer.observe(dialog, { attributes: true });

      return () => {
        observer.disconnect();
        dialog.removeEventListener('click', handleClickOutside);
      };
    }
  }, []);

  return { dialogRef, closeDialog, openDialog, scrollPosition, handleKeyDown };
};
