import { BreadcrumbDataOfSearchStateData, FacetDataOfSearchStateData, SortData } from '@api/generated/storefront';
import Icon from '@components/atoms/Icon';
import Panel, { PanelFooter, PanelHeader } from '@components/molecules/Panel';
import { ProductSortingDropdown } from '@components/molecules/ProductListFilterDropdowns';
import FACET_CODES from '@constants/productListFilters';
import { NO_QUERY } from '@features/recipes/RecipeSearchPage/constants';
import { clearFiltersFromQuery, countActiveFilters, getCurrentSearchKey } from '@helpers/productFilterHelpers';
import useAppRouter from '@hooks/useAppRouter';
import IconFilter from '@public/icons/regularIcons/icon-filter.svg';
import { assortmentTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import FilterList from '../../molecules/FilterList/FilterList';
import { ButtonRow, FilterButton, ProductListFiltersWrapper } from './ProductListFilters.styles';
import SelectedFilters from './SelectedFilters';
import { getBrands, getPromotionsAndLabels } from './helpers';

export interface ProductListFiltersProps {
  facets: FacetDataOfSearchStateData[];
  sorts: SortData[];
  pageName: 'category_page' | 'search_page';
  amountOfProducts: number;
  selectedFilters: BreadcrumbDataOfSearchStateData[];
}

const ProductListFilters = ({ facets, sorts, pageName, amountOfProducts, selectedFilters }: ProductListFiltersProps) => {
  const { push } = useAppRouter();
  const pathname = usePathname() ?? '';
  const searchParams = useSearchParams();
  const category = searchParams?.get('category');
  const categories = searchParams?.get('categories');
  const q = searchParams?.get('q');
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSearchKey, setCurrentSearchKey] = useState('');
  const [currentQuery, setCurrentQuery] = useState(q || '');
  const [currentCategory, setCurrentCategory] = useState(category || '');

  const { t } = useTranslation('productFiltering');

  const activeCount = countActiveFilters(facets);

  const clearFilterText = t('clearFilter');

  const handleClose = () => setIsExpanded(false);

  const clearFilters = () => {
    if (!searchParams) return;
    const queryWithoutFilters = clearFiltersFromQuery(searchParams);
    push({ pathname, query: queryWithoutFilters.toString() }, undefined, { scroll: false });
    setIsExpanded(false);
  };

  const onSearchTermChange = (newKey: string) => {
    setCurrentSearchKey(newKey);
    if (currentSearchKey !== newKey) {
      handleClose();
    }
  };

  const onCategoryChange = (newCategory: string) => {
    setCurrentCategory(newCategory);
    if (newCategory !== currentCategory) handleClose();
  };

  const trackNewQueryFilterLabel = (newQuery: string, facetCode: string) => {
    const newQueryFilterLabel = newQuery ? newQuery.split(`:${facetCode}:`) : '';
    if (newQueryFilterLabel.length > 1 && pageName === 'category_page') {
      assortmentTracker.categoryFilterEvent(newQueryFilterLabel[1], pageName);
    } else {
      assortmentTracker.searchFilterEvent(newQueryFilterLabel[1], facetCode);
    }
  };

  const onFilterChanged = (filterQuery: string) => {
    const firstQuery = filterQuery.indexOf(currentQuery as string);
    const currentQueryLength = currentQuery ? currentQuery.length : 0;

    const newQuery =
      firstQuery === 0
        ? filterQuery.substring(currentQueryLength)
        : filterQuery.substring(firstQuery + currentQueryLength);

    if (newQuery.includes(FACET_CODES.BRANDS)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.BRANDS);
    }
    if (newQuery.includes(FACET_CODES.LABELS)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.LABELS);
    }
    if (newQuery.includes(FACET_CODES.PROMOTION)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.PROMOTION);
    }
  };

  useEffect(() => {
    setCurrentQuery(q || '');
    const newKey = getCurrentSearchKey(q || '');

    const newCategory = categories?.[categories.length - 1];

    if (newCategory) {
      onCategoryChange(newCategory);
    }
    if (newKey) {
      onSearchTermChange(newKey);
    }
    if (q && typeof q === 'string' && currentQuery !== q) {
      onFilterChanged(q);
    }
  }, [categories]);

  const brands = getBrands(facets);
  const promotionsAndLabels = getPromotionsAndLabels(facets, t);
  const closePanel = () => setIsExpanded(false);
  const onFilterClick = (newQuery = NO_QUERY) => {
    if (!searchParams) return;
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('q', newQuery);
    push({ pathname, query: { q: newQuery } }, undefined, { scroll: false });
  };

  const headerSlot = (
    <PanelHeader title={t('toggleShowFilter')} closePanel={closePanel} closeText={t('common:defaultActions->close')} />
  );

  const footerSlot = (
    <PanelFooter
      primary={{ text: t('showProducts', { count: amountOfProducts }), onClick: closePanel }}
      secondary={{ text: clearFilterText, onClick: clearFilters, disabled: !activeCount }}
    />
  );

  return (
    <ProductListFiltersWrapper data-testid="product-list-filters-wrapper">
      <ButtonRow>
        <FilterButton variant="grey" size="sm" onClick={() => setIsExpanded(!isExpanded)}>
          <Icon icon={IconFilter} />
          {t('toggleShowFilter')}
          {activeCount > 0 && <span>({activeCount})</span>}
        </FilterButton>

        {!!sorts.length && <ProductSortingDropdown sorts={sorts} pageName={pageName} />}
      </ButtonRow>

      <SelectedFilters selectedFilters={selectedFilters} onFilterClick={onFilterClick} />

      <Panel headerSlot={headerSlot} footerSlot={footerSlot} isOpen={isExpanded} closePanel={closePanel}>
        <section data-testid="filter-panel-content">
          <FilterList title={t('labelsTitle')} onFilterClick={onFilterClick} facetList={promotionsAndLabels} />
          <FilterList title={t('brandsTitle')} onFilterClick={onFilterClick} facetList={brands} />
        </section>
      </Panel>
    </ProductListFiltersWrapper>
  );
};

export default ProductListFilters;
