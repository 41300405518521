import { EnergyDeclaration } from '@api/generated/storefront';
import EnergyDeclarationImage from '@components/atoms/EnergyDeclarationImage';
import Link from '@components/atoms/Link';
import Modal from '@components/molecules/__DEPRECATED__/Modal/index';
import getEnergyDeclarationArrowImageUrl from '@helpers/getEnergyDeclarationArrowImageUrl';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import {
  Container,
  EnergyDeclarationArrowButton,
  EnergyDeclarationArrowImage,
  EnergyDeclarationImageWrapper,
} from './ProductEnergyDeclarationLabel.styles';

// This component is a copy of the <EnergyDeclarationLabel>, with the difference that this component
// is intended to be used in the <Product> component, where as the other component is intended to be used
// in the <ProductDetails> component. The reason we use two instances is because of deviating
// requirements / demands of the component.

export interface ProductEnergyDeclarationLabelProps {
  energyDeclaration: EnergyDeclaration;
  isOnlineProduct?: boolean;
}

const ProductEnergyDeclarationLabel = ({
  energyDeclaration,
  isOnlineProduct = true,
}: ProductEnergyDeclarationLabelProps) => {
  const { t } = useTranslation('energydeclaration');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const imageUrl = getEnergyDeclarationArrowImageUrl(energyDeclaration.value);

  if (!imageUrl) {
    return null;
  }

  const handleOnClick = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Container data-testid="energy-declaration-label">
        {isOnlineProduct && <EnergyDeclarationArrowImage src={imageUrl} alt={t('title')} />}
        {!isOnlineProduct && (
          <EnergyDeclarationArrowButton onClick={handleOnClick}>
            <EnergyDeclarationArrowImage src={imageUrl} alt={t('title')} />
          </EnergyDeclarationArrowButton>
        )}
        {energyDeclaration.link && (
          <Link size="xsmall" href={energyDeclaration.link} target="_blank">
            {t('link')}
          </Link>
        )}
      </Container>
      {isModalOpen && energyDeclaration && energyDeclaration.image && (
        <Modal isOpen closeHandler={handleModalClose} size="small" position="center">
          <EnergyDeclarationImageWrapper>
            <EnergyDeclarationImage src={energyDeclaration.image} alt={t('title')} />
          </EnergyDeclarationImageWrapper>
        </Modal>
      )}
    </>
  );
};

export default ProductEnergyDeclarationLabel;
