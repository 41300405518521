import type { AddToCartForm } from '@occ/api-client';
import { AxfoodApi } from '@occ/api-client';
import mapCart from './mapCart';

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getCart = async () => {
  const response = await axfoodApi.getCartUsingGet();
  return mapCart(response.data);
};

export const addToCart = async (products: Array<AddToCartForm>, storeId?: string) => {
  const response = await axfoodApi.addToCartUsingPost({ products, storeId });
  return mapCart(response.data);
};
