import Table from '@components/atoms/Table';
import { ProductDetailsData } from '@components/organisms/ProductDetails/useGetProductDetails/mapping';
import useFeature from '@hooks/useFeature';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';
import { AlignRight, StyledParagraph, StyledTable } from './DailyRecommendedIntake.styles';

interface ProductInformationListingProps {
  product: ProductDetailsData;
}

const DailyRecommendedIntake = ({ product }: ProductInformationListingProps) => {
  const { t } = useTranslation('productDetails');
  const {
    dailyRecommendedIntakeData,
    formattedNutritionsFactList: nutritions,
    animalData,
    nutritionDescription,
  } = product;

  const featureEnabled = useFeature('FEATURE_DAILY_RECOMMENDED_INTAKE_FL');

  if (!featureEnabled) {
    return (
      <>
        {nutritions.length > 0 && !animalData && (
          <Table
            fullWidth
            rows={nutritions}
            columns={[
              {
                id: 'nutritionDescription',
                label: `${t('productDetails->nutritionDescription')} ${nutritionDescription}`,
                colSpan: 2,
              },
            ]}
          />
        )}
      </>
    );
  }
  const showNutritionsTable = nutritions.length > 0 && !dailyRecommendedIntakeData.length && !animalData;
  const showDRItable = !!dailyRecommendedIntakeData.length && !animalData;

  return (
    <>
      {showNutritionsTable && (
        <Table
          fullWidth
          rows={nutritions}
          columns={[
            {
              id: 'nutritionDescription',
              label: `${t('productDetails->nutritionDescription')} ${product.nutritionDescription}`,
              colSpan: 2,
            },
          ]}
        />
      )}
      {showDRItable && (
        <>
          {dailyRecommendedIntakeData.map(({ nutritionDescription, nutrientHeaderRow, rowData }) => {
            return (
              <Fragment key={nutritionDescription}>
                <StyledParagraph bold="bold">
                  {t('productDetails->nutritionDescription')} {nutritionDescription}
                </StyledParagraph>
                <StyledTable key={nutritionDescription} columns={nutrientHeaderRow} rows={rowData} fullWidth />
              </Fragment>
            );
          })}
          <AlignRight>
            <StyledParagraph>{t('productDetails->driDisclaimer')}</StyledParagraph>
          </AlignRight>
        </>
      )}
    </>
  );
};

export default DailyRecommendedIntake;
