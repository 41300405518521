import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import useAppRouter from '@hooks/useAppRouter';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';
import MixAndMatchBeam from './MixAndMatchBeam/MixAndMatchBeam';
import { BeamsWrapper, Container } from './ProductDetailsRelatedBeams.styles';
import RelatedProductsBeam from './RelatedProductsBeam/RelatedProductsBeam';
import { OTHER_BOUGHT, OTHER_VARIANTS, SIMILAR_PRODUCTS } from './constants';

export interface ProductDetailsRelatedBeamsProps {
  product: AxfoodProductDetailsViewModel;
}

export const EVENT_LIST_NAME = '/produkt | ';

const ProductDetailsRelatedBeams = ({ product }: ProductDetailsRelatedBeamsProps) => {
  const relatedRef = useRef<HTMLDivElement | null>(null);
  const { query } = useAppRouter();
  const scrollToMixAndMatch = query.scrollToMixAndMatch;

  const { t } = useTranslation('productDetails');

  useEffect(() => {
    const scrollTimeOut =
      scrollToMixAndMatch === 'true' && relatedRef.current
        ? setTimeout(() => {
            relatedRef.current?.scrollIntoView();
          }, 450)
        : null;

    return () => {
      if (scrollTimeOut) {
        clearTimeout(scrollTimeOut);
      }
    };
  }, [scrollToMixAndMatch]);

  const promotionCode = product.potentialPromotions[0]?.code || '';
  return (
    <Container>
      <BeamsWrapper data-testid="product-area" ref={relatedRef}>
        <MixAndMatchBeam
          eventListName={`${EVENT_LIST_NAME} ${t('productDetails->title->samePromotion')}`}
          promotionCode={promotionCode}
          excludedProducts={[product.code]}
        />
        <RelatedProductsBeam
          eventListName={`${EVENT_LIST_NAME} ${t('productDetails->title->othersBought')}`}
          productCode={product.code}
          displayType={OTHER_BOUGHT}
          title={t('productDetails->title->othersBought')}
        />
        <RelatedProductsBeam
          eventListName={`${EVENT_LIST_NAME} ${t('productDetails->title->similarProducts')}`}
          productCode={product.code}
          displayType={SIMILAR_PRODUCTS}
          title={t('productDetails->title->similarProducts')}
        />
        <RelatedProductsBeam
          eventListName={`${EVENT_LIST_NAME} ${t('productDetails->title->otherVariants')}`}
          productCode={product.code}
          displayType={OTHER_VARIANTS}
          title={t('productDetails->title->otherVariants')}
        />
      </BeamsWrapper>
    </Container>
  );
};

export default ProductDetailsRelatedBeams;
