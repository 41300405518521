import Product from '@components/molecules/Product/Product';
import { Product as ProductType } from '@components/molecules/Product/ProductDataMapper/types';
import ProductMessageModal from '@components/molecules/ProductMessageModal';
import ProductQuantity from '@components/molecules/ProductQuantity';
import MixAndMatchProductsContext from '@context/MixAndMatchProductsContext';
import makeIntersectionObserverComponent, { IntersectionObserverComponentProps } from '@helpers/componentTracking';
import useProductQuantityWithCart from '@hooks/useProductQuantityWithCart';
import { memo, useContext } from 'react';
import { ProductContainer } from './CartConnectedProduct.styles';

// This is the CartConnectedProduct component that can be used where appropriate.
// This component has a connection with the redux cart state, therefore changes
// to the product quantity will also be reflected there.

// If you need a component without any connections, take a look at the
// GeneralProduct component.

interface CartConnectedProductProps extends IntersectionObserverComponentProps {
  product: ProductType;
  eventListName: string;
  disableMixAndMatch?: boolean;
  onMixAndMatchClickHandler?: () => void;
  disableQuantity?: boolean;
  layout?: 'vertical' | 'horizontal';
}

const CartConnectedProduct = ({
  product,
  eventListName,
  disableMixAndMatch = false,
  disableQuantity = false,
  layout = 'vertical',
  intersectionRef,
}: CartConnectedProductProps) => {
  const {
    changeQuantityBy,
    onValueChange,
    onFocus,
    onBlur,
    quantity,
    quantityFieldIsActive,
    modalState,
    setModalState,
  } = useProductQuantityWithCart({ eventListName, product });
  const { isMixAndMatchProduct = false } = useContext(MixAndMatchProductsContext) || {};
  const shouldDisableLinks = product?.offline;

  const incrementValue = product?.incrementValue || 0;

  return (
    <>
      <ProductContainer data-testid="product-container" ref={intersectionRef}>
        <Product
          product={product}
          disableLinks={shouldDisableLinks}
          eventListName={eventListName}
          layout={layout}
          border={isMixAndMatchProduct}
          disableMixAndMatch={disableMixAndMatch}
        >
          <ProductQuantity
            value={quantity}
            decrement={() => changeQuantityBy(-incrementValue)}
            increment={() => changeQuantityBy(incrementValue)}
            isActive={quantityFieldIsActive}
            onChange={onValueChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disableQuantity}
            offline={product?.offline}
            incrementValue={incrementValue}
            unit={product?.price?.pickUnitName || ''}
            outOfStock={product?.outOfStock || false}
          />
        </Product>
      </ProductContainer>

      {modalState !== 'NONE' && <ProductMessageModal status={modalState} setStatus={setModalState} />}
    </>
  );
};
CartConnectedProduct.displayName = 'CartConnectedProduct';

const areEqual = (
  prevProps: Omit<CartConnectedProductProps, 'ref'>,
  nextProps: Omit<CartConnectedProductProps, 'ref'>
) => {
  if (prevProps.product?.code !== nextProps.product?.code) return false;
  if (prevProps.product?.price?.actualCustomerPrice !== nextProps.product?.price?.actualCustomerPrice) return false;
  if (prevProps.product?.outOfStock !== nextProps.product?.outOfStock) return false;
  if (
    'quantity' in prevProps.product &&
    'quantity' in nextProps.product &&
    prevProps.product.quantity !== nextProps.product.quantity
  )
    return false;
  if (prevProps.eventListName !== nextProps.eventListName) return false;
  if (prevProps.disableMixAndMatch !== nextProps.disableMixAndMatch) return false;
  if (prevProps.onMixAndMatchClickHandler !== nextProps.onMixAndMatchClickHandler) return false;
  if (prevProps.disableQuantity !== nextProps.disableQuantity) return false;
  if (prevProps.layout !== nextProps.layout) return false;
  return true;
};

// TODO: https://axfood.atlassian.net/browse/B2C-31565
const CartConnectedProductWithMemo = memo(CartConnectedProduct, areEqual);

export default CartConnectedProductWithMemo;

export const IntersectionObservedCartConnectedProduct = makeIntersectionObserverComponent(CartConnectedProductWithMemo);
