import { findComponentsByIds } from '@api';
import useCart from '@features/cart/useCart';
import useAssortmentKey from '@hooks/useAssortmentKey';
import useCustomer from '@hooks/useCustomer';
import useSWRImmutable from 'swr/immutable';

const fetcher = async ({ componentIds, storeId }: { componentIds: string; storeId?: string }) => {
  return findComponentsByIds({ componentIds: JSON.parse(componentIds), storeId });
};

const useCmsComponents = (componentIds: string, storeId?: string) => {
  const { customerId } = useCustomer();
  const { assortmentStoreId, slotId } = useAssortmentKey() || {};
  const { cart: { deliveryModeCode = '' } = {} } = useCart();

  const shouldFetch = Boolean(componentIds);
  // useSWRImmutable flags the resource as immutable. Only key changes will trigger re-fetch
  const { data, isLoading, error, isValidating } = useSWRImmutable(
    shouldFetch
      ? {
          endpoint: 'cms/components',
          storeId,
          componentIds,
          customerId,
          deliveryModeCode,
          assortmentStoreId,
          slotId,
        }
      : null,
    fetcher,
    { keepPreviousData: true }
  );
  return {
    data: data?.component,
    isLoading,
    error,
    isValidating,
  };
};

export default useCmsComponents;
