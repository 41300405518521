import media from '@constants/responsive';
import { rem } from 'polished';
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { ParagraphBoldType, ParagraphSizeType } from './types';

export interface IParagraphStyleProps {
  $textColor: '' | Theme;
  $size?: ParagraphSizeType;
  $bold?: ParagraphBoldType;
  $italics?: boolean;
  $underlined?: boolean;
  $truncateText?: boolean;
  children: React.ReactNode;
  className?: string;
}

const getSize = (size: ParagraphSizeType) => {
  switch (size) {
    case 'xs':
      return `
        font-size: ${rem(9)};
        ${media.tabletPortrait} {
          font-size: ${rem(10)};
        }
      `;
    case 'sm':
      return `
        font-size: ${rem(12)};
        ${media.tabletPortrait} {
          font-size: ${rem(12)};
        }
      `;
    case 'md':
      return `
        font-size: ${rem(14)};
        ${media.tabletPortrait} {
          font-size: ${rem(14)};
        }
      `;
    case 'lg':
      return `
        font-size: ${rem(14)};
        ${media.tabletPortrait} {
          font-size: ${rem(16)};
        }
      `;
    case 'xl':
      return `
        font-size: ${rem(18)};
        ${media.tabletPortrait} {
          font-size: ${rem(20)};
        }
      `;
    case 'xxl':
      return `
        font-size: ${rem(24)};
        ${media.tabletPortrait} {
          font-size: ${rem(24)};
        }
      `;
    case 'label-header':
      return `
        font-size: ${rem(14)};
        ${media.tabletPortrait} {
          font-size: ${rem(14)};
        }
      `;
    case 'body-text':
      return `font-size: ${rem(16)};`;
    case 'content-text':
      return `
        font-size: ${rem(16)};
        ${media.tabletPortrait} {
          font-size: ${rem(18)};
          line-height: ${rem(36)};
        }
      `;
    default:
      return '';
  }
};

const getBoldStyle = (bold: ParagraphBoldType) => {
  switch (bold) {
    case 'bold':
      return css`
        font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
      `;
    case 'medium':
      return css`
        font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
      `;
    case 'xboldItalic':
      return css`
        font-family: ${({ theme }) => theme.typography.fontSansItalicBold};
      `;
    default:
      return '';
  }
};

const getColorStyle = (textColor: Theme, theme: DefaultTheme) => {
  switch (textColor) {
    case 'primary':
      return css`
        color: ${theme.colors.colorPrimary};
      `;
    case 'secondary':
      return css`
        color: ${theme.colors.colorTextSecondary};
      `;
    case 'orange':
      return css`
        color: ${theme.colors.colorEcstasy};
      `;
    case 'white':
      return css`
        color: ${theme.colors.colorWhite};
      `;
    case 'black':
      return css`
        color: ${theme.colors.colorTextPrimary};
      `;
    case 'stack':
      return css`
        color: ${theme.colors.colorStack};
      `;
    default:
      return '';
  }
};

export const ParagraphStyled = styled.p<IParagraphStyleProps>`
  font-size: ${rem(14)};
  line-height: 1.5;
  margin: 0;
  ${media.tabletPortrait} {
    font-size: ${rem(16)};
  }
  ${({ $size }) => $size && getSize($size)};
  ${({ $italics }) =>
    $italics &&
    css`
      font-style: italic;
    `};
  ${({ $underlined }) =>
    $underlined &&
    css`
      text-decoration: underline;
    `};
  ${({ $bold }) => $bold && getBoldStyle($bold)};
  ${({ $textColor, theme }) => $textColor && getColorStyle($textColor, theme)};
  ${({ $truncateText }) =>
    $truncateText &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;
