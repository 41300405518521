import { AxfoodStoreInfoViewModel } from '@api/generated/storefront';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChangeStoreButton, ChangeStoreButtonWrapper, StoreSearchPopover } from './ChangeStore.styles';
import StoreSearch from './StoreSearch';

interface ChangeStoreProps {
  stores?: AxfoodStoreInfoViewModel[];
  currentStore: AxfoodStoreInfoViewModel | undefined;
  isLoading: boolean;
  handleChangeStore: (store: AxfoodStoreInfoViewModel) => void;
  className?: string;
}

const ChangeStore = ({ stores, currentStore, isLoading, handleChangeStore, className }: ChangeStoreProps) => {
  const { t } = useTranslation('axfoodWeeklyFlyerComponent');
  const [storeSearchVisible, setStoreSearchVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const clickOutsideRef = useRef(null);
  useOnClickOutside(clickOutsideRef, () => {
    setStoreSearchVisible(false);
  });
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const toggleStoreSearch = () => {
    setStoreSearchVisible((s) => !s);
  };

  useOnClickOutside(clickOutsideRef, () => {
    setStoreSearchVisible(false);
  });

  const handleChangeStoreAndClosePopover = (store: AxfoodStoreInfoViewModel) => {
    handleChangeStore(store);
    toggleStoreSearch();
  };
  return (
    <div ref={clickOutsideRef} className={className}>
      <ChangeStoreButtonWrapper ref={setReferenceElement as any}>
        <ChangeStoreButton variant="secondaryPlain" size="md" onClick={toggleStoreSearch} disabled={isLoading}>
          {t('buttons->changeStore')}
        </ChangeStoreButton>
        {storeSearchVisible && !!stores?.length && (
          <StoreSearchPopover ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
            <StoreSearch
              stores={stores}
              setStore={handleChangeStoreAndClosePopover}
              selectedStore={currentStore}
              onClose={toggleStoreSearch}
            />
          </StoreSearchPopover>
        )}
      </ChangeStoreButtonWrapper>
    </div>
  );
};

export default ChangeStore;
