import AccordionListItem from '@components/molecules/__DEPRECATED__/AccordionListItem';
import TakeoverMenu from '@components/molecules/TakeoverMenu';
import { navigationClickTracker } from '@trackers';
import { MenuLinkData } from '../hooks/useTopBarData';
import { usePathname } from 'next/navigation';

interface Props {
  toggleTakeoverMenu: () => void;
  menuLinks: MenuLinkData[];
}

const TakeoverMenuLinks = ({ toggleTakeoverMenu, menuLinks }: Props) => {
  const pathname = usePathname();

  const handleLinkClicked = (menuType: string, title: string) => {
    navigationClickTracker.linkClickTracker(menuType, title);
    toggleTakeoverMenu();
  };

  return (
    <TakeoverMenu onClose={toggleTakeoverMenu}>
      <menu>
        {menuLinks.map(({ title, id, url, iconUrl }) => (
          <AccordionListItem
            key={id}
            title={title}
            isActive={Boolean(url) && url === pathname}
            href={url}
            iconImageUrl={iconUrl}
            onClick={() => title && handleLinkClicked('hamburger', title)}
            wrapperElement="li"
          />
        ))}
      </menu>
    </TakeoverMenu>
  );
};

export default TakeoverMenuLinks;
