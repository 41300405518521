import { sendMessageToApp } from './sendMessageToApp';

/**
 * HK React Native app no longer listens to these events, with the exception of eventWebReady.
 */

const webToApp = {
  eventWebReady: () => {
    sendMessageToApp({ event: 'frontendready' });
  },
};

export default webToApp;
