/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodAuthSignResponse } from '../dto';
// @ts-ignore
import { AxfoodCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodCollectResponse } from '../dto';
// @ts-ignore
import { BankIdForm } from '../dto';
// @ts-ignore
import { CancelResponse } from '../dto';
// @ts-ignore
import { CreateSessionPaymentResponse } from '../dto';
// @ts-ignore
import { KlarnaAuthenticationCallbackResponse } from '../dto';
// @ts-ignore
import { SetPaymentModeJson } from '../dto';
/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel bankId sign
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankCancelUsingPOST: async (orderRef: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            assertParamExists('bankCancelUsingPOST', 'orderRef', orderRef)
            const localVarPath = `/axfood/rest/checkout/bankid/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdAuthUsingPOST: async (form: BankIdForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('bankIdAuthUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/checkout/bankid/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Collect with bankId login
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollectLoginUsingPOST: async (orderRef: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            assertParamExists('bankIdCollectLoginUsingPOST', 'orderRef', orderRef)
            const localVarPath = `/axfood/rest/checkout/bankid/collect-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Collect with bankId
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollectUsingPOST: async (orderRef: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            assertParamExists('bankIdCollectUsingPOST', 'orderRef', orderRef)
            const localVarPath = `/axfood/rest/checkout/bankid/collect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh qr data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdQrDataUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/checkout/bankid/qr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdSignUsingPOST: async (form: BankIdForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('bankIdSignUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/checkout/bankid/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel klarna order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelOrderUsingPOST1', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/klarna/cancel-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderCode !== undefined) {
                localVarQueryParameter['orderCode'] = orderCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingDELETE1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingDELETE1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingGET1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingGET1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingHEAD1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingHEAD1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingOPTIONS1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingOPTIONS1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPATCH1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingPATCH1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPOST1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingPOST1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPUT1: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelPaymentUsingPUT1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/cancelPayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clean klarna session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanKlarnaSessionUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/klarna/clean-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingDELETE: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingDELETE', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingGET: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingGET', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingHEAD: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingHEAD', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingOPTIONS: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingOPTIONS', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPATCH: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingPATCH', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPOST: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingPOST', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPUT: async (orderCode: string, orderRef?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentUsingPUT', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePayment/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingDELETE1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingDELETE1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingGET1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingGET1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingHEAD1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingHEAD1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingOPTIONS1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingOPTIONS1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPATCH1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingPATCH1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPOST1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingPOST1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPUT1: async (orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('completePaymentWithPickingConditionsUsingPUT1', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/completePaymentMocked/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderRef !== undefined) {
                localVarQueryParameter['orderRef'] = orderRef;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickingInstructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create klarna payment session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/klarna/payment-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Display checkout page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayCheckoutPageUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/singlestepcheckout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment modes for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidPaymentModesForUserUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/checkout/paymentmodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize checkout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeCheckoutUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order confirmation
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderConfirmationUsingGET: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('orderConfirmationUsingGET', 'orderCode', orderCode)
            const localVarPath = `/singlestepcheckout/orderconfirmation/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set klarna authentication token
         * @param {KlarnaAuthenticationCallbackResponse} klarnaCallbackBody klarnaCallbackBody
         * @param {string} [secretToken] secretToken
         * @param {string} [jaloSessionId] jaloSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setKlarnaAuthenticationTokenUsingPOST: async (klarnaCallbackBody: KlarnaAuthenticationCallbackResponse, secretToken?: string, jaloSessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klarnaCallbackBody' is not null or undefined
            assertParamExists('setKlarnaAuthenticationTokenUsingPOST', 'klarnaCallbackBody', klarnaCallbackBody)
            const localVarPath = `/axfood/rest/klarna/authCallbackEndpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (secretToken !== undefined) {
                localVarQueryParameter['secretToken'] = secretToken;
            }

            if (jaloSessionId !== undefined) {
                localVarQueryParameter['jaloSessionId'] = jaloSessionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(klarnaCallbackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set klarna payment method
         * @param {string} paymentmethod paymentmethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentMethodUsingPOST: async (paymentmethod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentmethod' is not null or undefined
            assertParamExists('setPaymentMethodUsingPOST', 'paymentmethod', paymentmethod)
            const localVarPath = `/axfood/rest/klarna/set-klarna-paymentmethod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (paymentmethod !== undefined) {
                localVarQueryParameter['paymentmethod'] = paymentmethod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set payment mode for user
         * @param {SetPaymentModeJson} paymentModeJson paymentModeJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentModeUsingPOST: async (paymentModeJson: SetPaymentModeJson, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentModeJson' is not null or undefined
            assertParamExists('setPaymentModeUsingPOST', 'paymentModeJson', paymentModeJson)
            const localVarPath = `/axfood/rest/checkout/paymentmode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentModeJson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Place order
         * @param {boolean} [saveCard] 
         * @param {string} [selectedCard] 
         * @param {string} [orderRef] 
         * @param {string} [klarnaAuthorizationToken] 
         * @param {boolean} [userClickedContinue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simplifiedPlaceOrderUsingPOST: async (saveCard?: boolean, selectedCard?: string, orderRef?: string, klarnaAuthorizationToken?: string, userClickedContinue?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/singlestepcheckout/placeOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


            if (saveCard !== undefined) { 
                localVarFormParams.set('saveCard', saveCard as any);
            }
    
            if (selectedCard !== undefined) { 
                localVarFormParams.set('selectedCard', selectedCard as any);
            }
    
            if (orderRef !== undefined) { 
                localVarFormParams.set('orderRef', orderRef as any);
            }
    
            if (klarnaAuthorizationToken !== undefined) { 
                localVarFormParams.set('klarnaAuthorizationToken', klarnaAuthorizationToken as any);
            }
    
            if (userClickedContinue !== undefined) { 
                localVarFormParams.set('userClickedContinue', userClickedContinue as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel bankId sign
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankCancelUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankCancelUsingPOST(orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdAuthUsingPOST(form: BankIdForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodAuthSignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdAuthUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Collect with bankId login
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdCollectLoginUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCollectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdCollectLoginUsingPOST(orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Collect with bankId
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdCollectUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCollectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdCollectUsingPOST(orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh qr data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdQrDataUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdQrDataUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdSignUsingPOST(form: BankIdForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodAuthSignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIdSignUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel klarna order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrderUsingPOST1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderUsingPOST1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingDELETE1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingDELETE1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingGET1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingGET1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingHEAD1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingHEAD1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingOPTIONS1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingOPTIONS1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingPATCH1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingPATCH1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingPOST1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingPOST1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPaymentUsingPUT1(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPaymentUsingPUT1(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clean klarna session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanKlarnaSessionUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanKlarnaSessionUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingDELETE(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingDELETE(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingGET(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingGET(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingHEAD(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingHEAD(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingOPTIONS(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingOPTIONS(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingPATCH(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingPATCH(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingPOST(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingPOST(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentUsingPUT(orderCode: string, orderRef?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentUsingPUT(orderCode, orderRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingDELETE1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingDELETE1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingGET1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingGET1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingHEAD1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingHEAD1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingOPTIONS1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingOPTIONS1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingPATCH1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingPATCH1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingPOST1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingPOST1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePaymentWithPickingConditionsUsingPUT1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePaymentWithPickingConditionsUsingPUT1(orderCode, orderRef, pickingInstructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create klarna payment session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSessionUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSessionPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSessionUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Display checkout page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayCheckoutPageUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayCheckoutPageUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment modes for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValidPaymentModesForUserUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValidPaymentModesForUserUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initialize checkout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeCheckoutUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeCheckoutUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order confirmation
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderConfirmationUsingGET(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderConfirmationUsingGET(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set klarna authentication token
         * @param {KlarnaAuthenticationCallbackResponse} klarnaCallbackBody klarnaCallbackBody
         * @param {string} [secretToken] secretToken
         * @param {string} [jaloSessionId] jaloSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody: KlarnaAuthenticationCallbackResponse, secretToken?: string, jaloSessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody, secretToken, jaloSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set klarna payment method
         * @param {string} paymentmethod paymentmethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPaymentMethodUsingPOST(paymentmethod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPaymentMethodUsingPOST(paymentmethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set payment mode for user
         * @param {SetPaymentModeJson} paymentModeJson paymentModeJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPaymentModeUsingPOST(paymentModeJson: SetPaymentModeJson, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPaymentModeUsingPOST(paymentModeJson, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Place order
         * @param {boolean} [saveCard] 
         * @param {string} [selectedCard] 
         * @param {string} [orderRef] 
         * @param {string} [klarnaAuthorizationToken] 
         * @param {boolean} [userClickedContinue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simplifiedPlaceOrderUsingPOST(saveCard?: boolean, selectedCard?: string, orderRef?: string, klarnaAuthorizationToken?: string, userClickedContinue?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simplifiedPlaceOrderUsingPOST(saveCard, selectedCard, orderRef, klarnaAuthorizationToken, userClickedContinue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel bankId sign
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankCancelUsingPOST(orderRef: { [key: string]: string; }, options?: any): AxiosPromise<CancelResponse> {
            return localVarFp.bankCancelUsingPOST(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdAuthUsingPOST(form: BankIdForm, options?: any): AxiosPromise<AxfoodAuthSignResponse> {
            return localVarFp.bankIdAuthUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Collect with bankId login
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollectLoginUsingPOST(orderRef: { [key: string]: string; }, options?: any): AxiosPromise<AxfoodCollectResponse> {
            return localVarFp.bankIdCollectLoginUsingPOST(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Collect with bankId
         * @param {{ [key: string]: string; }} orderRef orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollectUsingPOST(orderRef: { [key: string]: string; }, options?: any): AxiosPromise<AxfoodCollectResponse> {
            return localVarFp.bankIdCollectUsingPOST(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh qr data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdQrDataUsingPOST(options?: any): AxiosPromise<string> {
            return localVarFp.bankIdQrDataUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign with bankId
         * @param {BankIdForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdSignUsingPOST(form: BankIdForm, options?: any): AxiosPromise<AxfoodAuthSignResponse> {
            return localVarFp.bankIdSignUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel klarna order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST1(orderCode: string, options?: any): AxiosPromise<object> {
            return localVarFp.cancelOrderUsingPOST1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingDELETE1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingDELETE1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingGET1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingGET1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingHEAD1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingHEAD1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingOPTIONS1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingOPTIONS1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPATCH1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingPATCH1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPOST1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingPOST1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel payment
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPaymentUsingPUT1(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.cancelPaymentUsingPUT1(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clean klarna session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanKlarnaSessionUsingGET(options?: any): AxiosPromise<void> {
            return localVarFp.cleanKlarnaSessionUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingDELETE(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingDELETE(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingGET(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingGET(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingHEAD(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingHEAD(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingOPTIONS(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingOPTIONS(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPATCH(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingPATCH(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPOST(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingPOST(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentUsingPUT(orderCode: string, orderRef?: string, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentUsingPUT(orderCode, orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingDELETE1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingDELETE1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingGET1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingGET1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingHEAD1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingHEAD1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingOPTIONS1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingOPTIONS1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPATCH1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingPATCH1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPOST1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingPOST1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete payment for order
         * @param {string} orderCode orderCode
         * @param {string} [orderRef] orderRef
         * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePaymentWithPickingConditionsUsingPUT1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: any): AxiosPromise<string> {
            return localVarFp.completePaymentWithPickingConditionsUsingPUT1(orderCode, orderRef, pickingInstructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create klarna payment session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionUsingGET(options?: any): AxiosPromise<CreateSessionPaymentResponse> {
            return localVarFp.createSessionUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Display checkout page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayCheckoutPageUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.displayCheckoutPageUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment modes for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidPaymentModesForUserUsingGET(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getValidPaymentModesForUserUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize checkout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeCheckoutUsingGET(options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.initializeCheckoutUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order confirmation
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderConfirmationUsingGET(orderCode: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderConfirmationUsingGET(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set klarna authentication token
         * @param {KlarnaAuthenticationCallbackResponse} klarnaCallbackBody klarnaCallbackBody
         * @param {string} [secretToken] secretToken
         * @param {string} [jaloSessionId] jaloSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody: KlarnaAuthenticationCallbackResponse, secretToken?: string, jaloSessionId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody, secretToken, jaloSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set klarna payment method
         * @param {string} paymentmethod paymentmethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentMethodUsingPOST(paymentmethod: string, options?: any): AxiosPromise<void> {
            return localVarFp.setPaymentMethodUsingPOST(paymentmethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set payment mode for user
         * @param {SetPaymentModeJson} paymentModeJson paymentModeJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentModeUsingPOST(paymentModeJson: SetPaymentModeJson, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setPaymentModeUsingPOST(paymentModeJson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Place order
         * @param {boolean} [saveCard] 
         * @param {string} [selectedCard] 
         * @param {string} [orderRef] 
         * @param {string} [klarnaAuthorizationToken] 
         * @param {boolean} [userClickedContinue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simplifiedPlaceOrderUsingPOST(saveCard?: boolean, selectedCard?: string, orderRef?: string, klarnaAuthorizationToken?: string, userClickedContinue?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.simplifiedPlaceOrderUsingPOST(saveCard, selectedCard, orderRef, klarnaAuthorizationToken, userClickedContinue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * 
     * @summary Cancel bankId sign
     * @param {{ [key: string]: string; }} orderRef orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankCancelUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankCancelUsingPOST(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize with bankId
     * @param {BankIdForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankIdAuthUsingPOST(form: BankIdForm, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankIdAuthUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Collect with bankId login
     * @param {{ [key: string]: string; }} orderRef orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankIdCollectLoginUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankIdCollectLoginUsingPOST(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Collect with bankId
     * @param {{ [key: string]: string; }} orderRef orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankIdCollectUsingPOST(orderRef: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankIdCollectUsingPOST(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh qr data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankIdQrDataUsingPOST(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankIdQrDataUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign with bankId
     * @param {BankIdForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public bankIdSignUsingPOST(form: BankIdForm, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).bankIdSignUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel klarna order
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelOrderUsingPOST1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelOrderUsingPOST1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingDELETE1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingDELETE1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingGET1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingGET1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingHEAD1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingHEAD1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingOPTIONS1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingOPTIONS1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingPATCH1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingPATCH1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingPOST1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingPOST1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel payment
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cancelPaymentUsingPUT1(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cancelPaymentUsingPUT1(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clean klarna session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cleanKlarnaSessionUsingGET(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cleanKlarnaSessionUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingDELETE(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingDELETE(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingGET(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingGET(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingHEAD(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingHEAD(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingOPTIONS(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingOPTIONS(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingPATCH(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingPATCH(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingPOST(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingPOST(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentUsingPUT(orderCode: string, orderRef?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentUsingPUT(orderCode, orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingDELETE1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingDELETE1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingGET1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingGET1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingHEAD1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingHEAD1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingOPTIONS1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingOPTIONS1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingPATCH1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingPATCH1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingPOST1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingPOST1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete payment for order
     * @param {string} orderCode orderCode
     * @param {string} [orderRef] orderRef
     * @param {{ [key: string]: Array<string>; }} [pickingInstructions] pickingInstructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public completePaymentWithPickingConditionsUsingPUT1(orderCode: string, orderRef?: string, pickingInstructions?: { [key: string]: Array<string>; }, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).completePaymentWithPickingConditionsUsingPUT1(orderCode, orderRef, pickingInstructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create klarna payment session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createSessionUsingGET(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createSessionUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Display checkout page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public displayCheckoutPageUsingGET(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).displayCheckoutPageUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment modes for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public getValidPaymentModesForUserUsingGET(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).getValidPaymentModesForUserUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize checkout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public initializeCheckoutUsingGET(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).initializeCheckoutUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order confirmation
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public orderConfirmationUsingGET(orderCode: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).orderConfirmationUsingGET(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set klarna authentication token
     * @param {KlarnaAuthenticationCallbackResponse} klarnaCallbackBody klarnaCallbackBody
     * @param {string} [secretToken] secretToken
     * @param {string} [jaloSessionId] jaloSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody: KlarnaAuthenticationCallbackResponse, secretToken?: string, jaloSessionId?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).setKlarnaAuthenticationTokenUsingPOST(klarnaCallbackBody, secretToken, jaloSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set klarna payment method
     * @param {string} paymentmethod paymentmethod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public setPaymentMethodUsingPOST(paymentmethod: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).setPaymentMethodUsingPOST(paymentmethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set payment mode for user
     * @param {SetPaymentModeJson} paymentModeJson paymentModeJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public setPaymentModeUsingPOST(paymentModeJson: SetPaymentModeJson, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).setPaymentModeUsingPOST(paymentModeJson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Place order
     * @param {boolean} [saveCard] 
     * @param {string} [selectedCard] 
     * @param {string} [orderRef] 
     * @param {string} [klarnaAuthorizationToken] 
     * @param {boolean} [userClickedContinue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public simplifiedPlaceOrderUsingPOST(saveCard?: boolean, selectedCard?: string, orderRef?: string, klarnaAuthorizationToken?: string, userClickedContinue?: boolean, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).simplifiedPlaceOrderUsingPOST(saveCard, selectedCard, orderRef, klarnaAuthorizationToken, userClickedContinue, options).then((request) => request(this.axios, this.basePath));
    }
}
