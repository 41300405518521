import { getMixedCampaigns } from '@api';
import getStore from '@api/getStore';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import FixedRowsProductGrid from '@components/organisms/FixedRowsProductGrid';
import CAMPAIGN_TYPE from '@constants/campaignType';
import LINKS from '@constants/links';
import { DESKTOP_XXL_PRODUCTS_AMOUNT } from '@constants/promotionProductsGrid';
import logError from '@helpers/logHelpers';
import { usePromotionStore } from '@hooks/localStorage';
import { useAppSelector } from '@hooks/redux';
import useAssortmentKey from '@hooks/useAssortmentKey';
import useCustomer from '@hooks/useCustomer';
import useEventListName from '@hooks/useEventListName';
import { selectActiveStore } from '@selectors/delivery';
import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

interface AxfoodPromotionsCMSComponentProps {
  component: AxfoodPromotionsCMSComponent;
}

const LIST_NAME = 'Aktuella erbjudanden';
export const DEFAULT_STORE_ID = '4001';
const ROWS_TO_RENDER = 2;

const AxfoodPromotionsCMSComponent = ({ component }: AxfoodPromotionsCMSComponentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generalOffers, setGeneralOffers] = useState<Product[]>([]);
  const [inactiveStoreName, setInactiveStoreName] = useState('');

  const { t } = useTranslation('promotions');
  const activeStore = useAppSelector(selectActiveStore);
  const { homeStoreId } = useCustomer();
  const storeId = activeStore?.storeId;

  const { EVENT_LIST_NAME } = useEventListName(LIST_NAME);
  const [{ promotionStoreId }] = usePromotionStore();
  const hasPromotionStore = !!promotionStoreId && promotionStoreId !== DEFAULT_STORE_ID && promotionStoreId !== storeId;
  const hasHomeStore = !!homeStoreId && homeStoreId !== DEFAULT_STORE_ID && homeStoreId !== storeId;
  const hasInactiveStore = hasPromotionStore || hasHomeStore;
  const title = activeStore?.activelySelected || hasInactiveStore ? t('allOffers->title') : t('allOffers->titleAll');
  const assortmentKey = useAssortmentKey();

  useEffect(() => {
    const source = axios.CancelToken.source();
    let isCancelled = false;

    const fetchData = async () => {
      if (!assortmentKey) {
        return;
      }
      !isCancelled && setIsLoading(true);
      try {
        const query = promotionStoreId || storeId || homeStoreId || DEFAULT_STORE_ID;
        if (hasInactiveStore && !inactiveStoreName) {
          const store = await getStore({ storeId: query }, source.token);
          !isCancelled && setInactiveStoreName(store.data.name || '');
        } else {
          !isCancelled && setInactiveStoreName('');
        }
        const { results } = await getMixedCampaigns(
          query,
          CAMPAIGN_TYPE.LOYALTY,
          DESKTOP_XXL_PRODUCTS_AMOUNT * 2,
          undefined,
          undefined,
          { cancelToken: source.token }
        );
        if (results && !isCancelled) {
          setGeneralOffers(results);
        }
      } catch (e) {
        logError('Failed to get AxfoodPromotionsCMSComponent products', e);
      } finally {
        !isCancelled && setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
      source.cancel('Cancel loyalty campaigns request');
    };
  }, [assortmentKey, assortmentKey?.assortmentStoreId, assortmentKey?.slotId, promotionStoreId, storeId, homeStoreId]);

  return (
    <FixedRowsProductGrid
      products={generalOffers}
      eventListName={EVENT_LIST_NAME}
      title={title}
      subtitle={inactiveStoreName || activeStore?.name}
      href={LINKS.OFFERS}
      linkText={t('allOffers->showMore')}
      isLoading={isLoading}
      rows={ROWS_TO_RENDER}
    />
  );
};

export default AxfoodPromotionsCMSComponent;
