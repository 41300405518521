
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { PageAdaptedData } from '@api/generated/cms';
import Layout from '@components/Layout/Layout';
import ErrorBoundary, { ErrorBoundaryWithRouter } from '@components/atoms/ErrorBoundary';
import Error500 from '@components/templates/ErrorPages/500';
import { ONE_HOUR_IN_MS } from '@constants/timers';
import { ApplicationContextProvider } from '@context/ApplicationContext';
import PageContext, { PageContextType } from '@context/PageContext';
import { fetcher as customerFetcher, key as customerSwrKey } from '@hooks/useCustomer';
import { fetcher as featureFlagFetcher, key as featureSwrKey } from '@hooks/useFeatureFlags';
import useUserAgent from '@hooks/useUserAgent';
import StoreProvider from 'StoreProvider';
import useTranslation from 'next-translate/useTranslation';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ErrorInfo, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { SWRConfig, preload } from 'swr';
import '../axios-config';
import '../globalFetchInterceptor';
import GlobalStyles from '../styles/global';
import theme from '../styles/theme';
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    require('../mswMocks');
}
const errorBoundaryHandler = (error: Error, info: ErrorInfo) => {
    if (typeof window.newrelic !== 'undefined' && info.componentStack) {
        const { newrelic } = window;
        newrelic.noticeError('Error boundary caught error: ' + error.message, {
            stackTrace: info.componentStack,
        });
    }
};
interface MyAppProps extends AppProps {
    pageProps: {
        fallback?: PageAdaptedData;
        cmsPageId?: PageContextType['cmsPageId'];
    };
    analyticsKey: string;
}
if (typeof window !== 'undefined') {
    preload(featureSwrKey, featureFlagFetcher);
    preload(customerSwrKey, customerFetcher);
}
const DefaultHead = () => {
    const { t } = useTranslation('common');
    const { isReactNative } = useUserAgent();
    return (<Head>
      <title>{t('seo->defaultTitle')}</title>
      <meta name="viewport" content={`width=device-width, initial-scale=1, minimum-scale=1, ${isReactNative ? 'maximum-scale=1,' : ''} viewport-fit=cover`}/>
      <meta name="description" key="description" content={t('seo->defaultDescription')}/>
    </Head>);
};
const MyApp = ({ Component, pageProps }: MyAppProps) => {
    const [userAgent, setUserAgent] = useState('');
    useEffect(() => {
        if (navigator?.userAgent)
            setUserAgent(navigator.userAgent);
    }, []);
    return (<SWRConfig value={{
            fallback: { ...pageProps.fallback },
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            refreshInterval: ONE_HOUR_IN_MS,
        }}>
      <StoreProvider>
        <ApplicationContextProvider userAgent={userAgent}>
          <PageContext.Provider value={{ cmsPageId: pageProps.cmsPageId }}>
            <ThemeProvider theme={theme}>
              <ErrorBoundary errorHandler={errorBoundaryHandler} fallback={<Error500 />}>
                <GlobalStyles />
                <Layout>
                  <ErrorBoundaryWithRouter errorHandler={errorBoundaryHandler} fallback={<Error500 />}>
                    <DefaultHead />
                    <Component {...pageProps}/>
                  </ErrorBoundaryWithRouter>
                </Layout>
              </ErrorBoundary>
            </ThemeProvider>
          </PageContext.Provider>
        </ApplicationContextProvider>
      </StoreProvider>
    </SWRConfig>);
};
const __Next_Translate__Page__195d779ee42__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195d779ee42__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  