import useCart from '@features/cart/useCart';
import useAssortmentStore from '@hooks/useAssortmentStore';
import { useEffect, useState } from 'react';

export interface AssortmentKey {
  assortmentStoreId?: string;
  slotId?: string;
}

const useSlotId = (): string | undefined => {
  const { cart } = useCart();
  const slot = cart?.slot;
  return slot?.code;
};

/**
 * The assortment key is a combination of the assortment store and the currently selected
 * delivery slot. The storeId is undefined if the online assortment is selected.
 *
 * Initially the key is undefined until store and slot has been determined. This way, you can hold
 * fetching of product data until we know which assortment and stock we have.
 *
 * Consumers of assortmentKey may react on when the key is defined and changes for store and slot.
 *
 * For SWR, the assortmentKey is spread in the SWR key and the presence of the assortmentKey can
 * be used to only define the SWR key when we have an assortment key, to hold loading of data.
 *
 * Non SWR uses may use presence of key to determine if data should be fetched and detect changes
 * for store and slot.
 */
const useAssortmentKey = () => {
  const [assortmentKey, setAssortmentKey] = useState<AssortmentKey>();
  const assortmentStoreId = useAssortmentStore();
  const slotId = useSlotId();

  useEffect(() => {
    setAssortmentKey({ assortmentStoreId, slotId });
  }, [assortmentStoreId, slotId]);

  return assortmentKey;
};

export default useAssortmentKey;
