import DOMPurify from 'isomorphic-dompurify';
const allowedOnClicks = ['OneTrust.ToggleInfoDisplay()']; // Needed because of onetrust-button on cms pages that opens cookie options menu
export interface SanitizedHtmlProps {
  // string with html content
  content: string;
  // mode to determine the config for the sanitizer
  mode?: 'allowNone' | 'default';
  className?: string;
}

const allowNoneConfig = {
  ALLOWED_TAGS: [],
  ALLOWED_ATTR: [],
};

const defaultConfig = {
  ADD_ATTR: ['onclick'], // see afterSanitizeAttributes hook for specific conditions for button onclick
};

//  * @summary SanitizedHtml is a component that aim to centralize the config for
//  * how html strings are displayed in dangerouslySetInnerHTML through sanitizeHtml from the cms
//  */
const SanitizedHtml = ({ content, mode = 'default', className }: SanitizedHtmlProps) => {
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    const nodeAttributeValue = node.getAttribute('onclick');
    if (node.tagName.toLowerCase() === 'button' && nodeAttributeValue && !allowedOnClicks.includes(nodeAttributeValue)) {
      node.parentElement?.removeChild(node);
    }
  });

  const config = mode === 'allowNone' ? allowNoneConfig : defaultConfig;
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, config),
      }}
    />
  );
};

export default SanitizedHtml;
