import useCart from '@features/cart/useCart';
import { useAppSelector } from '@hooks/redux';
import { selectActiveStoreId } from '@selectors/delivery';

// previously known as selectAssortmentStoreId
const useAssortmentStore = () => {
  const { cart: { slot = undefined } = {}  } = useCart();
  const activeStoreId = useAppSelector(selectActiveStoreId);
  return  slot?.pickupStoreId || activeStoreId
}

export default useAssortmentStore;