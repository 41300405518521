import { FontSizeType, sizeLookup } from '@styles/fontSizes';
import { FontWeights } from '@styles/fonts';
import styled, { DefaultTheme, css } from 'styled-components';
import { ParagraphColor } from './Paragraph';

export interface IParagraphStyleProps {
  $size: FontSizeType;
  $weight: FontWeights;
  $color: ParagraphColor;
  $truncateText: boolean;
  $italics: boolean;
  $truncateRows: number;
}

const getColorStyle = (textColor: ParagraphColor, theme: DefaultTheme) => {
  switch (textColor) {
    case 'orange':
      return `color: ${theme.colors.colorEcstasy};`;
    case 'white':
      return `color: ${theme.colors.colorDesertStorm};`;
    case 'primary':
      return `color: ${theme.colors.colorPrimary};`;
    case 'secondary':
      return css`
        color: ${theme.colors.colorTextSecondary};
      `;

    case 'silver':
      return css`
        color: ${theme.colors.colorSilver};
      `;
    default:
      return `color: ${theme.colors.colorTextPrimary};`;
  }
};

export const ParagraphStyled = styled.p<IParagraphStyleProps>`
  line-height: 1.5;
  text-decoration: none;
  margin: 0;
  ${({ theme, $color }) => getColorStyle($color, theme)};
  font-weight: ${({ $weight, theme }) => theme.fonts.fontWeight[$weight]};
  ${({ $italics }) =>
    $italics &&
    css`
      font-style: italic;
    `};
  ${({ $size }) => sizeLookup[$size]};
  ${({ $truncateText }) =>
    $truncateText &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  ${({ $truncateRows }) =>
    $truncateRows &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${$truncateRows};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    `}
`;
