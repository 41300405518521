import Skeleton from '@components/atoms/Skeleton';
import Spinner from '@components/atoms/Spinner';
import Text from '@components/atoms/Text';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import IconButton from '@components/molecules/IconButton';
import ProductDiscounts from '@components/organisms/CartProductList/ProductDiscounts';
import ConfirmModal from '@components/organisms/ConfirmModal';
import CartQuantity from '@components/organisms/Header/Cart/CartQuantity';
import { URLPREFIXES } from '@constants/links';
import {
  getProductUrl,
  isXforYProduct,
  pickUnitName,
  priceToNumerical,
  shouldShowRemoveProductModal,
} from '@helpers/productHelper';
import useProductQuantityWithCart from '@hooks/useProductQuantityWithCart';
import type { AxfoodCartProductViewModel } from '@occ/api-client';
import IconRemove from '@public/icons/regularIcons/icon-remove.svg';
import { cartTracker, productTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { usePathname } from 'next/navigation';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import {
  Container,
  PriceDepositWrapper,
  ProductInfo,
  ProductInfoBottomRow,
  ProductInfoTopRow,
  ProductTitle,
  ProductTitles,
  QuantityWrapper,
  StyledLink,
  Thumbnail,
  ThumbnailWrapper,
} from './MiniCartProductRow.styles';

const urlPrefix = URLPREFIXES.PRODUCT_DETAIL;
export const MINICART_EVENT_LIST = { varor: '/minicart | Varor', recept: '/minicart | Recept' };

interface Props {
  product: AxfoodCartProductViewModel;
  setIsConfirmModalVisible: (isVisible: boolean) => void;
}

const MiniCartProductRow = React.forwardRef<any, Props>(({ product, setIsConfirmModalVisible }: Props, ref) => {
  const [isRemovingProduct, setIsRemovingProduct] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const pathname = usePathname() ?? '';
  const {
    changeQuantityBy,
    onValueChange,
    onFocus,
    onBlur,
    quantity,
    quantityFieldIsActive,
    removeProductFromCart,
    isChangingOrderForSoldOutProduct,
    isIncrementDisabledDueToLowStock,
    maxValue,
  } = useProductQuantityWithCart({
    product,
    eventListName: product.quantityIncludedInRecipe ? MINICART_EVENT_LIST.recept : MINICART_EVENT_LIST.varor,
  });
  const as = `${urlPrefix}${encodeURI(getProductUrl(product))}`;
  const query = {
    name: encodeURIComponent(getProductUrl(product)),
    productCode: product.code,
  };
  const unit = pickUnitName(product);
  const incrementValue = product.incrementValue || 0;

  const { t } = useTranslation('minicart');

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    return () => {
      setIsConfirmModalVisible(false);
    };
  }, [setIsConfirmModalVisible]);

  const removeProduct = async () => {
    setIsRemovingProduct(true);
    removeProductFromCart()
      .then((_data) => {
        cartTracker.removeProduct(
          product,
          quantity,
          product.quantityIncludedInRecipe ? MINICART_EVENT_LIST.recept : MINICART_EVENT_LIST.varor
        );
      })
      .finally(() => {
        isMounted && setIsRemovingProduct(false);
      });
  };

  const handleOnDecrement = () => {
    const recipeQuantity = product.quantityIncludedInRecipe && priceToNumerical(product.quantityIncludedInRecipe);
    const showModal = shouldShowRemoveProductModal(unit, quantity, incrementValue, recipeQuantity);
    if (showModal) {
      setShowConfirmModal(true);
      setIsConfirmModalVisible(true);
    } else {
      changeQuantityBy(-incrementValue);
    }
  };

  const handleOnValueChange = (quantityField: ChangeEvent<HTMLInputElement>) => {
    if (!quantityField.target.valueAsNumber) {
      setShowConfirmModal(true);
      setIsConfirmModalVisible(true);
    } else {
      onValueChange(quantityField);
    }
  };

  const onConfirmRemoveProductModal = async () => {
    await removeProduct();
    setShowConfirmModal(false);
    setIsConfirmModalVisible(false);
  };

  const onCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setIsConfirmModalVisible(false);
    onBlur();
  };
  const onProductLinkClicked = () => {
    productTracker.trackProductClicked(product, MINICART_EVENT_LIST.varor);
  };

  const handleOnBlur = () => {
    if (!quantity) {
      setShowConfirmModal(true);
      setIsConfirmModalVisible(true);
    } else {
      onBlur();
    }
  };

  const isXForY = isXforYProduct(product);

  return (
    <Container ref={ref} $withBottomBorder={!product.potentialPromotions.length} data-testid="productRow">
      <ThumbnailWrapper $withThumbnail>
        <Link
          href={{ pathname, query }}
          nextLinkAs={{ pathname: as }}
          underlined={false}
          title={product.name}
          shallow
          onClick={onProductLinkClicked}
          forceInternal
          dynamicUrlPrefix={urlPrefix}
        >
          {product.thumbnail?.url ? (
            <Thumbnail src={product.thumbnail?.url} alt={product.name} data-testid="product-row-image" />
          ) : (
            <Skeleton />
          )}
        </Link>
      </ThumbnailWrapper>
      <ProductInfo>
        <ProductInfoTopRow>
          <ProductTitles>
            <ProductTitle>
              <StyledLink
                href={{ pathname, query }}
                nextLinkAs={{ pathname: as }}
                underlined={false}
                title={product.name}
                shallow
                margin={false}
                onClick={onProductLinkClicked}
                forceInternal
                dynamicUrlPrefix={urlPrefix}
                data-testid="product-row-title"
              >
                <Text type="p" size="lg" color="black" truncateText>
                  {product.name}
                </Text>
              </StyledLink>
            </ProductTitle>
            <Paragraph size="sm" data-testid="product-row-sub-title">
              {product.productLine2}
            </Paragraph>
          </ProductTitles>
          {isRemovingProduct ? (
            <div>
              <Spinner color="black" />
            </div>
          ) : (
            <IconButton onClick={removeProduct} data-testid="product-row-remove-icon" icon={IconRemove} />
          )}
        </ProductInfoTopRow>
        <ProductInfoBottomRow>
          <QuantityWrapper>
            <CartQuantity
              value={quantity}
              unit={unit}
              outOfStock={product.outOfStock && !isChangingOrderForSoldOutProduct}
              decrement={handleOnDecrement}
              increment={() => changeQuantityBy(incrementValue)}
              isActive={quantityFieldIsActive}
              onChange={handleOnValueChange}
              onBlur={handleOnBlur}
              onFocus={onFocus}
              isIncrementDisabledDueToLowStock={isIncrementDisabledDueToLowStock}
              maxValue={maxValue}
            />
          </QuantityWrapper>
          <PriceDepositWrapper>
            {product.appliedPromotions?.length ? (
              <Paragraph
                size="md"
                textColor={isXForY ? 'primary' : 'black'}
                data-testid="product-row-price-with-deposit"
              >
                {product.totalDiscountedPriceWithDeposit}
              </Paragraph>
            ) : (
              <Paragraph size="md" data-testid="product-row-price" textColor="black">
                {product.totalPrice}
              </Paragraph>
            )}
            {!isXForY && <ProductDiscounts product={product} />}
            {product.totalDeposit && (
              <Paragraph size="sm" data-testid="product-row-deposit">
                {t('deposit->message', { totalDeposit: product.totalDeposit })}
              </Paragraph>
            )}
          </PriceDepositWrapper>
        </ProductInfoBottomRow>
      </ProductInfo>
      {showConfirmModal && <ConfirmModal onClose={onCloseConfirmModal} onConfirm={onConfirmRemoveProductModal} />}
    </Container>
  );
});
MiniCartProductRow.displayName = 'MiniCartProductRow';

const areEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps.setIsConfirmModalVisible !== nextProps.setIsConfirmModalVisible) return false;
  if (prevProps.product.code !== nextProps.product.code) return false;
  if (prevProps.product.totalPrice !== nextProps.product.totalPrice) return false;
  if (prevProps.product.totalDiscountedPrice !== nextProps.product.totalDiscountedPrice) return false;
  if (prevProps.product.appliedPromotions !== nextProps.product.appliedPromotions) return false;
  if (prevProps.product.outOfStock !== nextProps.product.outOfStock) return false;
  if (prevProps.product.potentialPromotions !== nextProps.product.potentialPromotions) return false;
  if (prevProps.product.quantityIncludedInRecipe !== nextProps.product.quantityIncludedInRecipe) return false;
  return true;
};

export default memo(MiniCartProductRow, areEqual);
