import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import ProductMessageModal from '@components/molecules/ProductMessageModal';
import ProductQuantity from '@components/molecules/ProductQuantity';
import { pickUnitName } from '@helpers/productHelper';
import useProductQuantityWithCart from '@hooks/useProductQuantityWithCart';
import useTranslation from 'next-translate/useTranslation';
import {
  PromotionUsed,
  QuantityAndPromotionLimitWrapper,
  QuantityWrapper,
  RedeemAndUsedPromotionWrapper,
  RedeemLabel,
} from './ProductDetails.styles';

interface ProductDetailsQuantityProps {
  product: AxfoodProductDetailsViewModel;
}

const ProductDetailsQuantity = ({ product }: ProductDetailsQuantityProps) => {
  const {
    changeQuantityBy,
    onValueChange,
    onFocus,
    onBlur,
    quantity,
    quantityFieldIsActive,
    modalState,
    setModalState,
  } = useProductQuantityWithCart({
    eventListName: '',
    product,
  });

  const { t } = useTranslation('product');
  const promotion = product.potentialPromotions[0];
  const hasPromotion = !!promotion;

  const incrementValue = product.incrementValue || 0;
  const unit = pickUnitName(product);

  return (
    <>
      <QuantityAndPromotionLimitWrapper>
        <QuantityWrapper>
          <ProductQuantity
            value={quantity}
            unit={unit}
            outOfStock={!!product.outOfStock}
            decrement={() => changeQuantityBy(-incrementValue)}
            increment={() => changeQuantityBy(incrementValue)}
            isActive={quantityFieldIsActive}
            onChange={onValueChange}
            onBlur={onBlur}
            onFocus={onFocus}
            incrementValue={incrementValue}
          />
        </QuantityWrapper>
        <RedeemAndUsedPromotionWrapper>
          <RedeemLabel data-testid="redeem-label">{(hasPromotion && promotion.redeemLimitLabel) || ''}</RedeemLabel>
          {!!(hasPromotion && promotion.timesUsed && promotion.timesUsed > 0) && (
            <PromotionUsed data-testid="promotion-used">
              <Paragraph size="md" textColor="primary">
                {t('productDetails:productDetails->promotionUsed', { timesUsed: promotion.timesUsed })}
              </Paragraph>
            </PromotionUsed>
          )}
        </RedeemAndUsedPromotionWrapper>
      </QuantityAndPromotionLimitWrapper>
      {modalState !== 'NONE' && <ProductMessageModal status={modalState} setStatus={setModalState} />}
    </>
  );
};

export default ProductDetailsQuantity;
