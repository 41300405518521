import ErrorBoundary from '@components/atoms/ErrorBoundary';
import OldAxfoodResponsiveBannerComponent from '@components/cmsComponents/__DEPRECATED__/AxfoodResponsiveBannerComponent';
import ArticleComponent from '@components/cmsComponents/ArticleComponent';
import AxfoodContentUspCmsComponent from '@components/cmsComponents/AxfoodContentUspCmsComponent';
import AxfoodFAQComponent from '@components/cmsComponents/AxfoodFAQComponent';
import AxfoodProductBannerComponent from '@components/cmsComponents/AxfoodProductBannerComponent';
import AxfoodProductCarouselComponent from '@components/cmsComponents/AxfoodProductCarouselComponent';
import AxfoodProductResponsiveBannerComponent from '@components/cmsComponents/AxfoodProductResponsiveBannerComponent';
import AxfoodPromoBannerComponent from '@components/cmsComponents/AxfoodPromoBannerComponent/AxfoodPromoBannerComponent';
import AxfoodPromoResponsiveBannerComponent from '@components/cmsComponents/AxfoodPromoResponsiveBannerComponent';
import AxfoodPromotionsCMSComponent from '@components/cmsComponents/AxfoodPromotionsCMSComponent';
import AxfoodResponsiveBannerComponent from '@components/cmsComponents/AxfoodResponsiveBannerComponent';
import CMSParagraphComponent from '@components/cmsComponents/CMSParagraphComponent';
import GenericPageBannerListComponent from '@components/cmsComponents/GenericPageBannerListComponent';
import HorizontalBannerCarouselComponent from '@components/cmsComponents/HorizontalBannerCarouselComponent';
import MultiColumnContainerComponent from '@components/cmsComponents/MultiColumnContainerComponent';
import PromotedProductsComponent from '@components/cmsComponents/PromotedProductsComponent';
import SimpleBannerComponent from '@components/cmsComponents/SimpleBannerComponent';
import SimpleHeroComponent, {
  SimpleHeroComponentData,
} from '@components/cmsComponents/SimpleHeroComponent/SimpleHeroComponent';
import VerticalBannerComponent from '@components/cmsComponents/VerticalBannerComponent';
import useFeature from '@hooks/useFeature';
import useHideCmsComponent from '@hooks/useHideCmsComponent';
import useSmartEdit from '@hooks/useSmartEdit';
import logger from 'config/logger';
import { ErrorInfo, ReactElement, ReactNode } from 'react';
import AxfoodCustomerServiceContactComponent from '../AxfoodCustomerServiceContactComponent';
import AxfoodEntranceComponent from '../AxfoodEntranceComponent/AxfoodEntranceComponent';
import AxfoodPressReleaseListComponent from '../AxfoodPressReleaseListComponent';
import AxfoodResponsiveBannerCarouselComponent from '../AxfoodResponsiveBannerCarouselComponent';
import AxfoodResponsiveImageAndParagraphComponent from '../AxfoodResponsiveImageAndParagraphComponent';
import AxfoodVideoBannerComponent from '../AxfoodVideoBannerComponent';
import AxfoodWeeklyFlyerComponent from '../AxfoodWeeklyFlyerComponent';
import CategoryHero from '../CategoryHeroComponent/CategoryHero';
import CategoryProductsComponent from '../CategoryProductsComponent';
import CMSImageComponent from '../CMSImageComponent';
import { Wrapper } from './CmsComponent.styles';

interface WrapperProps {
  children: ReactNode;
}

interface CmsComponentProps {
  component: CmsComponent;
  slotName?: string;
  slotPosition?: number;
  excludeWrapper?: boolean;
}

const excludeWrappingForComponents = [
  'MultiColumnContainerComponent',
  'AxfoodContentUspCmsComponent',
  'VerticalBannerComponent', // margins are handled within component
];

const ComponentWrapper = ({ children }: WrapperProps) => {
  if (!children || children.toString() === '') return null;
  return <Wrapper data-testid="wrapper">{children}</Wrapper>;
};
// eslint-disable-next-line fp/no-mutation
ComponentWrapper.displayName = 'ComponentWrapper';

type supportedTypeCodes =
  | 'AxfoodPromoResponsiveBannerComponent'
  | 'MultiColumnContainerComponent'
  | 'HorizontalBannerCarouselComponent'
  | 'SimpleBannerComponent'
  | 'GenericPageBannerListComponent'
  | 'AxfoodContentUspCmsComponent'
  | 'AxfoodPromotionsCMSComponent'
  | 'AxfoodProductCarouselComponent'
  | 'AxfoodResponsiveBannerComponent'
  | 'AxfoodProductBannerComponent'
  | 'AxfoodProductResponsiveBannerComponent'
  | 'PromotedProductsComponent'
  | 'CMSImageComponent'
  | 'CMSParagraphComponent'
  | 'ArticleComponent'
  | 'AxfoodPressReleaseListComponent'
  | 'AxfoodCustomerServiceContactComponent'
  | 'VerticalBannerComponent'
  | 'AxfoodFAQComponent'
  | 'AxfoodVideoBannerComponent'
  | 'AxfoodResponsiveImageAndParagraphComponent'
  | 'AxfoodResponsiveBannerCarouselComponent'
  | 'SimpleHeroComponent'
  | 'AxfoodEntranceComponent'
  | 'AxfoodWeeklyFlyerComponent'
  | 'CategoryProductsComponent'
  | 'AxfoodPromoBannerComponent';

type SupportedComponents = { [typeCode in supportedTypeCodes]: ReactElement };

export const ERROR_BOUNDARY_NAME = 'CMS_COMPONENT_ERROR_BOUNDARY';

const errorBoundaryHandler = (error: Error, info: ErrorInfo) => {
  logger.error({
    error: ERROR_BOUNDARY_NAME,
    message: error.message,
    responseData: info,
  });
};

const WithOptionalEdit = ({ component, children }: { component: CmsComponent; children: ReactElement }) => {
  const { isSmartEditEnabled, catalogVersionUuid: smartEditCv } = useSmartEdit();

  if (!children) {
    return null;
  }

  if (!isSmartEditEnabled) {
    return children;
  }

  return (
    <div
      className="smartEditComponent"
      data-smartedit-component-type={component.typeCode}
      data-smartedit-component-uuid={component.uuid}
      data-smartedit-component-id={component.uid}
      data-smartedit-catalog-version-uuid={smartEditCv}
    >
      {children}
    </div>
  );
};

const WithOptionalWrapper = ({
  component,
  noWrapper,
  children,
}: {
  component: CmsComponent;
  noWrapper: boolean;
  children: ReactElement;
}) => {
  if (!children) {
    return null;
  }

  if (excludeWrappingForComponents.includes(component.typeCode) || noWrapper) {
    return children;
  }

  return <ComponentWrapper>{children}</ComponentWrapper>;
};

const CmsComponent = ({ component, slotName, slotPosition, excludeWrapper = false }: CmsComponentProps) => {
  const hideComponent = useHideCmsComponent();

  // TODO Add component the usual way when released
  const categoryHeroComponentEnabled = useFeature('FEATURE_CATEGORY_HERO_MR');
  const newAxfoodResponsiveBannerComponentEnabled = useFeature('FEATURE_NEW_DESIGN_RESPONSIVE_BANNER_COMPONENT_EL');

  const getCMSComponentTemplate = () => {
    if (categoryHeroComponentEnabled && component?.typeCode === 'HemkopCategoryHeroComponent') {
      return <CategoryHero component={component as HemkopCategoryHeroComponent} />;
    }

    const componentLiteral: SupportedComponents = {
      AxfoodPromoResponsiveBannerComponent: (
        <AxfoodPromoResponsiveBannerComponent
          component={component as AxfoodPromoResponsiveBannerComponentType}
          slotName={slotName}
          slotPosition={slotPosition}
        />
      ),
      MultiColumnContainerComponent: (
        <MultiColumnContainerComponent
          component={component as MultiColumnContainerComponentType}
          slotName={slotName}
          slotPosition={slotPosition}
        />
      ),
      HorizontalBannerCarouselComponent: (
        <HorizontalBannerCarouselComponent
          component={component as HorizontalBannerCarouselCmsComponentType}
          slotPosition={slotPosition}
        />
      ),
      AxfoodResponsiveBannerCarouselComponent: (
        <AxfoodResponsiveBannerCarouselComponent
          component={component as AxfoodResponsiveBannerCarouselComponentType}
          slotPosition={slotPosition}
        />
      ),
      SimpleBannerComponent: <SimpleBannerComponent component={component as SimpleBannerComponentType} />,
      GenericPageBannerListComponent: (
        <GenericPageBannerListComponent component={component as GenericPageBannerListComponentType} />
      ),

      AxfoodContentUspCmsComponent: <AxfoodContentUspCmsComponent component={component as any} />,
      AxfoodProductCarouselComponent: (
        <AxfoodProductCarouselComponent component={component as AxfoodProductCarouselComponentType} />
      ),
      AxfoodPromotionsCMSComponent: (
        <AxfoodPromotionsCMSComponent component={component as AxfoodPromotionsCMSComponent} />
      ),
      AxfoodResponsiveBannerComponent: newAxfoodResponsiveBannerComponentEnabled ? (
        <AxfoodResponsiveBannerComponent
          component={component as AxfoodResponsiveBannerComponentType}
          slotPosition={slotPosition}
        />
      ) : (
        <OldAxfoodResponsiveBannerComponent
          component={component as AxfoodResponsiveBannerComponentType}
          slotPosition={slotPosition}
        />
      ),
      AxfoodProductBannerComponent: (
        <AxfoodProductBannerComponent component={component as AxfoodProductBannerComponentType} />
      ),
      AxfoodProductResponsiveBannerComponent: (
        <AxfoodProductResponsiveBannerComponent component={component as AxfoodProductResponsiveBannerComponentType} />
      ),
      AxfoodEntranceComponent: <AxfoodEntranceComponent component={component as AxfoodEntranceComponentData} />,
      PromotedProductsComponent: <PromotedProductsComponent component={component as PromotedProductsComponent} />,
      CMSImageComponent: <CMSImageComponent component={component as CMSImageComponent} />,
      CMSParagraphComponent: <CMSParagraphComponent component={component as CMSParagraphComponent} />,
      ArticleComponent: <ArticleComponent component={component as ArticleComponent} />,
      AxfoodPressReleaseListComponent: <AxfoodPressReleaseListComponent />,
      AxfoodCustomerServiceContactComponent: <AxfoodCustomerServiceContactComponent />,
      VerticalBannerComponent: <VerticalBannerComponent component={component} />,
      AxfoodFAQComponent: <AxfoodFAQComponent component={component as AxfoodFAQComponent} />,
      AxfoodVideoBannerComponent: <AxfoodVideoBannerComponent component={component as AxfoodVideoBannerComponentType} />,
      AxfoodResponsiveImageAndParagraphComponent: <AxfoodResponsiveImageAndParagraphComponent component={component} />,
      SimpleHeroComponent: (
        <SimpleHeroComponent component={component as SimpleHeroComponentData} slotPosition={slotPosition} />
      ),
      AxfoodWeeklyFlyerComponent: <AxfoodWeeklyFlyerComponent />,
      CategoryProductsComponent: <CategoryProductsComponent data={component as CategoryProductsComponentType} />,
      AxfoodPromoBannerComponent: (
        <AxfoodPromoBannerComponent
          component={component as AxfoodPromoBannerComponentData}
          slotPosition={slotPosition}
        />
      ),
    };

    return componentLiteral[component.typeCode as supportedTypeCodes] || null;
  };

  if (!component || !component?.typeCode) return null;
  if (hideComponent(component)) return null;

  const cmsComponentTemplate = getCMSComponentTemplate();

  return cmsComponentTemplate ? (
    <ErrorBoundary errorHandler={errorBoundaryHandler} fallback={<div data-testid="error-boundary" />}>
      <WithOptionalWrapper component={component} noWrapper={excludeWrapper}>
        <WithOptionalEdit component={component}>{cmsComponentTemplate}</WithOptionalEdit>
      </WithOptionalWrapper>
    </ErrorBoundary>
  ) : null;
};

export default CmsComponent;
