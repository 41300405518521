import { usePathname } from 'next/navigation';
import { navigationClickTracker } from '@trackers';
import { MenuLinkData } from '../hooks/useTopBarData';
import { Menu, StyledLink } from './MenuLinks.styles';

const MenuLinks = ({ menuLinks }: { menuLinks: MenuLinkData[] }) => {
  const pathname = usePathname();
  const handleLinkClicked = (linkName: string) => {
    navigationClickTracker.linkClickTracker('header', linkName);
  };

  return (
    <Menu>
      {menuLinks?.map(({ title, id, url }) =>
        title && url ? (
          <li key={id}>
            <StyledLink href={url} active={url === pathname} onClick={() => handleLinkClicked(title)} aria-label={title} data-text={title}>
              {title}
            </StyledLink>
          </li>
        ) : null
      )}
    </Menu>
  );
};

export default MenuLinks;
