import useCart from '@features/cart/useCart';
import OrderChangeBanner from '../OrderChangeBanner';

/**
 * A wrapper component for holding all reminders and the logic for displaying them.
 */

const Reminders = () => {
  const { cart: { orderReference = undefined} = {} } = useCart();
  return <>{orderReference && <OrderChangeBanner />}</>;
};

export default Reminders;
