import Icon from '@components/atoms/Icon';
import CartNotifications from '@components/organisms/Header/Cart/CartNotifications';
import LINKS from '@constants/links';
import { roundDecimals } from '@helpers/numberHelpers';
import CartIcon from '@public/icons/regularIcons/icon-cart.svg';
import useCart from '@features/cart/useCart';
import useTranslation from 'next-translate/useTranslation';
import NotificationButton from '../../../../atoms/HeaderButton/NotificationButton';
import MiniCart from '../MiniCart';

const CartButton = ({ showTotalProductAmount }: { showTotalProductAmount: boolean }) => {
  const { t } = useTranslation('common');

  const { cart } = useCart();
  const totalProductAmountInCart = cart?.totalUnitCount || 0;

  const totalProductAmount = totalProductAmountInCart ? roundDecimals(totalProductAmountInCart) : 0

  if (showTotalProductAmount) {
    return <MiniCart totalProductAmount={totalProductAmount} />;
  }

  return (
    <>
      <NotificationButton
        aria-expanded={false}
        aria-label={t('ariaLabels->cart')}
        badgeValue={totalProductAmount}
        borderRadius={16}
        href={LINKS.CART}
      >
        <Icon color="white" icon={CartIcon} />
      </NotificationButton>
      <CartNotifications />
    </>
  );
};
export default CartButton;
