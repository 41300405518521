import UnoptimizedImage from '@components/atoms/UnoptimizedImage/UnoptimizedImage';
import HeroContentComponent from '@components/molecules/HeroComponent/HeroContentComponent';
import { ContentComponentData } from '@components/organisms/ContentComponent';
import { ImageWrapper, PanelWrapper } from './HeroPanel.styles';

export interface HeroPanelData extends ContentComponentData {
  mobileImageUrl: string;
  mobileImageAltText?: string;
}

const HeroPanel = ({ data, isMobile, priority }: { data: HeroPanelData; isMobile: boolean; priority: boolean }) => (
  <>
    {isMobile && data.mobileImageUrl && (
      <ImageWrapper>
        <UnoptimizedImage
          src={data.mobileImageUrl}
          alt={data.mobileImageAltText || 'Hero image'}
          style={{ objectFit: 'cover', objectPosition: 'top' }}
          fill
          priority={priority}
        />
      </ImageWrapper>
    )}
    <PanelWrapper $isMobile={isMobile}>
      <HeroContentComponent data={data} color="black" isMobile={isMobile} />
    </PanelWrapper>
  </>
);

export default HeroPanel;
