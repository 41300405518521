import { rem } from 'polished';

const pico = rem(2);
const nano = rem(4);
const xsmall = rem(8);
const small = rem(16);
const smallMedium = rem(24);
const medium = rem(32);
const mediumLarge = rem(40);
const large = rem(48);
const xlarge = rem(64);
const xxlarge = rem(128);

export const themeSpacing = {
  pico,
  nano,
  xsmall,
  small,
  smallMedium,
  medium,
  mediumLarge,
  large,
  xlarge,
  xxlarge,
};

export type ThemeSpacing = typeof themeSpacing;
export default themeSpacing;
