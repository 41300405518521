import { resolveUrlString } from '@helpers/link';
import useConfig from '@hooks/useConfig';

//TODO B2C-30358: isExternal and doForceInternal should be removed and all links should be expected to be internal
const useLinkUtils = (hrefArg: UrlType, isExternal: boolean, doForceInternal: boolean, dynamicUrlPrefix?: string) => {
  const { config } = useConfig();
  const domainRegex = new RegExp(`${config?.PUBLIC_HOST}[/]?`);

  const getAbsoluteUrl = (href: UrlType, urlString: string, external: boolean) => {
    if (external) {
      return href;
    }
    const host = urlString.startsWith('http') ? '' : config?.PUBLIC_HOST;
    return `${host}${urlString}`;
  };

  //TODO B2C-30358: rewrite/remove: Rewrite this logic to expect links to be internal and make special cases for external links. Links should not use next/link for external links
  const isInternal = (
    href: UrlType,
    urlString: string,
    external: boolean,
    forceInternal: boolean
  ): [boolean, UrlType] => {
    if (urlString.includes('mailto:') || urlString.includes('tel:')) {
      return [true, urlString];
    }
    if (external) {
      return [false, urlString];
    }
    if (forceInternal) {
      return [true, href];
    }
    if (urlString.startsWith('/') || urlString.startsWith('#')) {
      return [true, urlString];
    }
    const sameDomain = domainRegex.test(urlString);
    if (sameDomain) {
      const urlPath = urlString.replace(domainRegex, '/');
      return [true, urlPath];
    }
    return [false, href];
  };

  const urlString = resolveUrlString(hrefArg);
  const [isInternalLink, updatedHref] = isInternal(hrefArg, urlString, isExternal, doForceInternal);
  const absoluteUrl = getAbsoluteUrl(updatedHref, urlString, isExternal);

  return {
    isInternalLink,
    updatedHref,
    absoluteUrl,
  };
};

export default useLinkUtils;
