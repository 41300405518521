import { AxfoodApi } from '@occ/api-client';
import { mapProductDetails } from './mapping';

const api = new AxfoodApi({
  baseUrl: '',
});

export const getProductDetail = async (productCode: string) => {
  const { data } = await api.getProductUsingGet1(productCode);
  return mapProductDetails(data);
};
