import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getDocumentScrollHeight, getWindowInnerHeight, getWindowScrollY } from './globalProperties';

export const threshold = 300;

const useScrollDirection = () => {
  const pathname = usePathname();
  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('up');
  const triggerRef = useRef<HTMLDivElement>(null);

  let ticking = false;
  let lastScroll = 0;
  let lastHeight = 0;

  const sanitizeScrollValue = (scrollY: number) => {
    const rawScrollY = scrollY || 0;

    if (rawScrollY < 0) {
      return 0;
    }

    const contentTop = getDocumentScrollHeight() - getWindowInnerHeight();

    if (rawScrollY > contentTop) {
      return contentTop;
    }

    return rawScrollY;
  };

  const updateScrollDir = useCallback(() => {
    const scroll = sanitizeScrollValue(getWindowScrollY());
    const height = getDocumentScrollHeight() || 0;

    const isScrolling = () => Math.abs(scroll - lastScroll) > 0;
    const isGoingUp = () => scroll < lastScroll;
    const hasReachedThreshold = () => isGoingUp() || scroll >= threshold;

    if (lastHeight === height) {
      if (isScrolling() && hasReachedThreshold()) {
        setScrollDir(isGoingUp() ? 'up' : 'down');
      }
    }

    lastHeight = height;
    lastScroll = scroll;
    ticking = false;
  }, [threshold, setScrollDir, lastScroll]);

  useLayoutEffect(() => {
    lastHeight = getDocumentScrollHeight();

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [updateScrollDir]);

  useEffect(() => {
    setScrollDir('up');
  }, [pathname]);

  return { isScrollingDown: scrollDir === 'down', triggerRef };
};

export default useScrollDirection;
