import { mobileSearchPortalId, searchSuggestionsId } from '@constants/globalElementIds';
import { URLPREFIXES } from '@constants/links';
import { usePathname } from 'next/navigation';
import useResponsive from '@hooks/useResponsive';
import useUserAgent from '@hooks/useUserAgent';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { memo, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import getContentPositioningInfo from './getContentPositioningInfo';
import { DesktopSuggestionsWrapper, MobileSuggestionsWrapper } from './SearchSuggestions.styles';
import SuggestionsContent, { Props } from './SuggestionsContent';

const MobileWrapper = ({ children }: { children: ReactNode }) => {
  const { isIos } = useUserAgent();
  const { viewportTopInDocument, headerHeight } = getContentPositioningInfo();
  const pathname = usePathname();

  useEffect(() => {
    const target = document.getElementById(searchSuggestionsId);

    if (pathname?.includes(URLPREFIXES.PRODUCT_DETAIL)) {
      enableBodyScroll(target);
    } else {
      disableBodyScroll(target);
    }

    return () => {
      enableBodyScroll(target);
    };
  }, [pathname]);

  const portalContainer = document.getElementById(mobileSearchPortalId);
  return portalContainer
    ? ReactDOM.createPortal(
        <MobileSuggestionsWrapper
          $offsetInDocument={viewportTopInDocument}
          $headerObscuredHeight={headerHeight}
          $heightWithUnit={isIos ? '100vh' : '100%'}
        >
          {children}
        </MobileSuggestionsWrapper>,
        portalContainer
      )
    : null;
};

const SearchSuggestionsContainer = ({ children }: { children: ReactNode }) => {
  const { isTabletPortraitOrGreater } = useResponsive();

  if (isTabletPortraitOrGreater) {
    return <DesktopSuggestionsWrapper>{children}</DesktopSuggestionsWrapper>;
  }

  return <MobileWrapper>{children}</MobileWrapper>;
};

const SearchSuggestions = ({ onClickSearchTerm, onCategoryClick, searchOnEnter, multiSearchData }: Props) => {
  return (
    <SearchSuggestionsContainer>
      <SuggestionsContent
        onClickSearchTerm={onClickSearchTerm}
        onCategoryClick={onCategoryClick}
        searchOnEnter={searchOnEnter}
        multiSearchData={multiSearchData}
        data-testid="search-suggestions"
      />
    </SearchSuggestionsContainer>
  );
};

export default memo(SearchSuggestions);
