import useCart from '@features/cart/useCart';
import { checkoutStepTracker } from '@trackers';
import { useEffect, useState } from 'react';

const useTrackCheckoutStep = (step: 1 | 2 | 3) => {
  const { cart: { products = [] } = {}, hasProducts } = useCart();

  const [hasSentTrackingEvent, setHasSentTrackingEvent] = useState(false);
  useEffect(() => {
    if (hasProducts && !hasSentTrackingEvent) {
      checkoutStepTracker.trackCheckoutStep(step, products);
      setHasSentTrackingEvent(true);
    }
  }, [step, hasSentTrackingEvent, hasProducts, products]);
};

export default useTrackCheckoutStep;
