/* eslint-disable react/no-array-index-key */
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { StyledTable, TableCell, TableHeadCell } from './Table.styles';
import { TableColumnsType, TableRowType } from './types';

export interface TableProps {
  columns: TableColumnsType[];
  fullWidth?: boolean;
  rows: Array<TableRowType>;
  'data-testid'?: string;
  className?: string;
}

interface TableRowProps {
  rowData: TableRowType;
}

const TableRow = ({ rowData: { data } }: TableRowProps) => (
  <tr>
    {data.map((cell) => (
      <TableCell key={cell.key}>{cell.value}</TableCell>
    ))}
  </tr>
);

export const Table = ({ columns, fullWidth, rows, 'data-testid': testId = undefined, className }: TableProps) => (
  <StyledTable $fullWidth={fullWidth} data-testid={testId} className={className}>
    <thead>
      <tr>
        {columns.map((col) => (
          <TableHeadCell key={col.id} data-testid="table-head-cell" colSpan={col.colSpan}>
            <Paragraph size="lg">{col.label}</Paragraph>
          </TableHeadCell>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, i) => (
        <TableRow key={i} rowData={row} />
      ))}
    </tbody>
  </StyledTable>
);

export default Table;
