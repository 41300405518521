import { rem } from 'polished';
import styled from 'styled-components';

export const SuggestionsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  position: absolute;
  left: 0;
  right: 0;
`;

export const DesktopSuggestionsWrapper = styled(SuggestionsWrapper)`
  display: flex;
  flex-direction: column;
  top: 100%;
  margin-top: ${rem(8)};
  border-radius: ${rem(16)};
  height: ${rem(560)};
  z-index: ${({ theme }) => theme.layers.header};
`;

export const MobileSuggestionsWrapper = styled(SuggestionsWrapper)<{
  $offsetInDocument: number;
  $headerObscuredHeight: number;
  $heightWithUnit: string;
}>`
  top: ${({ $offsetInDocument }) => $offsetInDocument}px;
  height: ${({ $heightWithUnit }) => $heightWithUnit};
  padding-top: ${({ $headerObscuredHeight }) => rem($headerObscuredHeight)};
  z-index: ${({ theme }) => theme.layers.below_header};
`;
