import { SortData } from '@api/generated/storefront';
import Dropdown from '@components/atoms/Dropdown';
import { Option } from '@components/atoms/Dropdown/Dropdown';
import useAppRouter from '@hooks/useAppRouter';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { productFiltersTracker } from '../../../trackers/productFiltersTracker';

interface DropdownProps {
  sorts: SortData[];
  pageName: string;
}

const ProductSortingDropdown = ({ sorts, pageName }: DropdownProps) => {
  const { push } = useAppRouter();
  const pathname = usePathname() ?? '';
  const searchParams = useSearchParams();
  const sort = searchParams?.get('sort');
  const [activeItem, setActiveItem] = useState<any | null>(sorts[0]);

  const handleOptionClick = (sort: SortData) => {
    const newSearchParams = new URLSearchParams(searchParams?.toString());
    newSearchParams.set('sort', sort.code || '');
    const newPathname = `${pathname}?${newSearchParams.toString()}`;
    push(newPathname, undefined, { scroll: false });
    productFiltersTracker.sortingTracker(pageName, sort.name);
  };

  const updateActiveItem = (option: Option) => {
    setActiveItem(option);
    handleOptionClick(option);
  };

  useEffect(() => {
    if (!!sort && !!sorts.length && activeItem?.code !== sort) {
      const selectedSortFromQuery = sorts.find((sortItem) => sortItem.code === sort);
      setActiveItem(selectedSortFromQuery || sorts[0]);
    }
  }, []);

  return (
    <Dropdown
      id="product-sorting-dropdown"
      options={sorts}
      selectedName={activeItem?.name}
      onOptionClick={updateActiveItem}
    />
  );
};

export default ProductSortingDropdown;
