import axios, { CancelToken } from 'axios';
import Config from 'config';

interface ServerResponse {
  data: CmsData;
}

type ParamProps = {
  cmsTicketId?: string;
};

const getComponent = (
  component: string,
  cmsTicketId: string | undefined,
  cancelToken: CancelToken
): Promise<ServerResponse> => {
  const params: ParamProps = {};

  if (cmsTicketId) {
    // eslint-disable-next-line fp/no-mutation
    params.cmsTicketId = cmsTicketId;
  }
  return axios.get(
    `/axfoodcommercewebservices/v2/${Config.SITE_NAME}/cms/components/${component.replace(/[[\]"]+/g, '')}`,
    { params, cancelToken }
  );
};

export default getComponent;
