export const grayPixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';

export const extractCloudinaryCloudName = (url: string): string | null => {
  try {
    const regex = /https?:\/\/res\.cloudinary\.com\/([^/]+)\//;
    const match = url.match(regex);
    return match ? match[1] : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error extracting Cloudinary cloud name:', error);
    return null;
  }
};
