import LinkButton from '@components/atoms/Button/LinkButton';
import Picture from '@components/atoms/Picture';
import useOnetrustActiveGroupHelper from '@helpers/oneTrustActiveGroupHelper';
import useIntersection from '@hooks/useIntersection';
import IconArrowRight from '@public/icons/regularIcons/icon-arrow-right.svg';
import { useCallback, useEffect, useState } from 'react';

import SanitizedHtml from '@components/atoms/SanitizedHtml';
import { PromoItemCreativeName, trackPromoBannerClick, trackPromoBannerView } from '@features/promotions/tracking';
import {
  Background,
  BackgroundImage,
  Banner,
  BannerNoImage,
  Content,
  ExternalHtml,
  FullWidthLinkArea,
  IconWrapper,
  StyledSanitizedHtml,
} from './AxfoodResponsiveBannerComponent.styles';

export interface AxfoodResponsiveBannerComponentProps {
  component: AxfoodResponsiveBannerComponentType;
  slotPosition?: number;
  onIntersect?: (arg0: string) => void;
  onClick?: (arg0: string) => void;
  trackingName?: PromoItemCreativeName;
}

const AxfoodResponsiveBannerComponent = ({
  component,
  slotPosition = undefined,
  onIntersect,
  onClick,
  trackingName = 'promo_banner',
}: AxfoodResponsiveBannerComponentProps) => {
  const [hasIntersected, setHasIntersected] = useState(false);
  const desktopImage = component?.media?.desktop?.url;
  const tabletImage = component?.media?.tablet?.url;
  const mobileImage = component?.media?.mobile?.url;
  const {
    buttonEnabled,
    buttonText,
    urlLink = '',
    centerContent,
    imageHeightMobile,
    imageHeightTablet,
    imageHeightDesktop,
  } = component;

  const mobileHeight = imageHeightMobile ? parseInt(imageHeightMobile, 10) : 200;
  const tabletHeight = imageHeightTablet ? parseInt(imageHeightTablet, 10) : 330;
  const desktopHeight = imageHeightDesktop ? parseInt(imageHeightDesktop, 10) : 330;
  const showButton = buttonEnabled === 'true' && !!buttonText && !!urlLink;
  const alignment = centerContent === 'true' ? 'center' : 'left';
  const hasBackgroundImage = !!(desktopImage || tabletImage || mobileImage);
  const { name = '', typeCode } = component;
  const gtmPosition = typeof slotPosition === 'number' ? `${slotPosition + 1}` : 'unknown';
  const fontColor = component.fontcolor?.toLowerCase();
  const backgroundColor = component.background?.toLowerCase();
  const isOneTrustReady = useOnetrustActiveGroupHelper();

  const promotionName = showButton ? buttonText : name;
  const handleLinkClick = () => {
    trackPromoBannerClick(urlLink, promotionName, gtmPosition, trackingName);
    !!onClick && onClick(name);
  };

  const [containerRef, intersectionObject] = useIntersection<HTMLDivElement>();

  const handleBannerIntersect = useCallback(() => {
    if (onIntersect) {
      onIntersect(name);
    }
    if (isOneTrustReady) {
      trackPromoBannerView(urlLink, promotionName, gtmPosition, trackingName);
      setHasIntersected(true);
    }
  }, [name, typeCode, gtmPosition, onIntersect, isOneTrustReady]);

  useEffect(() => {
    !hasIntersected && intersectionObject?.isIntersecting && isOneTrustReady && handleBannerIntersect();
  }, [containerRef, intersectionObject?.isIntersecting, handleBannerIntersect, hasIntersected, isOneTrustReady]);

  if (!component) {
    return null;
  }

  const shouldLoadEager = slotPosition !== undefined && slotPosition < 2;

  const banner = hasBackgroundImage ? (
    <Banner
      data-testid="banner"
      ref={containerRef}
      $mobileHeight={mobileHeight}
      $tabletHeight={tabletHeight}
      $desktopHeight={desktopHeight}
    >
      <Background data-testid="background-with-image">
        <BackgroundImage>
          {hasBackgroundImage && component.media && (
            <Picture media={component.media} shouldLoadEager={shouldLoadEager} />
          )}
        </BackgroundImage>
      </Background>
      <Content data-testid="content" $align={alignment}>
        {component.content ? (
          <ExternalHtml $fontColor={fontColor} $hasImage={hasBackgroundImage} data-testid="external-html">
            <StyledSanitizedHtml content={component.content} />
          </ExternalHtml>
        ) : null}
        {showButton && (
          <LinkButton onClick={handleLinkClick} href={urlLink} variant="secondary">
            {buttonText} <IconWrapper icon={IconArrowRight} color="black" />
          </LinkButton>
        )}
      </Content>
    </Banner>
  ) : (
    <BannerNoImage ref={containerRef} data-testid="banner-no-image" $background={backgroundColor}>
      {component.content ? (
        <ExternalHtml $fontColor={fontColor} $hasImage={hasBackgroundImage} data-testid="external-html">
          <SanitizedHtml content={component.content} />
        </ExternalHtml>
      ) : null}
    </BannerNoImage>
  );

  if (!showButton && urlLink) {
    return (
      <FullWidthLinkArea onClick={handleLinkClick} href={urlLink}>
        {banner}
      </FullWidthLinkArea>
    );
  }
  return banner;
};

export default AxfoodResponsiveBannerComponent;
