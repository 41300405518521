import { LayoutContainer } from '@components/organisms/Container/Container';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter';
import { ReactNode } from 'react';

const WithLayoutContainer = ({ children }: { children: ReactNode }) => {
  const { pathname = '' } = useAppRouter();
  const pathsWithoutMargins = [LINKS.FAQ, LINKS.RECIPE, `/preview${LINKS.RECIPE}`];

  const isPageWithoutMargins = pathsWithoutMargins.some((path) => pathname.startsWith(path));

  return isPageWithoutMargins ? children : <LayoutContainer>{children}</LayoutContainer>;
};

export default WithLayoutContainer;
