import Paragraph from '@components/atoms/Paragraph';
import IconFilledArrowUp from '@public/icons/colorIcons/icon-filled-arrow-up.svg';
import ClickIcon from '@public/icons/colorIcons/icon-finger-click.svg';
import useTranslation from 'next-translate/useTranslation';
import {
  ArrowIcon,
  ButtonIcon,
  MixAndMatchButton,
  MixAndMatchWrapper,
  StyledLinkArea,
} from './ProductMixAndMatch.styles';

interface MixAndMatchButtonProps {
  url: UrlType;
  as: string;
}
const ProductMixAndMatch = ({ url, as }: MixAndMatchButtonProps) => {
  const { t } = useTranslation('product');

  return (
    <MixAndMatchWrapper data-testid="mix-and-match-button">
      <StyledLinkArea href={url} nextLinkAs={as} scroll={false} shallow>
        <MixAndMatchButton>
          <ArrowIcon icon={IconFilledArrowUp} className="arrow-up-icon" size={28} color="white" />
          <ButtonIcon icon={ClickIcon} size={16} />
          <Paragraph color="primary" size="small" weight="bold">
            {t('mixmatch->label')}
          </Paragraph>
        </MixAndMatchButton>
      </StyledLinkArea>
    </MixAndMatchWrapper>
  );
};

export default ProductMixAndMatch;
