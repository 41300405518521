import Icon from '@components/atoms/Icon/Icon';
import styled, { css } from 'styled-components';
import { ArrowButtonVariant } from './ArrowLinkButton';
import LinkButton from '@components/atoms/Button/LinkButton';

export const StyledLinkButton = styled(LinkButton)<{ $leftArrow: boolean }>`
  ${({ $leftArrow }) =>
    $leftArrow &&
    css`
      flex-flow: row-reverse;
      justify-content: flex-end;
    `}
`;

export const ButtonIcon = styled(Icon)<{ variant: ArrowButtonVariant }>`
  color: inherit;
`;
