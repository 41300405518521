import HeroComponent from '@components/molecules/HeroComponent';
import { HeroComponentData } from '@components/molecules/HeroComponent/HeroComponent';

type SimpleHeroColor = 'BLACK' | 'WHITE';

export interface SimpleHeroComponentData extends CmsComponent {
  image: any;
  showTextBackground: string;
  headerText: string;
  textColor: SimpleHeroColor;
  bodyText: string;
  buttonText: string;
  buttonLink: string;
  buttonAltText?: string;
}

interface SimpleHeroComponentProps {
  component: SimpleHeroComponentData;
  slotPosition?: number;
}

export const mapFromSimpleHeroComponentData = (component: SimpleHeroComponentData): HeroComponentData => ({
  title: component.headerText,
  text: component.bodyText,
  buttonText: component.buttonText,
  buttonUrl: component.buttonLink || '',
  withPanel: component.showTextBackground?.toLowerCase() === 'true',
  textColor: component.textColor === 'WHITE' ? 'white' : 'black',
  mobileImageUrl: component.image?.mobile?.url,
  mobileImageAltText: component.image?.mobile?.altText,
  desktopImageUrl: component.image?.desktop?.url,
  desktopImageAltText: component.image?.desktop?.altText,
});

const SimpleHeroComponent = ({ component, slotPosition }: SimpleHeroComponentProps) => (
  <HeroComponent data={mapFromSimpleHeroComponentData(component)} slotPosition={slotPosition} />
);

export default SimpleHeroComponent;
