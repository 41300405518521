import { getProductsForProductBanner, ProductBannerReturnType } from '@api';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useAssortmentKey, { AssortmentKey } from '@hooks/useAssortmentKey';
import useCustomer from '@hooks/useCustomer';
import { useCallback } from 'react';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

export const ENDPOINT = 'getProductsForProductBanner';

type KeyType = {
  componentId: string;
  customerId: string;
  assortmentKey?: AssortmentKey;
  page: number;
};

export const carouselSize = 28;

const fetcher = ({ componentId, page }: KeyType) =>
  getProductsForProductBanner({
    componentId,
    size: `${carouselSize}`,
    page: `${page}`,
  });

const options: SWRInfiniteConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
  revalidateFirstPage: false,
};

const getLastResult = (d: ProductBannerReturnType[]) => (d && d.length > 0 ? d[d.length - 1] : undefined);

const useCarouselComponent = (componentId: string) => {
  const { customerId, customerFetchIsRequired } = useCustomer();
  const assortmentKey = useAssortmentKey();

  const getKey = (page: number) =>
    assortmentKey && customerFetchIsRequired
      ? {
          endpoint: ENDPOINT,
          customerId,
          componentId,
          page,
          ...assortmentKey,
        }
      : null;

  const { data = [], isLoading, setSize, isValidating } = useSWRInfinite(getKey, fetcher, options);

  const { paginationData, title } = getLastResult(data) || {};

  const { totalPages = 0, page = 0 } = paginationData || {};

  const hasMorePages = page + 1 < totalPages;

  const loadMore = useCallback(async () => {
    if (hasMorePages) {
      try {
        await setSize((s) => s + 1);
      } catch (e) {
        // handle error
      }
    }
  }, [hasMorePages, setSize]);

  const products: Product[] = data.filter((r) => r.formattedProducts).flatMap((r) => r.formattedProducts);

  return {
    formattedProducts: products,
    isLoading,
    title,
    loadMore,
    isValidating,
    hasMorePages,
  };
};

export default useCarouselComponent;
