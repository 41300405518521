import { UnitData } from '@api/generated/storefront';
import {
  getThumbnail,
  mapComparePrice,
  mapInactivePromotions,
} from '@components/molecules/Product/ProductDataMapper/helpers';
import {
  Analytics,
  ComparePrice,
  HybrisProduct,
  Price,
  Product,
  Promotion,
} from '@components/molecules/Product/ProductDataMapper/types';
import CAMPAIGN_TYPE from '@constants/campaignType';
import PROMOTION_THEME, { PROMOTION_TYPE } from '@constants/promotion';
import { numberValueOrZero, stringArrayOrEmpty, stringValueOrEmpty } from '@helpers/mapping';
import {
  getActualCustomerPrice,
  getLowestHistoricalPrice,
  pickUnitName as getPickUnitName,
  getPreviewUrl,
  getProductUrl,
  productToImpressionObject,
  promotionComparePriceIsSame,
  shouldShowOrdinaryPrice,
} from '@helpers/productHelper';
import { shouldShowPromotionPrice } from '@helpers/promotions';
import { capitalizeFirst } from '@helpers/string';
import { handleWarningLabel } from '@hooks/useProductQuantityWithCart/helpers';
import { AxfoodPromotionViewModel } from 'api/generated/storefront/dto/axfood-promotion-view-model';
import { PromotionDataV2 } from 'api/generated/storefront/dto/promotion-data-v2';

type HybrisPromotion = AxfoodPromotionViewModel & PromotionDataV2;

const mapPromotion = (hybrisProduct: any, priceUnit?: string): Promotion | undefined => {
  const potentialPromotions: HybrisPromotion[] = hybrisProduct?.potentialPromotions || [];
  const firstPromotion: HybrisPromotion = potentialPromotions?.[0];
  if (!firstPromotion) {
    return undefined;
  }

  const hasPromotion = !!firstPromotion;

  const showPromotionPrice = hasPromotion && shouldShowPromotionPrice(firstPromotion as AxfoodPromotionViewModel);
  const hasLoyaltyPromotion = firstPromotion.campaignType === CAMPAIGN_TYPE.LOYALTY;
  const hasSegmentedPromotion = firstPromotion.campaignType === CAMPAIGN_TYPE.SEGMENTED;
  const isPromotionUsed: boolean =
    hasPromotion &&
    !!firstPromotion.promotionRedeemLimit &&
    firstPromotion.promotionRedeemLimit > 0 &&
    !!firstPromotion.timesUsed &&
    firstPromotion.promotionRedeemLimit <= firstPromotion.timesUsed;
  const showOrdinaryPrice = shouldShowOrdinaryPrice(hybrisProduct);

  const isPromotionComparePriceSame = promotionComparePriceIsSame(hybrisProduct);
  const isAlwaysGoodPrice = firstPromotion?.promotionTheme?.code === PROMOTION_THEME.ALWAYS_GOOD_PRICE;
  const lowestHistoricalPriceString = getLowestHistoricalPrice(hybrisProduct);

  const mapMixAndMatchPromotion = (): boolean => {
    if (firstPromotion && hybrisProduct.code) {
      return (
        !!hybrisProduct.online &&
        firstPromotion.promotionType === PROMOTION_TYPE.MIX_MATCH_PRICE_PROMOTION &&
        !!firstPromotion.productCodes &&
        firstPromotion.productCodes.length > 1 &&
        !!firstPromotion.qualifyingCount &&
        firstPromotion.qualifyingCount >= 1
      );
    }
    return false;
  };

  return {
    isMixAndMatch: mapMixAndMatchPromotion(),
    isMixAndMatchPercentage: firstPromotion.promotionType === PROMOTION_TYPE.MIX_MATCH_PERCENTAGE_PROMOTION,
    isPercentage: firstPromotion.rewardLabel?.includes('%') || false,
    isAlwaysGoodPrice,
    isSingelProductAlwaysGoodPricePromotionProduct:
      isAlwaysGoodPrice && firstPromotion.promotionType !== PROMOTION_TYPE.MIX_MATCH_PRICE_PROMOTION,
    isLoyalty: firstPromotion.campaignType === CAMPAIGN_TYPE.LOYALTY,
    isPriceBomb:
      !hasLoyaltyPromotion &&
      !hasSegmentedPromotion &&
      firstPromotion.promotionTheme?.code === PROMOTION_THEME.PRICE_BOMB,
    hasLoyaltyPromotion,
    hasSegmentedPromotion,
    showPromotionPrice,
    promotionComparePrice: mapComparePrice(firstPromotion.comparePrice, hybrisProduct.comparePriceUnit),
    qualifyingCount: numberValueOrZero(firstPromotion.qualifyingCount),
    campaignType: stringValueOrEmpty(firstPromotion.campaignType),
    textLabelGenerated: stringValueOrEmpty(firstPromotion.textLabelGenerated),
    textLabel: stringValueOrEmpty(firstPromotion.textLabel),
    redeemLimitLabel: capitalizeFirst(stringValueOrEmpty(firstPromotion.redeemLimitLabel)),
    lowestHistoricalPrice: lowestHistoricalPriceString
      ? mapComparePrice(lowestHistoricalPriceString, priceUnit)
      : undefined,
    conditionLabel: stringValueOrEmpty(firstPromotion.conditionLabel),
    rewardLabel: stringValueOrEmpty(firstPromotion.rewardLabel),
    conditionLabelFormatted: stringValueOrEmpty(firstPromotion.conditionLabelFormatted),
    isPromotionUsed,
    productCode: stringValueOrEmpty(firstPromotion.mainProductCode),
    productCodes: stringArrayOrEmpty(firstPromotion.productCodes),
    promotionCode: stringValueOrEmpty(firstPromotion.code),
    showOrdinaryPrice,
    isPromotionComparePriceSame,
    endDate: stringValueOrEmpty(firstPromotion.endDate),
  };
};

export const mapHybrisProduct = (hybrisProduct: HybrisProduct): Product => {
  const firstPromotion = hybrisProduct?.potentialPromotions?.[0];
  const hasPromotion = !!firstPromotion;
  const priceUnit = hybrisProduct?.priceUnit?.split('/')[1];
  const hasAnyPromotion = hybrisProduct?.potentialPromotions?.[0] || hybrisProduct?.inactivePotentialPromotions?.[0];
  const promotion = hasPromotion ? mapPromotion(hybrisProduct, priceUnit) : undefined;

  const hasInactivePromotion =
    'inactivePotentialPromotions' in hybrisProduct && !!hybrisProduct.inactivePotentialPromotions?.[0];
  const inactivePromotion = hasInactivePromotion ? mapInactivePromotions(hybrisProduct) : undefined;

  const actualCustomerPrice: number = getActualCustomerPrice(hybrisProduct);
  const pickUnitName = getPickUnitName({
    productBasketType: typeof hybrisProduct.productBasketType !== 'string' ? hybrisProduct.productBasketType : undefined,
    pickUnit: 'pickUnit' in hybrisProduct ? (hybrisProduct.pickUnit as UnitData) : undefined,
  });

  const priceValue = hybrisProduct.priceValue;
  const hasPrice = actualCustomerPrice >= 0;

  const comparePrice: ComparePrice = mapComparePrice(hybrisProduct.comparePrice || '', hybrisProduct.comparePriceUnit);

  const formattedPrice: ComparePrice = mapComparePrice(hybrisProduct?.price, priceUnit);

  const price: Price = {
    actualCustomerPrice,
    unit: priceUnit || '',
    pickUnitName,
    value: priceValue || 0,
    hasPrice,
    formattedPrice,
    comparePrice,
    priceNoUnit:
      typeof hybrisProduct.priceNoUnit === 'number' ? hybrisProduct.priceNoUnit.toString() : hybrisProduct.priceNoUnit,
    depositPrice: hybrisProduct.depositPrice || undefined,
  };
  const analytics: Analytics = {
    impressionObject: productToImpressionObject(hybrisProduct),
  };

  const productAltText = hybrisProduct.manufacturer
    ? `${hybrisProduct.manufacturer} ${hybrisProduct.name}`
    : hybrisProduct.name;

  const image = 'image' in hybrisProduct ? hybrisProduct.image : undefined;

  const hasComparePrice =
    !!price?.comparePrice?.value && !!price.comparePrice.unit && (!hasPromotion || !!promotion?.isAlwaysGoodPrice);

  const hasPromotionComparePrice =
    hasPromotion &&
    !promotion?.isPromotionComparePriceSame &&
    !promotion?.isSingelProductAlwaysGoodPricePromotionProduct &&
    !!promotion?.promotionComparePrice?.value;

  return {
    isNewProductModel: true,
    code: hybrisProduct.code,
    name: hybrisProduct.name,
    online: hybrisProduct.online,
    offline: !hybrisProduct.online,
    displayVolume: hybrisProduct.displayVolume,
    manufacturer: hybrisProduct.manufacturer || '',
    outOfStock: !!hybrisProduct.outOfStock,
    incrementValue: hybrisProduct.incrementValue || 0,
    thumbnail: getThumbnail(hybrisProduct),
    hasComparePrice,
    price,
    promotion,
    hasPromotion,
    hasPromotionComparePrice,
    analytics,
    isDrugProduct: !!hybrisProduct.isDrugProduct,
    nicotineMedicalProduct: !!hybrisProduct.nicotineMedicalProduct,
    labels: hybrisProduct?.labels || [],
    warningLabel: handleWarningLabel(hybrisProduct as any),
    productAltText,
    image,
    isBargainProduct: !!('bargainProduct' in hybrisProduct && hybrisProduct.bargainProduct),
    isNewsSplashProduct: !!('newsSplashProduct' in hybrisProduct && hybrisProduct.newsSplashProduct),
    previewUrl: hasPromotion ? getPreviewUrl(hybrisProduct, firstPromotion) : '',
    productUrl: getProductUrl(hybrisProduct),
    energyDeclaration: 'energyDeclaration' in hybrisProduct ? hybrisProduct.energyDeclaration : undefined,
    saleableOnline: 'salableOnline' in hybrisProduct && hybrisProduct.salableOnline,
    hasInactivePromotion,
    inactivePromotion,
    hasAnyPromotion,
  };
};
const mapHybrisProducts = (products: any): Product[] => {
  return products.map((product: any) => mapHybrisProduct(product));
};
export default mapHybrisProducts;
