import Heading from '@components/atoms/Heading';
import PromoPanel from '@components/cmsComponents/AxfoodProductCarouselComponent/PromoPanel';
import Carousel from '@components/molecules/Carousel';
import { PromoListItem } from '@components/molecules/Carousel/CarouselListItem';
import CarouselProductsWithTracking
  from '@components/organisms/ProductsWithTracking/CarouselProductsWithTracking/CarouselProductsWithTracking';
import useEventListName from '@hooks/useEventListName';
import { HeaderRow, ProductCarouselWrapper } from './AxfoodProductCarouselComponent.styles';
import useCarouselComponent from './useCarouselComponent';

export interface AxfoodProductCarouselComponentProps {
  component: AxfoodProductCarouselComponentType;
}

const AxfoodProductCarouselComponent = ({ component }: AxfoodProductCarouselComponentProps) => {
  const {
    formattedProducts,
    isLoading,
    title = '',
    loadMore,
    isValidating,
    hasMorePages,
  } = useCarouselComponent(component.uid);
  const { EVENT_LIST_NAME } = useEventListName(title);

  const showPromoPanel = !!component.promoComponent;

  if (!formattedProducts.length) {
    return null;
  }

  return (
    <ProductCarouselWrapper data-testid="product-carousel">
      <HeaderRow>
        <Heading size="small" type="h2">
          {title}
        </Heading>
        {/** TODO needs BE change in B2C-31211 */}
        {/* {!!formattedProducts.length && (
          <AmountOfProducts>{t('productCount', { count: totalProducts })}</AmountOfProducts>
        )} */}
      </HeaderRow>
      <Carousel hasMoreItems={hasMorePages} isFetchingMoreData={isValidating}>
        {showPromoPanel && (
          <PromoListItem>
            <PromoPanel component={component.promoComponent as AxfoodPromoBannerComponentData} />
          </PromoListItem>
        )}
        <CarouselProductsWithTracking
          products={formattedProducts}
          eventListName={EVENT_LIST_NAME}
          isLoading={isLoading}
          loadMore={loadMore}
        />
      </Carousel>
    </ProductCarouselWrapper>
  );
};

export default AxfoodProductCarouselComponent;
