export const BREAKPOINTS = {
  xxxs: 320,
  xxs: 375,
  xs: 620,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
  wide: 1921,
};

export const media = {
  smallMobile: `@media screen and (min-width: ${BREAKPOINTS.xxxs}px)`,
  mobile: `@media screen and (min-width: ${BREAKPOINTS.xxs}px)`,
  largeMobile: `@media screen and (min-width: ${BREAKPOINTS.xs}px)`,
  tabletPortrait: `@media screen and (min-width: ${BREAKPOINTS.sm}px), print`, // also used for print
  tabletLandscape: `@media screen and (min-width: ${BREAKPOINTS.md}px)`,
  desktop: `@media screen and (min-width: ${BREAKPOINTS.lg}px)`,
  desktopXL: `@media screen and (min-width: ${BREAKPOINTS.xl}px)`,
  desktopXXL: `@media screen and (min-width: ${BREAKPOINTS.xxl}px)`,
  desktopWide: `@media screen and (min-width: ${BREAKPOINTS.wide}px)`,
};

export type Screen = keyof typeof media;

export default media;
