/**
 * Source: https://usehooks.com/useDebounce/
 *
 * This hook allows you to debounce any fast changing value. The debounced value will only reflect
 * the latest value when the useDebounce hook has not been called for the specified time period.
 *
 * @param value
 * @param delay: debounce time in ms
 */
import { useCallback, useEffect, useRef, useState } from 'react';

const useDebounce = <V>(value: V, delay: number): V => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // ... within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

/**
 * Returns a debounced version of the function that delays its execution until after
 * the specified delay has passed since the last time it was invoked. The debounced
 * function returns a promise that resolves with the result of the function execution.
 */
// eslint-disable-next-line fp/no-rest-parameters
export const useDebounceFn = <F extends (...args: any[]) => any>(fn: F, delay: number): ((...args: Parameters<F>) => Promise<ReturnType<F>>) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // eslint-disable-next-line fp/no-rest-parameters
  const debouncedFunction = useCallback((...args: Parameters<F>) => {
    return new Promise<ReturnType<F>>((resolve) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // eslint-disable-next-line fp/no-mutation
      timeoutRef.current = setTimeout(() => {
        const result = fn(...args);
        resolve(result);
      }, delay);
    });
  }, [fn, delay]);

  return debouncedFunction;
};

export default useDebounce;
