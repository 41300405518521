import { closeDeliveryModal } from '@actions/delivery';
import { URLPREFIXES } from '@constants/links';
import { pageview } from '@helpers/analytics';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { selectDeliveryModalIsVisible } from '@selectors/delivery';
import { selectLoginModalIsVisible } from '@selectors/login';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

const useOnRouteChange = () => {
  const dispatch = useAppDispatch();
  const { hasFetchedCustomer } = useCustomer();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const isDeliveryModalVisible = useAppSelector(selectDeliveryModalIsVisible);
  const isLoginModalVisible = useAppSelector(selectLoginModalIsVisible);

  useEffect(() => {
    if (hasFetchedCustomer && pathname) {
      pageview(pathname);
    }
  }, [hasFetchedCustomer, pathname]);

  useEffect(() => {
    const isDeliveryMethodInPath = pathname?.includes(URLPREFIXES.DELIVERY_METHOD);
    if (!isDeliveryMethodInPath && isDeliveryModalVisible) {
      dispatch(closeDeliveryModal());
    }
  }, [dispatch, isDeliveryModalVisible, isLoginModalVisible, pathname, searchParams]);
};

export default useOnRouteChange;
