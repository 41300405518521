import {
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodProductDetailsViewModel,
  EnergyDeclaration,
  ImageData,
} from '@api/generated/storefront';
import PICK_UNIT from '@constants/pickUnit';

export type HybrisProduct = AxfoodBasicProductViewModel | AxfoodProductDataModel | AxfoodProductDetailsViewModel;

export interface ComparePrice {
  value: string; // 3,00 kr
  unit?: string; // st
  valueWithUnit?: string; // 3,00 kr/st
}

export interface Price {
  actualCustomerPrice: number;
  unit: string;
  pickUnitName: typeof PICK_UNIT.KILOGRAM | typeof PICK_UNIT.PIECES;
  value: number;
  hasPrice: boolean;
  formattedPrice: ComparePrice;
  comparePrice: ComparePrice;
  priceNoUnit?: string;
  depositPrice?: string;
}

export interface Promotion {
  isMixAndMatch: boolean;
  isMixAndMatchPercentage: boolean;
  isPercentage: boolean;
  isAlwaysGoodPrice: boolean;
  isLoyalty: boolean;
  showPromotionPrice: boolean;
  promotionComparePrice: ComparePrice;
  qualifyingCount: number;
  campaignType: string;
  textLabelGenerated: string;
  textLabel: string;
  redeemLimitLabel: string;
  lowestHistoricalPrice?: ComparePrice;
  hasLoyaltyPromotion: boolean;
  hasSegmentedPromotion: boolean;
  isPriceBomb: boolean;
  conditionLabel: string;
  rewardLabel: string;
  conditionLabelFormatted: string;
  isPromotionUsed: boolean;
  productCode: string;
  productCodes: string[];
  promotionCode: string;
  showOrdinaryPrice: boolean;
  isPromotionComparePriceSame: boolean;
  endDate: string;
  isSingelProductAlwaysGoodPricePromotionProduct: boolean;
}

export type InactivePromotion = Pick<
  Promotion,
  | 'conditionLabel'
  | 'conditionLabelFormatted'
  | 'rewardLabel'
  | 'isPriceBomb'
  | 'hasSegmentedPromotion'
  | 'hasLoyaltyPromotion'
  | 'isAlwaysGoodPrice'
  | 'isLoyalty'
  | 'qualifyingCount'
  | 'textLabelGenerated'
  | 'textLabel'
  | 'promotionCode'
>;

export interface Impression {
  id: string;
  name: string;
  brand: string;
  price: number;
  category: string;
}

export interface Analytics {
  impressionObject: Impression;
}

export type WarningLabel = 'NONE' | 'NICOTINE' | 'GENERAL_ERROR' | 'NOTSELLABLE' | 'TOBACCO' | 'NICOTINE_SNUFF';

export interface Product {
  isNewProductModel: boolean;
  code: string;
  name: string;
  hasPromotion: boolean;
  hasComparePrice: boolean;
  hasPromotionComparePrice: boolean;
  online: boolean;
  offline: boolean;
  displayVolume?: string;
  manufacturer?: string;
  outOfStock: boolean;
  incrementValue: number;
  thumbnail: string;
  isDrugProduct: boolean;
  nicotineMedicalProduct: boolean;
  warningLabel: WarningLabel;
  labels: Array<string>;
  price: Price;
  promotion?: Promotion;
  analytics: Analytics;
  productAltText: string;
  image?: ImageData;
  isBargainProduct: boolean;
  isNewsSplashProduct: boolean;
  previewUrl: string;
  productUrl: string;
  energyDeclaration?: EnergyDeclaration;
  saleableOnline: boolean;
  hasInactivePromotion: boolean;
  inactivePromotion?: InactivePromotion;
  hasAnyPromotion: boolean;
}

export const isProduct = (
  product: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel | Product
): product is Product => {
  return 'isNewProductModel' in product;
};
