import {
  FacetDataOfSearchStateData,
  RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel,
} from '@api/generated/storefront';
import LINKS from '@constants/links';

export const formatImgUrl = (url: string, w = '240', h = '220', device = 'desktop') => {
  return url.replace('{w}', w).replace('{h}', h).replace('{device}', device);
};

export const getSelectedFilters = (facets: FacetDataOfSearchStateData[] | undefined) => {
  return facets ? facets.flatMap((f) => f.values).filter((v) => v?.selected) : [];
};

export const getSearchQuery = (filter: string): string =>
  (filter.substring(0, 1) !== ':' &&
    filter.substring(0, 1) !== '*' &&
    filter.substring(0, filter.indexOf(':') > 0 ? filter.indexOf(':') : filter.length)) ||
  '';

export const getItemListElements = (
  recipeResults: RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel['results'], apiUrl: string
) => {
  const itemListElement = recipeResults?.map((recipe, index) => ({
    '@type': 'ListItem',
    url: `${apiUrl}${LINKS.RECIPE}${recipe.id}`,
    position: index + 1,
  }));

  return itemListElement;
};

export const recipeSearchStructuredData = (
  recipes: RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel,
  asPath: string,
  numberOfItems: string,
  apiUrl: string
) => {
  const itemListElement = recipes?.results?.map((recipe, index) => ({
    '@type': 'ListItem',
    url: `${apiUrl}${LINKS.RECIPE}${recipe.id}`,
    position: index + 1,
  }));

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    url: `${apiUrl}${asPath}`,
    numberOfItems,
    itemListElement,
  };
  return structuredData;
};
