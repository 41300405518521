import { BreadcrumbDataOfSearchStateData } from '@api/generated/storefront';
import Tablist, { Tab } from '@components/molecules/Tablist';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  selectedFilters: BreadcrumbDataOfSearchStateData[];
  onFilterClick: (query: string) => void;
}

const SelectedFilters = ({ selectedFilters, onFilterClick }: Props) => {
  const { t } = useTranslation('productFiltering');

  if (!selectedFilters.length) {
    return null;
  }

  const list: Tab[] = selectedFilters.map((facet) => {
    const { facetValueName = '', removeQuery, facetCode } = facet;
    const filterText = facetCode === 'promotionFilter' ? t('offersLabel') : facetValueName;
    return {
      content: filterText,
      onClick: () => onFilterClick(removeQuery?.query?.value ?? ''),
      selected: true,
      key: filterText,
    };
  });

  return <Tablist list={list} />;
};

export default SelectedFilters;
