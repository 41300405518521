import { extractCloudinaryCloudName } from '@features/recipes/utils/cloudinary';
import { CldImage, CldImageProps } from 'next-cloudinary';

type CloudinaryImageProps = Omit<CldImageProps, 'config'>;

const CloudinaryImage = (props: CloudinaryImageProps) => {
  const cloudName = extractCloudinaryCloudName(props.src);
  if (!cloudName) {
    return null;
  }
  return (
    <CldImage
      {...props}
      config={{
        cloud: {
          cloudName,
        },
        url: { analytics: false },
      }}
    />
  );
};

export default CloudinaryImage;
