import { useAppSelector } from '@hooks/redux';
import usePrevious from '@hooks/usePrevious';
import { selectLoginModalIsVisible } from '@selectors/login';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import useHandleLoginClose from './useHandleLoginClose';

const useCloseLoginModalOnRouteChange = () => {
  const pathname = usePathname() ?? '';
  const prevPathname = usePrevious(pathname);
  const handleLoginClose = useHandleLoginClose();
  const loginModalIsVisible = useAppSelector(selectLoginModalIsVisible);

  useEffect(() => {
    if (!loginModalIsVisible) {
      return; // do nothing if login modal is not visible
    }

    // TODO: This condition is needed during migration from next/router to next/navigation since usePathname is not ready on first render with next/router
    if (!prevPathname) {
      return; // do nothing if event was triggered by page router isReady
    }

    if (prevPathname !== pathname) {
      handleLoginClose({ loginConfirmSuccess: false });
    }
  }, [pathname, prevPathname, handleLoginClose, loginModalIsVisible]);
};

export default useCloseLoginModalOnRouteChange;
