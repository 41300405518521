import { getPromotionProducts } from '@api';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useAssortmentKey from '@hooks/useAssortmentKey';
import useCustomer from '@hooks/useCustomer';
import useSWR, { SWRConfiguration } from 'swr';

export const ENDPOINT = 'getPromotionProductsUsingGET';

type KeyType = {
  customerId: string;
  activeStoreId?: string;
  promotionCode: string;
  excludeProducts: Array<string>;
};

const fetcher = ({ promotionCode, excludeProducts }: KeyType) => {
  return getPromotionProducts({
    promotionCode,
    excludeProducts,
  });
};

const options: SWRConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
};

const useMixAndMatchPromotion = (promotionCode: string, excludeProducts: Array<string>) => {
  const { customerId, customerFetchIsRequired } = useCustomer();
  const assortmentKey = useAssortmentKey();

  const { data, isLoading } = useSWR(
    customerFetchIsRequired && assortmentKey
      ? {
          endpoint: ENDPOINT,
          customerId,
          promotionCode,
          excludeProducts,
          ...assortmentKey,
        }
      : null,
    fetcher,
    options
  );
  return {
    products: data?.products || [],
    isLoading,
  };
};

export default useMixAndMatchPromotion;
