import Pill from '@components/atoms/Pill';
import { Key, ReactNode } from 'react';
import { TablistWrapper } from './Tablist.styles';

export type Tab = {
  content: ReactNode;
  onClick: () => void;
  selected: boolean;
  key: Key;
  disabled?: boolean;
};
interface Props {
  list: Tab[];
  children?: ReactNode;
  className?: string;
}

const Tablist = ({ list, children = null, className }: Props) => {
  return (
    <TablistWrapper role="tablist" className={className}>
      {list.map(({ selected, onClick, content, key, disabled = false }) => (
        <Pill key={key} selected={selected} onClick={onClick} disabled={disabled}>
          {content}
        </Pill>
      ))}
      {children}
    </TablistWrapper>
  );
};

export default Tablist;
