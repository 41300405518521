/* eslint-disable react/no-array-index-key */
import { IconType } from '@components/atoms/Icon/Icon';
import Trans from 'next-translate/Trans';
import React, { useEffect, useState } from 'react';
import { Container, List, NoResultContainer } from './Autocomplete.styles';
import AutocompleteItem from './AutocompleteItem';

export interface Props {
  searchTerm: string;
  suggestions: Array<Suggestion>;
  suggestionIndex: number;
  setSuggestionText?: (text: string) => void;
  className?: string;
  onClick: (term?: string, suggestion?: Suggestion) => void;
  highlightOnHover?: boolean;
  boldSearchTermSubstring?: boolean;
  iconName?: IconType;
  scrollItem?: boolean;
  showNoResultText?: boolean;
}

const Autocomplete = ({
  searchTerm,
  suggestions,
  suggestionIndex,
  setSuggestionText,
  className,
  onClick,
  highlightOnHover = false,
  boldSearchTermSubstring = false,
  iconName,
  scrollItem = false,
  showNoResultText = false,
}: Props) => {
  const [refSuggestion, setRefSuggestion] = useState<HTMLLIElement>();

  useEffect(() => {
    if (!!refSuggestion && setSuggestionText) {
      setSuggestionText(refSuggestion.getAttribute('data-value') || '');
    }
  }, [suggestionIndex, refSuggestion, setSuggestionText]);

  return (
    <Container className={className}>
      <List>
        {suggestions.length === 0 && showNoResultText && (
          <NoResultContainer>
            <Trans
              i18nKey="store:findStore->autoCompleteNoResultText"
              values={{
                searchTerm,
              }}
            />
          </NoResultContainer>
        )}
        {suggestions.map((suggestion, i) => {
          return (
            <AutocompleteItem
              key={`${suggestion.term}-${i}`}
              onClick={onClick}
              searchTerm={searchTerm}
              boldSearchTermSubstring={boldSearchTermSubstring}
              focus={suggestionIndex === i}
              index={i}
              searchableTerm={suggestion.term}
              suggestionIndex={suggestionIndex}
              highlightOnHover={highlightOnHover}
              suggestion={suggestion}
              setRefSuggestion={setRefSuggestion}
              shouldShowItemIcon={'shouldShowItemIcon' in suggestion && !!suggestion.shouldShowItemIcon}
              iconName={iconName}
              disabled={'disabled' in suggestion && !!suggestion.disabled}
              scrollItem={scrollItem}
              alternativeDisplayTerm={suggestion.displayName ? suggestion.displayName : undefined}
            />
          );
        })}
      </List>
    </Container>
  );
};

export default React.memo(Autocomplete);
