import Icon from '@components/atoms/Icon';
import Tooltip from '@components/atoms/Tooltip';
import IconInformation from '@public/icons/regularIcons/icon-information.svg';
import IconMinus from '@public/icons/regularIcons/icon-minus.svg';
import IconPlus from '@public/icons/regularIcons/icon-plus.svg';
import useTranslation from 'next-translate/useTranslation';
import { PlusButton, RoundButton, TooltipBackground } from './Quantity.styles';
const iconSize = 24;

interface Props {
  onClick: () => void;
  disabled?: boolean;
}
const DecreaseButton = ({ onClick, disabled = false }: Props) => {
  const { t } = useTranslation('common');
  return (
    <RoundButton
      aria-label={t('ariaLabels->decrement')}
      data-testid="minus-button"
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="minus-button"
    >
      <Icon size={iconSize} icon={IconMinus} color={disabled ? 'white' : 'red'} data-testid="icon-left" />
    </RoundButton>
  );
};

export const IncreaseButton = ({ onClick, disabled = false }: Props) => {
  const { t } = useTranslation('common');
  return (
    <PlusButton
      aria-label={t('ariaLabels->increment')}
      data-testid="plus-button"
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="plus-button"
    >
      <Icon size={iconSize} icon={IconPlus} color="white" data-testid="icon-right" />
    </PlusButton>
  );
};

export const InfoButton = ({ onClick, disabled = false }: Props) => {
  const { t } = useTranslation('common');
  return (
    <RoundButton
      aria-label={t('ariaLabels->info')}
      data-testid="info-button"
      type="button"
      onClick={onClick}
      className="info-button"
    >
      <Icon size={iconSize} icon={IconPlus} color={disabled ? 'white' : 'red'} data-testid="icon-right" />
    </RoundButton>
  );
};

export const ChangeOrderIncreaseNotAllowedTooltip = () => {
  const { t } = useTranslation('product');
  return (
    <TooltipBackground>
      <Tooltip
        iconName={IconInformation}
        toolTipText={t('common:ariaLabels->about', {
          label: t('increaseNotAllowed'),
        })}
        showToolTipText={false}
      >
        {t('changeOrderIncreaseNotAllowedTooltip')}
      </Tooltip>
    </TooltipBackground>
  );
};

export default DecreaseButton;
