import getComponent from '@api/cms/getComponent';
import logError from '@helpers/logHelpers';
import axios from 'axios';
import { ServerResponse } from 'http';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

const useGlobalMessages = (slotComponents: Array<CmsComponentTypes>) => {
  const [currentMessages, setCurrentMessages] = useState<Array<GlobalMessage>>([]);
  const searchParams = useSearchParams();
  const cmsTicketId = searchParams?.get('cmsTicketId') || undefined;
  const LOCAL_STORAGE_PREFIX = 'hemkop.CmsNotification-';
  const source = axios.CancelToken.source();

  const stripOfBracketsAndSpaces = (element: string): string => {
    return element.replace(/[[\]"]+/g, '');
  };

  const hasMessageBeenChecked = (component: CmsComponentTypes) => {
    const localStorageTitle = `${LOCAL_STORAGE_PREFIX}${component.uid}`;
    return JSON.parse(localStorage?.getItem(localStorageTitle) as string) === true;
  };

  const updateState = (messagesToBeRemoved: Array<GlobalMessage>) => {
    setCurrentMessages(
      currentMessages.filter(
        (message: GlobalMessage) =>
          !messagesToBeRemoved.find((flaggedMessage: GlobalMessage) => flaggedMessage.uid === message.uid)
      )
    );
  };

  const setMessageAsChecked = (message: GlobalMessage) => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${message.parentUid}`, JSON.stringify(true));
    updateState([message]);
  };

  const addParentUids = (messages: Array<GlobalMessage>) => {
    return messages.map((message: GlobalMessage) => {
      const parentComponent = slotComponents.find((component: CmsComponentTypes) => {
        if (component.components) {
          return stripOfBracketsAndSpaces(component.components) === message.uid;
        }
        return false;
      });

      if (parentComponent) {
        // eslint-disable-next-line no-param-reassign
        message.parentUid = parentComponent.uid || '';
        return message;
      }

      return message;
    });
  };

  const getSingleComponentData = async (component: string) => {
    let cleanedComponentIdentifier = stripOfBracketsAndSpaces(component);
    cleanedComponentIdentifier = encodeURIComponent(cleanedComponentIdentifier);
    return getComponent(cleanedComponentIdentifier, cmsTicketId, source.token);
  };

  const setUpRequestsForAllComponents = (): Promise<ServerResponse>[] => {
    const requestArray: Array<any> = [];
    slotComponents.forEach((component) => {
      if (component.components && !hasMessageBeenChecked(component)) {
        requestArray.push(getSingleComponentData(component.components));
      }
    });
    return requestArray;
  };

  const fetchData = async (requestArray: Promise<ServerResponse>[]) => {
    try {
      const responses: Array<any> = await Promise.allSettled(requestArray);
      const filteredResponses: Array<any> = responses.filter((response) => response.status !== 'rejected');
      return filteredResponses.map((response) => response.value.data) as Array<GlobalMessage>;
    } catch (e) {
      logError('Could not get cms content slot data for global messages', e);
      return [] as Array<GlobalMessage>;
    }
  };

  const setupGlobalMessages = async () => {
    try {
      const requests = setUpRequestsForAllComponents();
      const messages: Array<GlobalMessage> = await fetchData(requests);
      const messagesWithUids = addParentUids(messages);
      setCurrentMessages(messagesWithUids);
    } catch (e) {
      logError('Could not setup global messages', e);
    }
  };

  useEffect(() => {
    !!slotComponents?.length && setupGlobalMessages();
  }, [slotComponents?.length]);

  return {
    currentMessages,
    setMessageAsChecked,
  };
};

export default useGlobalMessages;
