import useTranslation from 'next-translate/useTranslation';
import { ForwardedRef, forwardRef, memo } from 'react';
import { InputField, InputWrapper, Label } from './Quantity.styles';
import { QuantityUnit } from './types';

interface InputFieldProps {
  onLabelClick: () => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  quantityValue: string;
  unit: QuantityUnit;
  disabled?: boolean;
  minValue: number;
  maxValue: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  step?: number;
}

const QuantityInputField = forwardRef(
  (
    {
      onLabelClick,
      unit,
      disabled,
      onChange,
      onBlur,
      onFocus,
      onKeyUp,
      quantityValue,
      minValue,
      maxValue,
      step,
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation('common');

    return (
      <InputWrapper data-testid="input-wrapper">
        <InputField
          disabled={disabled}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          value={quantityValue}
          $width={quantityValue?.toString().length || 0}
          aria-disabled={disabled}
          data-testid="quantity-input"
          min={minValue}
          max={maxValue}
          step={step}
        />
        <Label onClick={onLabelClick}>{t(`units->${unit}`)}</Label>
      </InputWrapper>
    );
  },
);

// eslint-disable-next-line fp/no-mutation
QuantityInputField.displayName = 'QuantityInputField';

export default memo(QuantityInputField);
