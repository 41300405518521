import useOnClickOutside from '@hooks/useOnClickOutside';
import useRoveFocus from '@hooks/useRoveFocus';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import IconArrowUp from '@public/icons/regularIcons/icon-arrow-up.svg';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Icon from '../Icon';
import { ExpandedWrapper, OptionName, SelectedText, SortsOnText, StyledOption, Wrapper } from './Dropdown.styles';
import { useSearchParams } from 'next/navigation';

export interface Option {
  code?: string;
  name?: string;
  selected?: boolean;
}

interface Props {
  id: string;
  selectedName: string;
  options: Array<Option>;
  onOptionClick: (option: Option) => void;
}

const DropDown = ({ options, selectedName, onOptionClick, id }: Props) => {
  const [isExpanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { setCurrentFocus, currentFocus } = useRoveFocus(options.length, containerRef.current);
  const { t } = useTranslation('common');
  const [popperElement, setPopperElement] = useState(null);

  const searchParams = useSearchParams();
  const sort = searchParams?.get('sort')

  useEffect(() => {
    const selectedIndex = options.findIndex((option) => option.code === sort);
    if (currentFocus !== selectedIndex) {
      setCurrentFocus(selectedIndex);
    }
  }, [sort]);

  const { styles, attributes } = usePopper(containerRef.current, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  useOnClickOutside(containerRef, () => {
    setExpanded(false);
  });

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && currentFocus !== -1) {
      onOptionClick(options[currentFocus]);
      setExpanded(false);
    }
    if (e.key === 'Escape') {
      setExpanded(false);
    }

    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key) && !isExpanded) {
      setExpanded(true);
    }
  };

  const label = `${t('defaultActions->sortOn')} ${selectedName}`;
  return (
    <div ref={containerRef}>
      <Wrapper
        tabIndex={0}
        onClick={() => setExpanded(!isExpanded)}
        onKeyDown={onKeyDown}
        aria-label={label}
        aria-haspopup="listbox"
        aria-controls={id}
        aria-expanded={isExpanded}
        role="combobox"
        aria-activedescendant={isExpanded ? selectedName : undefined}
        data-testid="sort-dropdown"
      >
        <SortsOnText>{t('defaultActions->sortOn')}&nbsp;</SortsOnText>
        <SelectedText>{selectedName}</SelectedText>
        <Icon icon={isExpanded ? IconArrowUp : IconArrowDown} size={16} />
      </Wrapper>
      {isExpanded && (
        <ExpandedWrapper
          id={id}
          aria-label={label}
          ref={setPopperElement as any}
          style={styles.popper}
          {...attributes.popper}
          role="listbox"
        >
          {options.map((option, index) => (
            <StyledOption
              role="option"
              key={option.code}
              id={option.name}
              aria-selected={option.selected}
              $currentFocus={currentFocus === index}
              onClick={() => {
                setCurrentFocus(index);
                onOptionClick(option);
                setExpanded(false);
              }}
            >
              <OptionName>{option.name}</OptionName>
            </StyledOption>
          ))}
        </ExpandedWrapper>
      )}
    </div>
  );
};

export default DropDown;
