import Badge from '@components/atoms/Badge';
import Icon, { IconType } from '@components/atoms/Icon/Icon';
import { usePathname } from 'next/navigation';
import { BadgeWrapper, Container, StyledMenuButton, StyledMenuLink, StyledParagraph } from './AccountMenuItem.styles';

export declare type MenuLink = {
  url?: string;
  title: string;
  color?: ColorType;
  icon?: {
    svg: IconType;
    color: ColorType;
  };
  onClick: () => void;
  badgeValue?: number;
};

export interface AccountMenuListItemProps {
  link: MenuLink;
  onLinkClicked?: () => void;
  className?: string;
  'data-testid'?: string;
  'data-cy'?: string;
}

const AccountMenuItem = ({
  link,
  onLinkClicked,
  className,
  'data-testid': dataTestid,
  'data-cy': dataCy,
}: AccountMenuListItemProps) => {
  const pathname = usePathname();

  const isLinkActive = pathname === link.url;

  const commonProps = {
    className,
    key: link.title,
    onClick: () => {
      link.onClick();
      onLinkClicked && onLinkClicked();
    },
    'data-testid': dataTestid,
    'data-cy': dataCy,
  };

  const menuItemContent = (
    <>
      <Container>
        {link.icon && <Icon icon={link.icon.svg} color={isLinkActive ? 'red' : 'black'} />}
        <StyledParagraph color={isLinkActive ? 'primary' : 'black'} weight="medium">
          {link.title}
        </StyledParagraph>
      </Container>
      <BadgeWrapper>
        <Badge value={link.badgeValue} color="red" />
      </BadgeWrapper>
    </>
  );

  return link.url ? (
    <StyledMenuLink {...commonProps} href={link.url}>
      {menuItemContent}
    </StyledMenuLink>
  ) : (
    <StyledMenuButton {...commonProps}>{menuItemContent}</StyledMenuButton>
  );
};

export default AccountMenuItem;
