import { headerId } from '@constants/globalElementIds';

const getContentPositioningInfo = () => {
  const headerHeight = document.getElementById(headerId)?.getBoundingClientRect()?.bottom || 0;
  const viewportTopInDocument = window?.scrollY || 0;

  return { viewportTopInDocument, headerHeight };
};

export default getContentPositioningInfo;
