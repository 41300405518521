import CloudinaryImage from '@components/atoms/CloudinaryImage';
import UnoptimizedImage from '@components/atoms/UnoptimizedImage/UnoptimizedImage';
import { grayPixel } from '@features/recipes/utils/cloudinary';
import { formatImgUrl } from '../helpers';
interface Props {
  imageUrl: string;
  altText: string;
}

const imageSize = { width: '376', height: '220' };

const RecipeCardImage = ({ imageUrl, altText }: Props) => {
  const isCloudinary = imageUrl.includes('cloudinary');

  if (!isCloudinary) {
    return (
      <UnoptimizedImage
        src={formatImgUrl(imageUrl, imageSize.width, imageSize.height)}
        alt={altText}
        style={{ objectFit: 'cover', objectPosition: 'center' }}
        fill
        priority={true}
        blurDataURL={grayPixel}
        placeholder="blur"
      />
    );
  }

  return (
    <CloudinaryImage
      loading="lazy"
      src={imageUrl}
      alt={altText}
      fill
      style={{ objectFit: 'cover', objectPosition: 'center' }}
      sizes="(max-width: 619px) 50vw, (max-width: 1023px) 33vw, (max-width: 1439px) 25vw, 376px"
      blurDataURL={grayPixel}
      placeholder="blur"
    />
  );
};

export default RecipeCardImage;
