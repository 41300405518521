import ProductGrid from '@components/atoms/ProductGrid';
import Spinner from '@components/atoms/Spinner';
import { Stack } from '@components/atoms/Stack';
import CategoryInfo from '@components/molecules/CategoryInfo/CategoryInfo';
import NoResults from '@components/molecules/NoResults';
import { CategoryLinksWithShowMoreButton } from '@components/organisms/CategoryLinks';
import ProductListFilters from '@components/organisms/ProductListFilters';
import ProductsWithTracking from '@components/organisms/ProductsWithTracking';
import { getFormattedProductsCategoryAppliedFilters } from '@helpers/productHelper';
import { flattenString } from '@helpers/string';
import useAppRouter from '@hooks/useAppRouter';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import useFeature from '@hooks/useFeature';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import useTranslation from 'next-translate/useTranslation';
import { RefObject, useState } from 'react';
import { Bottom } from './CategoryProductsComponent.styles';
import ImageGridComponent from './ImageGridComponent';
import useCategory from './useCategory';

interface Props {
  data: CategoryProductsComponentType;
}

const isImageGridComponent = (data: unknown): data is ImageGridComponentType => {
  const hasData = !!data && typeof data === 'object';
  const hasImage = hasData && 'image' in data && !!data?.image;
  const hasTitle = hasData && 'title' in data && !!data?.title;
  return hasImage && hasTitle;
};

const CategoryProductsComponent = ({ data }: Props) => {
  const { query } = useAppRouter();
  const { t } = useTranslation('categorypage');

  const {
    products,
    breadcrumbs,
    sorts,
    facets,
    subCategories,
    restrictedProductTypes,
    isValidating: isFetching,
    isLoading,
    loadMore,
    totalNumberOfResults,
    categoryInfo,
  } = useCategory({
    categoryPath: flattenString(query?.categories),
  });

  const imageGridComponentEnabled = useFeature('FEATURE_ENABLE_IMAGE_GRID_COMPONENT_FL');

  const componentIds = JSON.stringify(data?.gridPackages?.replace(/[[\]"]+/g, ''));
  const { data: gridPackages = [] } = useCmsComponents(componentIds);

  const [gridComponent] = gridPackages;

  const imageGridComponentData = isImageGridComponent(gridComponent) ? gridComponent : undefined;

  const infiniteScrollRef = useInfiniteScroll(loadMore);

  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const handleRef = (ref: HTMLDivElement) => setGridRef(ref);

  const EXTENDED_EVENT_LIST_NAME = getFormattedProductsCategoryAppliedFilters(query);
  const EVENT_LIST_NAME = `/sortiment | ${categoryInfo?.name}`;
  const noProducts = !products || !products.length;

  return (
    <div data-testid="category-page-wrapper">
      <Stack $space="smallMedium">
        <CategoryLinksWithShowMoreButton categories={subCategories} showSkeletons={isLoading} buttonSize="md" />
        <ProductListFilters
          facets={facets || []}
          sorts={sorts || []}
          pageName="category_page"
          amountOfProducts={totalNumberOfResults}
          selectedFilters={breadcrumbs || []}
        />
        {!isLoading && noProducts ? (
          <NoResults noHitsCopy={t('noProductsToShow')} />
        ) : (
          <>
            <ProductGrid passRef={handleRef}>
              <CategoryInfo restrictedProductTypes={restrictedProductTypes} />
              {imageGridComponentEnabled && imageGridComponentData ? (
                <ImageGridComponent component={imageGridComponentData} />
              ) : null}
              <ProductsWithTracking
                products={products}
                eventListName={EVENT_LIST_NAME}
                extendedEventListName={EXTENDED_EVENT_LIST_NAME !== '' ? EXTENDED_EVENT_LIST_NAME : undefined}
                containerRef={gridRef}
                isLoading={isLoading}
              />
            </ProductGrid>
            <Bottom ref={infiniteScrollRef as RefObject<HTMLDivElement>}>{isFetching && <Spinner color="red" />}</Bottom>
          </>
        )}
      </Stack>
    </div>
  );
};

export default CategoryProductsComponent;
