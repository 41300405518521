import { Count } from '@components/atoms/Badge/Badge.styles';
import LinkArea from '@components/atoms/LinkArea';
import Paragraph from '@components/atoms/Paragraph';
import { insetFocusBoxShadow } from '@styles/utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const baseStyles = css`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)} ${rem(16)};
  border: none;
  min-height: ${rem(56)};

  &:focus-visible {
    ${insetFocusBoxShadow};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorLinen};
  }
`

export const StyledMenuLink = styled(LinkArea)`${baseStyles}`;

export const StyledMenuButton = styled.button`${baseStyles}`;

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: ${rem(8)};
`;

export const BadgeWrapper = styled.div`
  ${Count} {
    position: initial;
    transform: initial;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  line-height: ${rem(16)};
  display: flex;
  align-items: center;
  font-size: ${rem(14)};
`;
