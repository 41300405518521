import Text from '@components/atoms/Text';
import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import LINKS from '@constants/links';
import { formatToLongDate } from '@helpers/date';
import useAppRouter from '@hooks/useAppRouter';
import useDeleteCartRequest from '@hooks/useDeleteCartRequest';
import useCart from '@features/cart/useCart';
import useTranslation from 'next-translate/useTranslation';
import { memo, useState } from 'react';
import ConfirmModal from '../ConfirmModal';
import { Container } from './OrderChangeBanner.styles';
/**
 * This is a component that is shown globally below the header when the user is changing an already existing order.
 * The component acts as a reminder for the user that they are currently changing an order and have up to a certain
 * listed end time untill the can't do anmymore changes to the order. The component also gives the user a link to click
 * that will camcel the editing of the order, doing so will prompt the customer with a modal where they can then confirm if they
 * really want to cancel the changes they have made to the order.
 */

const OrderChangeBanner = () => {
  const { t } = useTranslation('orderchangebanner');
  const [showModal, setShowModal] = useState<boolean>(false);
  const { cart } = useCart();
  const orderReference = cart?.orderReference
  const orderReferenceSlotCloseTime = cart?.orderReferenceSlotCloseTime
  const { push } = useAppRouter();
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const { cancelEditOrder } = useDeleteCartRequest({
    onSuccess: () => push(`${LINKS.ORDER_CONFIRMATION}${orderReference}`),
  });

  const orderEndTime: string = orderReferenceSlotCloseTime ? formatToLongDate(orderReferenceSlotCloseTime) : '';

  if (!orderEndTime || !orderReference) {
    return null;
  }

  return (
    <>
      <Container>
        <Paragraph size="body-text" textColor="white">
          {t('currentlyChangingOrder', { orderReference, orderEndTime })}
        </Paragraph>
        <Button variant="link" onClick={toggleModal}>
          <Text type="p" size="md" color="white">
            {t('cancelChanges')}
          </Text>
        </Button>
      </Container>
      {showModal && (
        <ConfirmModal
          confirmText={t('wantToCancelOrderChanges')}
          onConfirm={cancelEditOrder}
          onClose={toggleModal}
          confirmButtonText={t('common:yes')}
          cancelButtonText={t('common:no')}
        />
      )}
    </>
  );
};

export default memo(OrderChangeBanner);
