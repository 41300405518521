import mapCart from '@features/cart/mapCart';
import { AxfoodApi, CustomerExtraData } from '@occ/api-client';

/**
 * CartApi
 */
const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getMostLikelyAddress = async () => {
  const result = await axfoodApi.getCustomerLikelyDeliveryAddressUsingGet();
  return result.data;
};

export const addVoucher = async (voucherCode: string) => {
  const response = await axfoodApi.applyVoucherToCartUsingPost({ voucherCode });
  return mapCart(response.data);
};

export const deleteCart = async (isReplacingOrder: boolean, signal: AbortSignal) => {
  const response = await axfoodApi.clearCartUsingDelete({ cancelPossibleContinueCart: isReplacingOrder }, { signal });
  return mapCart(response.data);
};

export const removeVoucher = async (voucherCode: string) => {
  const response = await axfoodApi.releaseVoucherFromCartUsingDelete(voucherCode);
  return mapCart(response.data);
};

export const setCustomerExtraInfo = async ({
  commentFromCustomer,
  usersOrderReference,
  doorCode,
}: CustomerExtraData) => {
  const response = await axfoodApi.setCustomerExtraUsingPost({
    commentFromCustomer,
    doorCode,
    usersOrderReference,
  });
  return mapCart(response.data);
};

export const setPostalCode = (postalCode: string) => {
  return axfoodApi.setPostalCodeUsingPost({ postalCode });
};

export const setDeliveryAddress = async (
  firstName?: string,
  lastName?: string,
  addressLine1?: string,
  addressLine2?: string,
  postalCode?: string,
  town?: string,
  addressId?: string,
  email?: string,
  cellphone?: string,
  companyName?: string,
  longitude?: number,
  latitude?: number
) => {
  const response = await axfoodApi.setDeliveryAddressUsingPost({
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    postalCode,
    town,
    addressId,
    email,
    cellphone,
    companyName,
    longitude,
    latitude,
  });
  return mapCart(response.data);
};

export const setCartCustomerInfo = async (
  email: string,
  cellphone: string,
  firstName?: string,
  lastName?: string,
  saveToMyPages?: boolean
) => {
  const response = await axfoodApi.setCustomerContactUsingPost({ email, cellphone, firstName, lastName, saveToMyPages });
  return mapCart(response.data);
};

export const openOrderForChange = async (orderId: string) => {
  const response = await axfoodApi.continueShopForOrderUsingPost({
    orderNumber: orderId,
  });
  return mapCart(response.data);
};

export const addOrderToCart = async (orderCode: string) => {
  const res = await axfoodApi.putToCartUsingPost1(orderCode);
  return mapCart(res.data);
};

export const getCartStatus = (checkStock: boolean = false, slotCode?: string, storeId?: string) =>
  // checkStock needs to be undefined if not true (false is not allowed, at least for now)
  axfoodApi.checkCartStatusUsingGet({
    checkStock: checkStock ? true : undefined,
    slotCode,
    storeId,
  });

export const updateEkoReplacement = async (replaceEko: boolean) => {
  const response = await axfoodApi.updateEkoReplacementFlagUsingPost({ replaceEko });
  return mapCart(response.data);
};

export const deleteSlotInCart = async (signal?: AbortSignal) => {
  const response = await axfoodApi.removeSlotFromCartUsingDelete({ signal });
  return mapCart(response.data);
};
