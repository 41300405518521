/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RecipeWishListData } from '../dto';
// @ts-ignore
import { RecipeWishlistForm } from '../dto';
// @ts-ignore
import { ResponseEntity } from '../dto';
/**
 * RecipeWishListApi - axios parameter creator
 * @export
 */
export const RecipeWishListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add recipe to user wish list
         * @param {string} id id
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipeToRecipeWishlistUsingPOST: async (id: string, recipeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addRecipeToRecipeWishlistUsingPOST', 'id', id)
            // verify required parameter 'recipeCode' is not null or undefined
            assertParamExists('addRecipeToRecipeWishlistUsingPOST', 'recipeCode', recipeCode)
            const localVarPath = `/axfood/rest/user-recipe-wishlist/{id}/add/{recipeCode}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"recipeCode"}}`, encodeURIComponent(String(recipeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user recipe wish list
         * @param {RecipeWishlistForm} recipeWishlistForm recipeWishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeWishlistUsingPOST: async (recipeWishlistForm: RecipeWishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeWishlistForm' is not null or undefined
            assertParamExists('createRecipeWishlistUsingPOST', 'recipeWishlistForm', recipeWishlistForm)
            const localVarPath = `/axfood/rest/user-recipe-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipeWishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeWishlistUsingDELETE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRecipeWishlistUsingDELETE', 'id', id)
            const localVarPath = `/axfood/rest/user-recipe-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeWishlistUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/user-recipe-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe wishlists for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/user-recipe-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove recipe from recipe wish list
         * @param {string} listId listId
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromRecipeWishlistUsingDELETE: async (listId: string, recipeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('removeEntryFromRecipeWishlistUsingDELETE', 'listId', listId)
            // verify required parameter 'recipeCode' is not null or undefined
            assertParamExists('removeEntryFromRecipeWishlistUsingDELETE', 'recipeCode', recipeCode)
            const localVarPath = `/axfood/rest/user-recipe-wishlist/{listId}/recipe/{recipeCode}`
                .replace(`{${"listId"}}`, encodeURIComponent(String(listId)))
                .replace(`{${"recipeCode"}}`, encodeURIComponent(String(recipeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeWishListApi - functional programming interface
 * @export
 */
export const RecipeWishListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeWishListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add recipe to user wish list
         * @param {string} id id
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecipeToRecipeWishlistUsingPOST(id: string, recipeCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeWishListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecipeToRecipeWishlistUsingPOST(id, recipeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user recipe wish list
         * @param {RecipeWishlistForm} recipeWishlistForm recipeWishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecipeWishlistUsingPOST(recipeWishlistForm: RecipeWishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeWishListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecipeWishlistUsingPOST(recipeWishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecipeWishlistUsingDELETE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecipeWishlistUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeWishlistUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeWishListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeWishlistUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe wishlists for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeWishlistsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecipeWishListData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeWishlistsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove recipe from recipe wish list
         * @param {string} listId listId
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEntryFromRecipeWishlistUsingDELETE(listId: string, recipeCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeWishListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntryFromRecipeWishlistUsingDELETE(listId, recipeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeWishListApi - factory interface
 * @export
 */
export const RecipeWishListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeWishListApiFp(configuration)
    return {
        /**
         * 
         * @summary Add recipe to user wish list
         * @param {string} id id
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipeToRecipeWishlistUsingPOST(id: string, recipeCode: string, options?: any): AxiosPromise<RecipeWishListData> {
            return localVarFp.addRecipeToRecipeWishlistUsingPOST(id, recipeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user recipe wish list
         * @param {RecipeWishlistForm} recipeWishlistForm recipeWishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeWishlistUsingPOST(recipeWishlistForm: RecipeWishlistForm, options?: any): AxiosPromise<RecipeWishListData> {
            return localVarFp.createRecipeWishlistUsingPOST(recipeWishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeWishlistUsingDELETE(id: string, options?: any): AxiosPromise<ResponseEntity> {
            return localVarFp.deleteRecipeWishlistUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistUsingGET(id: string, options?: any): AxiosPromise<RecipeWishListData> {
            return localVarFp.getRecipeWishlistUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe wishlists for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistsUsingGET(options?: any): AxiosPromise<Array<RecipeWishListData>> {
            return localVarFp.getRecipeWishlistsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove recipe from recipe wish list
         * @param {string} listId listId
         * @param {string} recipeCode recipeCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromRecipeWishlistUsingDELETE(listId: string, recipeCode: string, options?: any): AxiosPromise<RecipeWishListData> {
            return localVarFp.removeEntryFromRecipeWishlistUsingDELETE(listId, recipeCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeWishListApi - object-oriented interface
 * @export
 * @class RecipeWishListApi
 * @extends {BaseAPI}
 */
export class RecipeWishListApi extends BaseAPI {
    /**
     * 
     * @summary Add recipe to user wish list
     * @param {string} id id
     * @param {string} recipeCode recipeCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public addRecipeToRecipeWishlistUsingPOST(id: string, recipeCode: string, options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).addRecipeToRecipeWishlistUsingPOST(id, recipeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user recipe wish list
     * @param {RecipeWishlistForm} recipeWishlistForm recipeWishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public createRecipeWishlistUsingPOST(recipeWishlistForm: RecipeWishlistForm, options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).createRecipeWishlistUsingPOST(recipeWishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public deleteRecipeWishlistUsingDELETE(id: string, options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).deleteRecipeWishlistUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public getRecipeWishlistUsingGET(id: string, options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).getRecipeWishlistUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe wishlists for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public getRecipeWishlistsUsingGET(options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).getRecipeWishlistsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove recipe from recipe wish list
     * @param {string} listId listId
     * @param {string} recipeCode recipeCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeWishListApi
     */
    public removeEntryFromRecipeWishlistUsingDELETE(listId: string, recipeCode: string, options?: AxiosRequestConfig) {
        return RecipeWishListApiFp(this.configuration).removeEntryFromRecipeWishlistUsingDELETE(listId, recipeCode, options).then((request) => request(this.axios, this.basePath));
    }
}
