import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useCustomer from '@hooks/useCustomer';
import useSWR, { SWRConfiguration } from 'swr';
import { getProductDetail } from './api';

export const ENDPOINT = 'getProductUsingGET1';

type KeyType = {
  productCode: string;
};

const fetcher = async ({ productCode }: KeyType) => getProductDetail(productCode);

const options: SWRConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
};

const useGetProductDetails = (productCode: string) => {
  const { customerFetchIsRequired } = useCustomer();

  const { data, isLoading, error, isValidating } = useSWR(
    !!productCode && customerFetchIsRequired
      ? {
          endpoint: ENDPOINT,
          productCode,
        }
      : null,
    fetcher,
    options
  );
  return {
    product: data,
    isLoading,
    error,
    isValidating,
  };
};

export default useGetProductDetails;
