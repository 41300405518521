import { closeDeliveryModal, openDeliveryModal } from '@actions/delivery';
import { cancelValidateCanGoToCheckout } from '@actions/validators';
import FaqWidget from '@components/molecules/FaqWidget';
import MessageBox from '@components/molecules/MessageBox/MessageBox';
import CustomerInfoReminderModal from '@components/organisms/AddMissingCustomerInformation/CustomerInfoReminderModal';
import ConflictModal from '@components/organisms/ConflictModal';
import Footer from '@components/organisms/Footer';
import Header from '@components/organisms/Header';
import LoginModal from '@components/organisms/Login/LoginModal';
import ProductDetailsModal from '@components/organisms/ProductDetails/ProductDetailsModal';
import Reminders from '@components/organisms/Reminders';
import { mobileSearchPortalId } from '@constants/globalElementIds';
import LINKS from '@constants/links';
import useSymplifyABTesting from '@features/ABTesting/useSymplifyABTesting';
import gtmTrack from '@helpers/gtmTrack';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useCmsLayout from '@hooks/useCmsLayout';
import useConfig from '@hooks/useConfig';
import useCustomer from '@hooks/useCustomer';
import useFeature from '@hooks/useFeature';
import useFetchInitialData from '@hooks/useFetchInitialData';
import useOnRouteChange from '@hooks/useOnRouteChange';
import useUserAgent from '@hooks/useUserAgent';
import { selectConflictModalIsOpen } from '@selectors/conflictModal';
import { selectDeliveryModalIsVisible } from '@selectors/delivery';
import { generalTracker, loginTracker } from '@trackers';
import dynamic from 'next/dynamic';
import React, { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import WithLayoutContainer from './WithLayoutContainer';

const DynamicDeliveryMethod = dynamic(() => import('@components/organisms/DeliveryMethod'));

interface Props {
  children: any;
}

const ClientSideChat = dynamic(() => import('@components/molecules/Chat'), { ssr: false });

const Layout = ({ children }: Props) => {
  const { push, asPath = '', pathname, query } = useAppRouter();
  const dispatch = useAppDispatch();
  const { isReactNative } = useUserAgent();
  const deliveryModalIsVisible = useAppSelector(selectDeliveryModalIsVisible);
  const { hasFetchedCustomer, customerId, isB2BCustomer, latestLoginIsAutoLogin, idInSourceSystem } = useCustomer();
  const conflictModalIsOpen = useAppSelector(selectConflictModalIsOpen);
  const { messageBoxSlot } = useCmsLayout();
  const { config } = useConfig();
  const chatEnabled = useFeature('FEATURE_ENABLE_RELEASY_CHAT_FL');
  const imboxEnabled = useFeature('FEATURE_IMBOX_ENABLED_FL');
  useFetchInitialData();
  useOnRouteChange();
  useSymplifyABTesting();

  const closeDelivery = useCallback(() => {
    dispatch(cancelValidateCanGoToCheckout());
    dispatch(closeDeliveryModal());
    push({ pathname, query }, undefined, { shallow: true, scroll: false });
  }, [dispatch, push, pathname, query]);

  useEffect(() => {
    if (config?.GTM_YYYYYY) {
      TagManager.initialize({ gtmId: config.GTM_YYYYYY });
    }
  }, [config?.GTM_YYYYYY]);

  useEffect(() => {
    generalTracker.pageChange('optimize.activate');
  }, [pathname]);

  useEffect(() => {
    gtmTrack({ event: 'device', device: isReactNative ? 'App' : 'Web' });
  }, []);

  useEffect(() => {
    if (hasFetchedCustomer) {
      loginTracker.trackUser({ customerId, isB2BCustomer, latestLoginIsAutoLogin, idInSourceSystem });
    }
  }, [hasFetchedCustomer, customerId, isB2BCustomer, latestLoginIsAutoLogin, idInSourceSystem]);

  useEffect(() => {
    if (
      (asPath.startsWith(LINKS.DELIVERY_METHOD_HOME) || asPath.startsWith(LINKS.DELIVERY_METHOD_PICKUP)) &&
      !deliveryModalIsVisible
    ) {
      dispatch(openDeliveryModal());
    }
  }, [asPath]);

  return (
    <>
      <MessageBox messageBoxSlot={messageBoxSlot} />
      {!isReactNative && <Header />}
      <WithLayoutContainer>
        <div id={mobileSearchPortalId} />
        <Reminders />
        {children}
      </WithLayoutContainer>
      {!isReactNative && <Footer />}
      <LoginModal />
      <CustomerInfoReminderModal />
      {deliveryModalIsVisible && <DynamicDeliveryMethod onClose={closeDelivery} />}
      <ProductDetailsModal />
      {conflictModalIsOpen && <ConflictModal />}
      {chatEnabled ? <ClientSideChat /> : null}
      {imboxEnabled ? <FaqWidget hideWidget={!!isReactNative} /> : null}
      <div id="base-overlay-container" />
      <div id="takeover-container" />
      <div id="modal-container" />
    </>
  );
};

export default React.memo(Layout);
