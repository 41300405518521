import * as notificationActions from '@actions/notification';
import LoyaltyLoginNotification from '@components/molecules/LoyaltyLoginNotification';
import VoucherNotification from '@components/molecules/VoucherNotification';
import NOTIFICATIONS from '@constants/notifications';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import useCart from '@features/cart/useCart';
import { selectShowLogInNotification, selectShowVoucherNotification } from '@selectors/notifications';
import { useEffect, useRef } from 'react';

const CartNotifications = () => {
  const showLoyaltyLogin = useAppSelector(selectShowLogInNotification);
  const showVoucher = useAppSelector(selectShowVoucherNotification);
  const { cart: { loyaltyProductCount = 0} = {} } = useCart();

  const { isLoggedIn } = useCustomer();

  const ref = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loyaltyProductCount >= 1 && !isLoggedIn) {
      dispatch(
        notificationActions.setNotificationVisibility({
          name: NOTIFICATIONS.LOYALTY_LOGIN_NOTIFICATION,
          count: true,
          show: true,
          number: loyaltyProductCount,
        })
      );
    }
  }, [loyaltyProductCount, dispatch, isLoggedIn]);
  return (
    <div ref={ref}>
      {showLoyaltyLogin && <LoyaltyLoginNotification popperReference={ref.current} />}
      {showVoucher && <VoucherNotification popperReference={ref.current} />}
    </div>
  );
};
export default CartNotifications;
