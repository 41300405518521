import Button, { ButtonSize } from '@components/atoms/Button/Button';
import LinkButton from '@components/atoms/Button/LinkButton';
import { MultiSearchLink } from '@helpers/productMapper';
import useTranslation from 'next-translate/useTranslation';
import { memo, useState } from 'react';
import SuggestionList from '../SuggestionList';
import { CategoryList, CategoryListItem } from './CategoryLinks.styles';
import { CategorySkeletons } from './CategoryLinksSkeleton';

interface Props {
  categories: MultiSearchLink[];
  showSkeletons: boolean;
  onCategoryClick?: (name: string) => void;
  withHeader?: boolean;
  buttonSize?: ButtonSize;
}

export const sliceIndex = 5;
const CategoryLinksWithShowMoreButton = ({
  categories,
  showSkeletons,
  onCategoryClick,
  withHeader = false,
  buttonSize = 'sm',
}: Props) => {
  const [isShowingAll, setShowAll] = useState(false);
  const { t } = useTranslation('common');
  const hasCategories = !!categories.length;
  const showCategories = hasCategories || showSkeletons;

  const items = isShowingAll ? categories : categories.slice(0, sliceIndex);
  const remainingItems = isShowingAll ? [] : categories.slice(sliceIndex);

  const renderShowMoreButton = categories.length > sliceIndex && !isShowingAll;

  const handleCategoryClick = (name: string) => {
    if (onCategoryClick) {
      onCategoryClick(name);
    }
  };
  return (
    <>
      {showCategories && (
        <SuggestionList title={t('search->categories')} withHeader={withHeader}>
          <CategoryList>
            {showSkeletons ? (
              <CategorySkeletons buttonSize={buttonSize} />
            ) : (
              <>
                {items.map(({ name, url }) => (
                  <CategoryListItem key={name} data-testid="category-suggestion-link">
                    <LinkButton
                      variant="secondary"
                      size={buttonSize}
                      href={url}
                      onClick={() => handleCategoryClick(name)}
                    >
                      {name}
                    </LinkButton>
                  </CategoryListItem>
                ))}
                {remainingItems.map(({ name, url }) => (
                  <CategoryListItem key={name} data-testid="category-suggestion-link" $hideOnMobile>
                    <LinkButton
                      variant="secondary"
                      size={buttonSize}
                      href={url}
                      onClick={() => handleCategoryClick(name)}
                    >
                      {name}
                    </LinkButton>
                  </CategoryListItem>
                ))}
              </>
            )}
            {renderShowMoreButton ? (
              <CategoryListItem $hideOnTablet>
                <Button variant="secondary" onClick={() => setShowAll(true)}>
                  {t('defaultActions->showMore')} ({categories.length - sliceIndex})
                </Button>
              </CategoryListItem>
            ) : null}
          </CategoryList>
        </SuggestionList>
      )}
    </>
  );
};

export default memo(CategoryLinksWithShowMoreButton);
