import media from '@constants/responsive';
import { rem } from 'polished';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Position } from './Panel';

const slideInTransition = (position: Position): Partial<Record<TransitionStatus, any>> => ({
  entering: css`
    transform: translateX(${position === 'left' ? '-101%' : '101%'});
  `,
  entered: css`
    transform: translateX(0);
  `,
});

// Panel sizes may need adjustment when new Panels are introduced in Web 2.0
const getSize = (size: string) => {
  switch (size) {
    case 'small':
      return rem(346);
    case 'medium':
      return rem(490);
    case 'large':
      return rem(590);
    default:
      return rem(280);
  }
};

interface ContainerProps {
  $position: Position;
  $transition: TransitionStatus;
  $size: string;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  top: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  ${({ $position }) => ($position === 'left' ? `left: 0;` : `right: 0;`)}

  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: ${({ $position }) => ($position === 'left' ? 'translateX(-101%)' : 'translateX(101%)')};
  ${({ $transition, $position }) => slideInTransition($position)[$transition]}

  ${media.largeMobile} {
    width: 60%;
  }
  ${media.tabletPortrait} {
    width: ${({ $size }) => getSize($size)};
  }

  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  flex: 1;
`;

export const HeaderSlotWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  border-bottom: $rem(1) solid ${({ theme }) => theme.colors.colorCararra};
  padding: ${rem(8)} ${rem(16)};
  ${media.tabletPortrait} {
    padding: ${rem(8)} ${rem(24)};
  }
`;

export const FooterSlotWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  margin: ${rem(16)} ${rem(8)};
  border-radius: ${rem(16)};
  position: sticky;
  z-index: 600;
  left: 0;
  right: 0;
  bottom: ${rem(16)};
  box-shadow: 0 0 ${rem(25)} ${rem(4)} ${({ theme }) => theme.colors.colorBoxShadow};
  padding: ${rem(16)} ${rem(12)};
  margin: ${rem(8)} ${rem(4)};

  ${media.mobile} {
    margin: ${rem(16)} ${rem(8)};
    padding: ${rem(16)} ${rem(24)};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: ${rem(60)};
`;

export const Close = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
