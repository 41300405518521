/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable fp/no-rest-parameters */
import Image, { ImageProps } from 'next/image';

/**
 * UnoptimizedImage component that wraps Next.js Image component
 * with unoptimized=true by default
 */
export default function UnoptimizedImage({ ...props }: Omit<ImageProps, 'unoptimized'> & { unoptimized?: boolean }) {
  return <Image unoptimized={true} {...props} />;
}
