import Icon from '@components/atoms/Icon';
import CartNotifications from '@components/organisms/Header/Cart/CartNotifications';
import MiniCartPane from '@components/organisms/Header/Cart/MiniCartPane';
import useOnClickOutside from '@hooks/useOnClickOutside';
import CartIcon from '@public/icons/regularIcons/icon-cart.svg';
import useCart from '@features/cart/useCart';
import Overlay from 'components/atoms/Overlay';
import useTranslation from 'next-translate/useTranslation';
import { memo, useRef, useState } from 'react';
import NotificationButton from '../../../../atoms/HeaderButton/NotificationButton';
import useToggleMiniCart from '../useToggleMiniCart';

interface Props {
  totalProductAmount: number;
}
const MiniCart = ({ totalProductAmount }: Props) => {
  const { t } = useTranslation('common');
  const ref = useRef<HTMLDivElement>(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const { isPaneVisible, handleClickMiniCartButton, toggleMenu } = useToggleMiniCart();
  const miniCartPosition = ref.current
    ? ref.current?.parentElement?.previousElementSibling?.getBoundingClientRect().bottom
    : 64;

  const { cart: { subTotalPriceWithDiscountsAndVouchers = ''} = {} } = useCart();
  const buttonText =
    parseInt(subTotalPriceWithDiscountsAndVouchers, 10) > 0 ? subTotalPriceWithDiscountsAndVouchers : null;

  useOnClickOutside(ref, () => {
    if (isPaneVisible && !isConfirmModalVisible) {
      toggleMenu();
    }
  });
  return (
    <div ref={ref}>
      <NotificationButton
        aria-expanded={isPaneVisible}
        aria-haspopup="dialog"
        aria-label={t('ariaLabels->minicart')}
        badgeValue={totalProductAmount}
        borderRadius={!!buttonText ? 32 : 16}
        onClick={handleClickMiniCartButton}
      >
        <Icon color="white" icon={CartIcon} />
        {buttonText}
      </NotificationButton>
      <CartNotifications />
      {isPaneVisible && (
        <>
          <MiniCartPane setIsConfirmModalVisible={setIsConfirmModalVisible} toggleMenu={toggleMenu} />
          <Overlay opacity={0.5} onClick={toggleMenu} zIndex={99} lockScroll={true} position={miniCartPosition} />
        </>
      )}
    </div>
  );
};

export default memo(MiniCart);
