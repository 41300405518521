import HeaderButton from '@components/atoms/HeaderButton';
import Icon from '@components/atoms/Icon';
import Spinner from '@components/atoms/Spinner';
import LINKS from '@constants/links';
import isFrontend from '@helpers/isFrontend';
import { getSearchUrl } from '@helpers/productMapper';
import useAppRouter from '@hooks/useAppRouter';
import useResponsive from '@hooks/useResponsive';
import IconClear from '@public/icons/colorIcons/icon-clear.svg';
import IconSearch from '@public/icons/regularIcons/icon-search.svg';
import { searchTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  CancelButton,
  ClearButtonWrapper,
  Container,
  SearchField,
  SearchForm,
  SearchIcon,
  SearchInput,
  StyledOverlay,
} from './Search.styles';
import SearchSuggestions from './SearchSuggestions';
import useMultiSearchAsYouType, { hasSearchTerm } from './useMultiSearchAsYouType';
import useSearchTerm from './useSearchTerm';

interface SearchProps {
  size?: 'sm' | 'md';
  setSearchIsFocused: (value: boolean) => void;
}

export const DEBOUNCE_TIME = 500;

const Search = ({ size = 'md', setSearchIsFocused }: SearchProps) => {
  const { t } = useTranslation('common');
  const { isDesktopOrGreater } = useResponsive();
  const { push } = useAppRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const q = searchParams?.get('q');

  const searchRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useSearchTerm();
  const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
  const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);
  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null);

  const multiSearch = useMultiSearchAsYouType();

  const { search, isFetchingData, totalNumberOfResults } = multiSearch;

  const showSearchSuggestions = isFrontend() && suggestionsIsOpen;

  useEffect(() => {
    if (searchTerm && pathname !== LINKS.SEARCH) {
      setSearchTerm('');
      setShowCancelButton(false);
      setSearchIsFocused(false);
    }
  }, [pathname]);

  const closeSuggestions = () => {
    setSuggestionsIsOpen(false);
  };

  const searchOnEnter = () => {
    search('');
    setSuggestionsIsOpen(false);
    push(getSearchUrl(searchTerm));
    searchRef.current?.blur();
    setShowCancelButton(false);
    setSearchIsFocused(false);
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = null;
    }
  };

  const clickOnSearchTerm = useCallback(
    (term?: string) => {
      if (!term) return;
      searchTracker.trackSuggestionClick(searchTerm, term);
      setSearchTerm(term);
      searchRef?.current?.blur();
      closeSuggestions();
      setShowCancelButton(false);
      if (term !== q) {
        searchRef?.current?.blur();
      }
    },
    [q, searchTerm, setSearchTerm]
  );

  const onKeyUp = (key: React.KeyboardEvent<HTMLInputElement>) => {
    if (key.key === 'Enter') {
      searchOnEnter();
    }
  };

  const clearSearch = useCallback(
    (keepTerm?: boolean) => {
      !keepTerm && setSearchTerm('');
      setShowCancelButton(false);
    },
    [setSearchTerm, setShowCancelButton]
  );

  const handleCancelClick = () => {
    setSearchIsFocused(false);
    setShowCancelButton(false);
    setSuggestionsIsOpen(false);
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.currentTarget.value;
      setSuggestionsIsOpen(hasSearchTerm(inputValue));
      setSearchTerm(inputValue);
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = null;
      }
      if (hasSearchTerm(inputValue)) {
        timeoutHandler.current = setTimeout(() => search(inputValue), DEBOUNCE_TIME);
      }

      setShowCancelButton(!!inputValue && !isDesktopOrGreater);

      // resets state in MultiSearch hook to prevent product impressions to be tracked several times when toggling search modal
      if (!hasSearchTerm(inputValue)) {
        search('');
      }
    },
    [setSearchTerm, setShowCancelButton, isDesktopOrGreater, search]
  );

  const onCategoryClick = (categoryName: string) => {
    closeSuggestions();
    clearSearch();
    searchRef?.current?.blur();
    searchTracker.trackCategoryClick(searchTerm, categoryName);
  };

  const clearRef = useRef<HTMLDivElement>(null);

  const onSearchFieldFocus = () => {
    if (hasSearchTerm(searchTerm) && totalNumberOfResults === 0) {
      search(searchTerm);
    }
    setSearchIsFocused(true);
    !isDesktopOrGreater && setShowCancelButton(true);
    hasSearchTerm(searchTerm) && setSuggestionsIsOpen(true);
  };

  const onSearchFieldBlur = () => {
    showSearchSuggestions && searchTerm && searchTracker.trackSearchSuggestionsShown(searchTerm);
  };

  return (
    <>
      <Container>
        {/* action="" enables keyboard with "search" in mobile IOS devices */}
        <SearchForm role="search" onSubmit={(e) => e.preventDefault()} action="">
          <SearchField
            onClick={(e) => {
              if (!e.currentTarget.contains(clearRef.current)) {
                searchRef?.current?.focus();
              }
            }}
          >
            <SearchIcon>
              <Icon icon={IconSearch} color="grey" data-testid="icon-search" />
            </SearchIcon>
            <SearchInput
              data-testid="product-search"
              type="search"
              $size={size}
              placeholder={t('search->placeholder')}
              ref={searchRef}
              onChange={handleChange}
              value={searchTerm}
              onBlur={onSearchFieldBlur}
              onFocus={onSearchFieldFocus}
              onKeyUp={onKeyUp}
            />
            {isFetchingData && <Spinner />}

            {!!searchTerm && (
              <ClearButtonWrapper ref={clearRef}>
                <HeaderButton
                  variant="transparent"
                  size="md"
                  type="reset"
                  onClick={() => {
                    clearSearch();
                    searchRef.current?.focus();
                  }}
                  id="clear-button"
                  aria-label={t('defaultActions->clear')}
                >
                  <Icon icon={IconClear} />
                </HeaderButton>
              </ClearButtonWrapper>
            )}
          </SearchField>
          {showSearchSuggestions && (
            <SearchSuggestions
              onClickSearchTerm={clickOnSearchTerm}
              onCategoryClick={onCategoryClick}
              searchOnEnter={searchOnEnter}
              multiSearchData={multiSearch}
            />
          )}
        </SearchForm>
        {showCancelButton && (
          <CancelButton variant="transparent" size="sm" onClick={handleCancelClick} id="cancel-button">
            {t('defaultActions->cancel')}
          </CancelButton>
        )}
      </Container>
      {showSearchSuggestions && <StyledOverlay lockScroll zIndex={40} onClick={closeSuggestions} />}
    </>
  );
};

export default memo(Search);
