import { multiSearchAsYouType } from '@api';
import { SearchSuggestionData } from '@api/generated/storefront';
import mapHybrisProducts from '@components/molecules/Product/ProductDataMapper/productDataMapper';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { MultiSearchLink, mapCategories, mapSuggestions } from '@helpers/productMapper';
import { useAppSelector } from '@hooks/redux';
import { selectActiveStoreId } from '@selectors/delivery';
import { useState } from 'react';
import useSWR from 'swr';

const fetcher = async (query: string) => {
  const data = await multiSearchAsYouType(query, 0, 5);
  const searchSuggestions = data?.autocompleteResultData?.suggestions
    ? mapSuggestions(data?.autocompleteResultData?.suggestions)
    : [];
  const categories = data?.productSearchPageData?.navigationCategories
    ? mapCategories(data?.productSearchPageData?.navigationCategories)
    : [];

  const products = data?.productSearchPageData?.results ? data?.productSearchPageData?.results : [];

  const totalNumberOfResults = data?.productSearchPageData?.pagination?.totalNumberOfResults
    ? data?.productSearchPageData?.pagination?.totalNumberOfResults
    : 0;
  const customSuggestion = data.productSearchPageData?.customSuggestion;

  const freeTextSearch = data.productSearchPageData?.freeTextSearch;
  return { searchSuggestions, categories, products, totalNumberOfResults, customSuggestion, freeTextSearch };
};

export const minSearchLength = 2;

export const hasSearchTerm = (term?: string): boolean => !!(term && term.length >= minSearchLength);

interface MultiSearchDataWithOutProducts {
  categories: MultiSearchLink[];
  searchSuggestions: MultiSearchLink[];
  totalNumberOfResults: number;
  customSuggestion?: SearchSuggestionData;
  freeTextSearch?: string;
}

export interface MultiSearchData extends MultiSearchDataWithOutProducts {
  productsFormatted: Product[];
  isFetchingData: boolean;
  showSkeletons: boolean;
}

interface MultiSearchHook extends MultiSearchDataWithOutProducts {
  products: ProductVariantType;
}

interface MultiSearchHookReturn extends MultiSearchData {
  search: (queryString: string) => void;
}

const useMultiSearchAsYouType = (): MultiSearchHookReturn => {
  const [query, setQuery] = useState<string>('');
  const activeStore = useAppSelector(selectActiveStoreId);
  const { data, isValidating } = useSWR<MultiSearchHook>(
    query.length >= minSearchLength ? ['useMultiSearchAsYouType', query, activeStore] : null,
    () => fetcher(query),
    { keepPreviousData: false },
  );

  const {
    searchSuggestions = [],
    categories = [],
    products = [],
    totalNumberOfResults = 0,
    customSuggestion,
    freeTextSearch,
  } = data || {};
  const productsFormatted = mapHybrisProducts(products);

  const search = (queryString: string) => {
    setQuery(queryString);
  };

  return {
    search,
    searchSuggestions,
    categories,
    productsFormatted,
    totalNumberOfResults,
    isFetchingData: isValidating,
    showSkeletons: isValidating || !data,
    customSuggestion,
    freeTextSearch,
  };
};

export default useMultiSearchAsYouType;
