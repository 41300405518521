import AccordionSection from '@components/atoms/__DEPRECATED__/AccordionSection';
import { TabType } from '@components/atoms/Tab/types';
import ProductDescriptionListing from '@components/molecules/ProductDescriptionListing';
import ProductInformationListing from '@components/molecules/ProductInformationListing';
import ProductOtherListing from '@components/molecules/ProductOtherListing';
import TAB_TYPES, { TabType as ProductDetailTabType } from '@constants/productDetailTabs';
import { useEffect, useRef, useState } from 'react';
import { ProductDetailsData } from '../useGetProductDetails/mapping';
import AccordionSectionContent from './AccordionSectionContent';
import AccordionSectionHeader from './AccordionSectionHeader';
import { AccordionWrapper } from './ProductDetailsAccordion.styles';

interface ProductDetailsAccordionProps {
  accordionSections: TabType[];
  onChange: (id: string) => void;
  product: ProductDetailsData;
  initiallyExpanded: 'productInformation' | '';
  updatedSelectedTab: ProductDetailTabType | '';
}

const ProductDetailsAccordion = ({
  accordionSections,
  onChange,
  product,
  initiallyExpanded,
  updatedSelectedTab,
}: ProductDetailsAccordionProps) => {
  const [activeTab, setActiveTab] = useState<'' | ProductDetailTabType>(initiallyExpanded);
  const [lastClosedTab, setLastClosedTab] = useState(' ');
  const sectionRef = useRef<HTMLDivElement>(null);
  const onClickAccordionSection = (tabId: ProductDetailTabType) => {
    setLastClosedTab(tabId !== activeTab ? activeTab : tabId);
    const currentlyActiveTab = accordionSections.find((tab) => tab.id === tabId);
    if (currentlyActiveTab?.hasData) {
      setActiveTab(activeTab === tabId ? '' : tabId);
    }
    onChange(tabId !== activeTab ? tabId : '');

    if (activeTab !== tabId && sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    setActiveTab(initiallyExpanded);
  }, [product, initiallyExpanded]);

  useEffect(() => {
    if (updatedSelectedTab) {
      setActiveTab(updatedSelectedTab);
    }
  }, [updatedSelectedTab]);

  return (
    <AccordionWrapper>
      {accordionSections.map((section) => {
        const isActive = activeTab === section.id;

        return (
          <AccordionSection
            key={section.id}
            onClick={() => onClickAccordionSection(section.id as ProductDetailTabType)}
            shouldAlwaysRenderContent
            headerSlot={
              <AccordionSectionHeader
                data-testid={isActive ? 'activeHeader' : 'inactiveHeader'}
                active={isActive}
                title={section.translation}
                disabled={!section.hasData}
                lastClosedTab={lastClosedTab === section.id}
                ref={isActive ? sectionRef : undefined}
              />
            }
            contentSlot={
              <AccordionSectionContent active={isActive} data-testid={isActive ? 'activeContent' : 'inactiveContent'}>
                {activeTab === TAB_TYPES.PRODUCTINFORMATION && <ProductDescriptionListing product={product} />}
                {activeTab === TAB_TYPES.NUTRITIONVALUE && <ProductInformationListing product={product} />}
                {activeTab === TAB_TYPES.MISC && <ProductOtherListing product={product} />}
              </AccordionSectionContent>
            }
          />
        );
      })}
    </AccordionWrapper>
  );
};
export default ProductDetailsAccordion;
