import usePageHasSideNav from '@hooks/usePageHasSideNav';
import useResponsive from '@hooks/useResponsive';
import useCart from '@features/cart/useCart';

const useHeaderResponsive = () => {
  const { isTabletPortraitOrGreater, isTabletLandscapeOrGreater, isDesktopOrGreater } = useResponsive();
  const hasSideNav = usePageHasSideNav();
  const { hasFetchedCart } = useCart();

  const showHamburgerButton = !isTabletLandscapeOrGreater;
  const showCartButtonAmount = isTabletPortraitOrGreater;
  const showDeliveryButton = isDesktopOrGreater;
  const showAccountButtonText = isDesktopOrGreater;

  return {
    showHamburgerButton,
    showMenuLinks: isTabletLandscapeOrGreater,
    showCustomerServiceLink: isTabletPortraitOrGreater,
    showAccountButtonText,
    showCartButtonAmount,
    showDeliveryButton: hasFetchedCart && showDeliveryButton,
    showMobileButtonBar: hasFetchedCart && !showDeliveryButton && hasSideNav,
    hideRightColumnOnFocus: !isTabletPortraitOrGreater,
  };
};

export default useHeaderResponsive;
