import LinkButton from '@components/atoms/Button/LinkButton';
import { Section } from '@components/atoms/Section/Section.styles';
import Heading from '@components/atoms/__DEPRECATED__/Heading';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { ButtonWrapper, Container, HeadingWrapper } from '@components/templates/ErrorPages/ErrorPages.styles';
import LINKS from '@constants/links';
import useTranslation from 'next-translate/useTranslation';

const ProductNotAvailable = () => {
  const { t } = useTranslation('productDetails');

  return (
    <Section $marginTop>
      <Container $verticalAlignment="top">
        <HeadingWrapper>
          <Heading type="h1">{t('productDetails->error->title')}</Heading>
        </HeadingWrapper>
        <Paragraph size="body-text">{t('productDetails->error->message')}</Paragraph>
        <ButtonWrapper>
          <LinkButton href={LINKS.START}>{t('common:goToStartPage')}</LinkButton>
        </ButtonWrapper>
      </Container>
    </Section>
  );
};

export default ProductNotAvailable;
