import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

interface VerticalAlignmentProps {
  $verticalAlignment?: 'top' | 'center';
}

export const Container = styled.div<VerticalAlignmentProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$verticalAlignment === 'top' ? 'flex-start' : 'center')};
  margin-top: ${rem(9)};
  padding: ${rem(43)} ${rem(16)} 2rem;
  min-height: ${rem(600)};
  text-align: center;
  background: ${({ theme }) => theme.colors.colorWhite};

  ${media.tabletPortrait} {
    min-height: ${rem(1000)};
  }
`;

export const HeadingWrapper = styled.div`
  text-align: center;
  margin: ${rem(16)} 0;
  padding: 0 ${rem(10)};
  max-width: ${rem(333)};
  ${media.tabletPortrait} {
    max-width: ${rem(500)};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${rem(36)};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
  margin-top: ${rem(8)};
`;
