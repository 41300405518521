import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const fontSize = rem(14);

interface ContainerProps {
  $isActive: boolean;
}
export const QuantityBase = styled.div`
  border-radius: ${rem(40)};
  width: 100%;
  display: flex;
  align-items: center;

  ${media.tabletLandscape} {
    padding: 0;
    height: auto;
  }
`;

export const BorderBox = styled(QuantityBase)<ContainerProps>`
  border: ${rem(2)} solid transparent;

  ${(props) =>
    props.$isActive &&
    `
      border-color: ${props.theme.colors.colorPrimary};
    `}
`;

export const Container = styled(QuantityBase)`
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.colorPampas};
  height: 100%;
  padding: ${rem(2)};
`;

const RoundBase = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colorWhite};
  border: none;
  border-radius: ${rem(24)};
  width: ${rem(32)};
  height: ${rem(32)};
  padding: 0;
  flex-shrink: 0;
`;

export const TooltipBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border: none;
  border-radius: ${rem(24)};
  width: ${rem(32)};
  height: ${rem(32)};
  padding: 0;
  flex-shrink: 0;
`;

export const RoundButton = styled.button`
  ${RoundBase}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.colorStack : theme.colors.colorWhite)};

  svg {
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, disabled }) => (disabled ? 'inital' : theme.colors.colorCararra)};
    }
  }
`;

export const PlusButton = styled(RoundButton)`
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.colorStack : theme.colors.colorPrimary)};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, disabled }) => (disabled ? 'inital' : theme.colors.colorCardinal)};
    }
  }
`;

export const InputWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: ${fontSize};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  flex: 1 1 50%;
  gap: ${rem(2)};
`;

export const Label = styled.span`
  line-height: ${rem(16)};
  pointer-events: none;

  ${media.tabletPortrait} {
    margin-left: 0;
    padding-left: ${rem(3)};
  }
`;

export const InputField = styled.input.attrs({ type: 'number' })<{ $width: number }>`
  border: none;
  min-width: 0;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  height: ${rem(24)};
  text-align: center;
  appearance: textfield;
  font-size: ${fontSize};
  background-color: transparent;
  padding: 0;
  -webkit-text-fill-color: ${({ theme }) => theme.colors.colorTextPrimary}; // Override iOS font color change
  opacity: 1; // Override iOS opacity change
  width: ${({ $width }) => ($width < 5 ? `calc(${$width} * 1ch)` : `5ch`)};
  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
